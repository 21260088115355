import React from "react"
import classNames from "classnames";
import {CheckboxIcon} from "../icons";

const ThCheckbox = ({checked, defaultChecked, onChange, label, description, disabled=false}) => {

    const btnClasses= classNames({
        "h-[42px] w-[100px] flex items-center relative  rounded-md pl-1 hover:cursor-pointer": true,
        "bg-secondary-action text-white": checked | defaultChecked,
        "bg-input text-primary": !checked,
        "disabled": disabled
    })

    const markStyle = {
        // backgroundColor: disabled ? checked ? "#6d6e6e" : "#DBDBDB" : checked ? "#FC634B" : "#DBDBDB"
        backgroundColor: checked ? disabled ? "#6d6e6e" : "#FC634B" : "#DBDBDB"
    }

    return (
        <td>
            <div className="flex justify-center px-[10px]">

                    <div  className={btnClasses} onClick={onChange}>
                        <input  className="absolute opacity-0" type="checkbox" checked={checked || false} onChange={onChange}/>
                        <div className="ml-2">
                            <div className="test h-[18px] w-[18px] rounded-[4px] flex items-center justify-center" style={markStyle}>
                                {
                                    checked && <CheckboxIcon stroke={disabled ? '#474747' : checked ? "#FFFFFF" : "transparent"}
                                                  width={10} height={7} src="/checkbox.svg"/>
                                }
                            </div>
                        </div>

                        <div className="flex flex-col ml-2">
                            <div className="text-xs font-normal">{description}</div>
                            <div className="text-sm font-medium">{label}</div>
                        </div>
                    </div>
            </div>
        </td>
    )
}

export default ThCheckbox