import * as React from "react";
const SvgCameraIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={14}
    height={12}
    fill="none"
    {...props}
  >
    <path
      fill="#313131"
      d="M12 1.5h-1.732L9.415.222A.5.5 0 0 0 9 0H5a.5.5 0 0 0-.416.223L3.732 1.5H2A1.5 1.5 0 0 0 .5 3v7A1.5 1.5 0 0 0 2 11.5h10a1.5 1.5 0 0 0 1.5-1.5V3A1.5 1.5 0 0 0 12 1.5Zm.5 8.5a.5.5 0 0 1-.5.5H2a.5.5 0 0 1-.5-.5V3a.5.5 0 0 1 .5-.5h2a.5.5 0 0 0 .416-.223L5.267 1h3.465l.852 1.277A.5.5 0 0 0 10 2.5h2a.5.5 0 0 1 .5.5v7Zm-3-3.5A.5.5 0 0 1 9 7H7.5v1.5a.5.5 0 1 1-1 0V7H5a.5.5 0 1 1 0-1h1.5V4.5a.5.5 0 1 1 1 0V6H9a.5.5 0 0 1 .5.5Z"
    />
  </svg>
);
export default SvgCameraIcon;
