import React, {useRef, useState} from "react"
import Modal from "./Modal";
import {DndProvider, useDrag, useDrop} from "react-dnd";
import classNames from "classnames";
import {Drag, TrashSmallIcon} from "../icons";
import {HTML5Backend} from "react-dnd-html5-backend";
import Button from "./Button";
import {
    useMealCategories,
    useRestaurantCategoriesCreation,
    useRestaurantCategoriesDeletion,
    useRestaurantCategoriesUpdate
} from "../store/MealCategoriesStore";

const CategoryItem = ({category, index, moveOption, onDelete}) => {

    const ref = useRef(null);

    const [{isDragging}, drag] = useDrag({
        type: 'OPTION',
        item: {index},
        collect: (monitor) => ({
            isDragging: !!monitor.isDragging(),
        }),
    });

    const [, drop] = useDrop({
        accept: 'OPTION',
        drop: (draggedItem) => {
            if (draggedItem.index !== index) {
                moveOption(draggedItem.index, index);
                draggedItem.index = index;
            }
        }
    });

    drag(drop(ref));

    return (
        <div className={classNames({
            "flex items-center justify-between h-[40px] shrink-0  px-2 rounded-[6px] mb-2 text-gray-800": true,
            "!cursor-grabbing hover:bg-gray-100": isDragging,
            "hover:cursor-grab  bg-input border border-gray-200": true,

        })} ref={ref}>
            <div className="flex items-center">
                <Drag width={20}/>
                <div className="ml-1">{category.category}</div>
            </div>
            <div className="flex items-center">
                <div onClick={() => onDelete(category.id)}
                     className="ml-4 rounded-full w-[25px] h-[25px] flex items-center justify-center hover:cursor-pointer hover:bg-gray-300 hover:underline text-sm"
                ><TrashSmallIcon width={14} height={14}/></div>
            </div>
        </div>
    )
}

const MealCategoriesModal = ({clientId, onClose}) => {

    const {data} = useMealCategories(clientId)
    const {mutate: createCategory} = useRestaurantCategoriesCreation()
    const {mutate: updateCategories} = useRestaurantCategoriesUpdate()
    const {mutate: deleteCategory} = useRestaurantCategoriesDeletion()

    const [entry, setEntry] = useState("")

    const moveItem = (fromIndex, toIndex) => {
        const newItems = [...data];
        const [movedItem] = newItems.splice(fromIndex, 1);
        newItems.splice(toIndex, 0, movedItem);
        newItems.forEach((item, index) => item.position = index + 1)
        updateCategories(newItems)
    };

    const handleEnter = (e) => {
        if (e.key === 'Enter' && entry && entry.length > 2) {
            handleCreate()
        }
    }
    const handleCreate = async () => {
        createCategory({name: entry, clientId: clientId})
        setEntry('')
    }

    const handleDelete = async (categoryId) => {
        deleteCategory(categoryId)
    }

    return (
        <Modal onClose={onClose} show={true}>

            <div className="bg-white rounded-[8px] max-h-[80%] overflow-hidden flex flex-col">
                <div className="header ">Kategorije</div>
                <div className="body flex flex-col overflow-hidden h-full">
                    <div className="relative mb-8">
                        <input
                            className="flex w-full bg-input rounded-[6px] pl-4 pr-[100px] h-[40px] font-light text-sm border border-gray-200"
                            type="text"
                            value={entry}
                            placeholder="Unesite naziv kategorije"
                            onChange={e => setEntry(e.target.value)}
                            onKeyUp={handleEnter}/>
                        <Button className="absolute right-[5px] top-[5px]"
                                onClick={handleCreate} disabled={!entry || entry.length < 3}>Kreiraj</Button>
                    </div>
                    <div className="flex flex-col overflow-y-auto h-full dropdown">
                        {
                            data && data
                                .sort((a, b) => a.position < b.position)
                                .map((category, index) => (
                                    <DndProvider key={category.id}
                                                 backend={HTML5Backend}>
                                        <CategoryItem category={category}
                                                      key={category.id}
                                                      index={index}
                                                      moveOption={moveItem}
                                                      onDelete={handleDelete}/>
                                    </DndProvider>
                                ))
                        }
                    </div>
                </div>
            </div>
        </Modal>
    )
}

export default MealCategoriesModal