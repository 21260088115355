import React, {useState} from "react";
import Modal from "./Modal";
import Button from "./Button";
import {AddressIcon, CityIcon, ContactIcon, EmailIcon, PhoneIcon, PibIcon, UserIcon} from "../icons/customer";

const Raw = ({label, value, startIcon}) => {

    const [copied, setCopied] = useState(false)

    const copy = () => {
        setCopied(true)
        navigator.clipboard.writeText(value);
    }

   return (
       <div className="flex justify-between mb-4 text-sm text-primary" onMouseLeave={() => setCopied(false)}>

           <div className="flex align-center">
               <div className="w-8">{startIcon}</div>
               <div className="">{label}</div>
           </div>
           <div className="group text-sm flex align-center hover:cursor-pointer">
               { copied &&
                   <div className="mr-2 bg-black text-white  rounded-md p-1.5">Uspešno kopirano</div>
               }
               { copied &&
                   <div className="mr-2 bg-aqua  rounded-md p-1.5"><img src="/check-circle.svg"/></div>

               }
               <div onClick={copy} className="invisible group-hover:visible mr-2 bg-pearl-white  rounded-md p-1.5"><img src="/copy.svg"/></div>
               <div className="group-hover:bg-pearl-white rounded-md p-1.5 font-medium">{value}</div>
           </div>
       </div>
   )
}

const CustomerInfo = ({recipe, isExtended, setIsExtended}) => {

    return (
        <div>
            <Modal show={isExtended} onClose={() => setIsExtended(false)} contentClassName="w-500 bg-white">
                <Modal.Header>Kontakt informacije</Modal.Header>
                <Modal.Body>
                    <Raw label="Ime"
                         value={recipe.nutrical_request.customer_name}
                         startIcon={<UserIcon/>}/>
                    <Raw label="Adresa"
                         value={recipe.nutrical_request.customer_address}
                         startIcon={<AddressIcon/>}/>
                    <Raw label="Grad"
                         value={recipe.nutrical_request.customer_city}
                         startIcon={<CityIcon/>}/>
                    <Raw label="Email"
                         value={recipe.nutrical_request.customer_email}
                         startIcon={<EmailIcon/>}/>
                    <Raw label="Broj telefona"
                         value={recipe.nutrical_request.customer_phone}
                         startIcon={<PhoneIcon/>}/>
                    <Raw label="Osoba za kontakt"
                         value={recipe.nutrical_request.contact_name}
                         startIcon={<ContactIcon/>}/>
                    <Raw label="PIB"
                         value={recipe.nutrical_request.customer_vat_number}
                         startIcon={<PibIcon/>}/>
                </Modal.Body>
                <Modal.Footer isValid={true}  className="flex justify-center items-center">
                    <Button onClick={() => setIsExtended(false)} color="primary" size="large">U redu</Button>
                </Modal.Footer>
            </Modal>

        </div>
    )
}

export default CustomerInfo