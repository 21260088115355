export const NutrientType = {
  VITAMIN: {
    value: "VITAMIN",
  },
  MINERAL: {
    value: "MINERAL",
  },
};

export const Nutrient = {
  vitamin_a: {
    type: NutrientType.VITAMIN,
    label: "Vitamin A",
    value: "vitamin_a",
  },
  vitamin_d: {
    type: NutrientType.VITAMIN,
    label: "Vitamin D",
    value: "vitamin_d",
  },
  vitamin_e: {
    type: NutrientType.VITAMIN,
    label: "Vitamin E",
    value: "vitamin_e",
  },
  vitamin_k: {
    type: NutrientType.VITAMIN,
    label: "Vitamin K",
    value: "vitamin_k",
  },
  vitamin_c: {
    type: NutrientType.VITAMIN,
    label: "Vitamin C",
    value: "vitamin_c",
  },
  thiamin_b1: {
    type: NutrientType.VITAMIN,
    label: "Tiamin",
    value: "thiamin_b1",
  },
  riboflavin_b2: {
    type: NutrientType.VITAMIN,
    label: "Riboflavin",
    value: "riboflavin_b2",
  },
  niacin_b3: {
    type: NutrientType.VITAMIN,
    label: "Niacin",
    value: "niacin_b3",
  },
  vitamin_b6: {
    type: NutrientType.VITAMIN,
    label: "Vitamin B6",
    value: "vitamin_b6",
  },
  folate_b9: {
    type: NutrientType.VITAMIN,
    label: "Folna kiselina",
    value: "folate_b9",
  },
  vitamin_b12: {
    type: NutrientType.VITAMIN,
    label: "Vitamin B12",
    value: "vitamin_b12",
  },
  biotin_b7: {
    type: NutrientType.VITAMIN,
    label: "Biotin",
    value: "biotin_b7",
  },
  pantothenic_acid_b5: {
    type: NutrientType.VITAMIN,
    label: "Pantotenska kiselina",
    value: "pantothenic_acid_b5",
  },

  // minerals

  potassium: {
    type: NutrientType.MINERAL,
    label: "Kalijum",
    value: "potassium",
  },
  chloride: {
    type: NutrientType.MINERAL,
    label: "Hloridi",
    value: "chloride",
  },
  calcium: {
    type: NutrientType.MINERAL,
    label: "Kalcijum",
    value: "calcium",
  },
  phosphorus: {
    type: NutrientType.MINERAL,
    label: "Fosfor",
    value: "phosphorus",
  },
  magnesium: {
    type: NutrientType.MINERAL,
    label: "Magnezijum",
    value: "magnesium",
  },
  iron: {
    type: NutrientType.MINERAL,
    label: "Gvožđe",
    value: "iron",
  },
  zinc: {
    type: NutrientType.MINERAL,
    label: "Cink",
    value: "zinc",
  },
  copper: {
    type: NutrientType.MINERAL,
    label: "Bakar",
    value: "copper",
  },
  manganese: {
    type: NutrientType.MINERAL,
    label: "Mangan",
    value: "manganese",
  },
  fluoride: {
    type: NutrientType.MINERAL,
    label: "Fluorid",
    value: "fluoride",
  },
  selenium: {
    type: NutrientType.MINERAL,
    label: "Selen",
    value: "selenium",
  },
  chromium: {
    type: NutrientType.MINERAL,
    label: "Hrom",
    value: "chromium",
  },
  molybdenum: {
    type: NutrientType.MINERAL,
    label: "Molibden",
    value: "molybdenum",
  },
  iodine: {
    type: NutrientType.MINERAL,
    label: "Jod",
    value: "iodine",
  },
};

export const displayAllNutrients = (nutrientFlag, type) => {
  for (let v in Nutrient) {
    if (Nutrient[v].type.value === type.value && !nutrientFlag[v]) {
      return false;
    }
  }

  return true;
};

export const getCheckedNutrients = (nutrientFlag, type) => {
  let result = [];
  for (let v in Nutrient) {
    if (Nutrient[v].type.value === type.value && nutrientFlag[v]) {
      result.push(Nutrient[v].label);
    }
  }

  return result.join(", ");
};
