import * as React from "react";
const SvgShoppingBasketIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={16}
    height={16}
    fill="none"
    {...props}
  >
    <g clipPath="url(#shoppingBasketIcon_svg__a)">
      <path
        fill="#313131"
        d="M15.362 9.25h-.539a3.342 3.342 0 0 0-2.587-3.864L11.442 0H5.159l-.366 2.47a2.3 2.3 0 0 0-2.529-.846A2.3 2.3 0 0 0 .74 4.492L2.194 9.25h-.957v2.813h1.044L2.968 16h10.663l.688-3.938h1.043V9.25Zm-1.422-.602c0 .206-.026.407-.076.602H9.22a2.398 2.398 0 1 1 4.72-.602ZM5.475 4.27l.215-1.457h5.219l.37 2.51a3.338 3.338 0 0 0-2.537 1.514 2.76 2.76 0 0 0-2.217.866L5.475 4.27Zm1.523 4.98-.123-.403c.26-.589.818-.993 1.446-1.068a3.328 3.328 0 0 0-.06 1.471H6.997ZM10.632.937l.138.938H5.83l.138-.938h4.664ZM1.636 4.219a1.35 1.35 0 0 1 .1-1.036 1.36 1.36 0 0 1 2.5.24l.05.167-1.443 1.303.628.696 1.118-1.01.337 1.103-1.443 1.303.628.696 1.118-1.01.788 2.58H3.174L1.636 4.218Zm.539 5.97h12.25v.937H2.175v-.938Zm4.156 1.874h3.937v1.22H6.331v-1.22Zm6.512 3H3.756l-.524-3h2.161v2.157h5.813v-2.156h2.161l-.524 3Z"
      />
    </g>
    <defs>
      <clipPath id="shoppingBasketIcon_svg__a">
        <path fill="#fff" d="M0 0h16v16H0z" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgShoppingBasketIcon;
