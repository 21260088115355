import React, { useState } from "react";
import "../css/nutricalRequestTable.css";
import Button from "./Button";
import Status from "./Status";
import DeleteModal from "./DeleteModal";
import { format } from "date-fns";
import { TrashIcon } from "../icons";
import NutricalRequestFormModal from "./NutricalRequestFormModal";
import { useNavigate } from "react-router-dom";

const NutricalRequestsTable = (props) => {
  let { data, onDelete, isLoading } = props;

  const navigate = useNavigate();
  const [selectedForDeletion, setSelectedForDeletion] = useState(null);

  const isDeleteModalOpen = Boolean(selectedForDeletion);

  const handleOpenBtnClick = (id) => {
    navigate(`/requests/${id}`);
  };

  const handleConfirmDeleteBtnCLick = () => {
    onDelete(selectedForDeletion.id);
    setSelectedForDeletion(null);
  };

  return (
    <div className="nutrical-request-table mt-[24px]">
      <table className="">
        <thead>
          <tr>
            <th className="w-20-pct-sm">Redni broj</th>
            <th className="w-40-pct-sm">Datum i vreme</th>
            <th className="w-40-pct-sm">Naziv recepta i klijenta</th>
            <th className="w-40-pct-sm">Paket</th>
            <th className="w-40-pct-sm pl-35">Status</th>
            <th className="pr-50 w-[160px]">Akcije</th>
          </tr>
        </thead>
        <tbody>
          {data &&
            data.map((e) => (
              <tr key={e.id} className="text-primary">
                <td className="font-medium">{e.id}</td>
                <td className="">
                  <div>
                    {e.created_at
                      ? format(new Date(e.created_at), "dd.MM.yyyy")
                      : ""}
                  </div>
                  <div>
                    {e.created_at
                      ? format(new Date(e.created_at), "HH:mm")
                      : ""}
                  </div>
                </td>
                <td>
                  <div className="font-medium">{e.recipe_name}</div>
                  <div>{e.customer_name}</div>
                </td>
                <td className="font-semibold uppercase text-package">
                  {e.subscription_package}
                </td>
                <td className="">
                  <Status status={e.status} />
                </td>
                <td className="actions">
                  <div className="flex">
                    {e.status === "INIT" && (
                      <Button
                        className="w-[90px] rounded-[4px]"
                        color="secondary"
                        onClick={() => handleOpenBtnClick(e.id)}
                      >
                        Započni
                      </Button>
                    )}
                    {e.status !== "INIT" && (
                      <Button
                        className="w-[90px] rounded-[4px] border border-black"
                        color="white"
                        onClick={() => handleOpenBtnClick(e.id)}
                      >
                        Otvori
                      </Button>
                    )}

                    {/*<button className="bg-white border border-black w-[90px] h-[30px] font-semibold rounded-[4px]*/}
                    {/*hover:bg-black hover:text-white hover:cursor-pointer" onClick={() => handleOpenBtnClick(e.id)}>Otvori</button>}*/}
                    <TrashIcon
                      className="ml-4 hover:cursor-pointer"
                      onClick={() => setSelectedForDeletion(e)}
                    />
                  </div>

                  {/*<Menu>*/}
                  {/*    <MenuItem onClick={() => setSelectedForDeletion(e)} startIcon={<img src="/delete-red.svg"/>}>Obriši</MenuItem>*/}
                  {/*</Menu>*/}
                </td>
              </tr>
            ))}
        </tbody>
      </table>
      {isLoading && <div className="loader-line"></div>}
      <DeleteModal
        open={isDeleteModalOpen}
        title={"Obriši"}
        itemName={selectedForDeletion ? selectedForDeletion.recipe_name : ""}
        message="Da li ste sigurni da želite da obrišete ovaj rekord?"
        onDelete={handleConfirmDeleteBtnCLick}
        onClose={() => setSelectedForDeletion(null)}
      />
    </div>
  );
};

export default NutricalRequestsTable;
