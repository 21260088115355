import * as React from "react";
const SvgTableIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={16}
    height={14}
    fill="#989898"
    {...props}
  >
    <path d="M14.188.813H1.812A1.125 1.125 0 0 0 .688 1.938v10.124a1.125 1.125 0 0 0 1.125 1.126h12.376a1.125 1.125 0 0 0 1.124-1.126V1.938A1.125 1.125 0 0 0 14.188.813Zm0 11.25H1.812V1.937h12.376v10.126Zm-2.25-7.313a.563.563 0 0 1-.563.563h-6.75a.563.563 0 1 1 0-1.125h6.75a.562.562 0 0 1 .563.562Zm0 2.25a.563.563 0 0 1-.563.563h-6.75a.563.563 0 1 1 0-1.125h6.75a.562.562 0 0 1 .563.562Zm0 2.25a.563.563 0 0 1-.563.563h-6.75a.563.563 0 1 1 0-1.126h6.75a.562.562 0 0 1 .563.563Z" />
  </svg>
);
export default SvgTableIcon;
