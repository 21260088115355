import axios from "axios";
import {
  host,
  recipe_report_by_id_url,
  recipe_report_download_url,
  recipe_report_generate_url,
} from "../config/api";
import { RecipeType } from "./RecipeService";

export const getReport = async (requestId, recipeType) => {
  return axios({
    method: "GET",
    url:
      recipeType.value === RecipeType.RESTAURANT.value
        ? `${host}/v1/reports/restaurant-request/${requestId}`
        : `${host}/v1/reports/nutrical-request/${requestId}`,
  });
};

export const downloadPDF = async (recipeId, docType) => {
  return axios({
    method: "GET",
    url: `${recipe_report_generate_url}/${recipeId}`,
    params: {
      type: docType,
    },
    responseType: "blob",
  }).then((response) => {
    return new Blob([response.data], { type: "application/pdf" });
  });
};

export const downloadReport = async (recipeId, requestId) => {
  return axios({
    method: "GET",
    url: `${recipe_report_download_url}/${recipeId}`,
    responseType: "blob",
  }).then((response) => {
    const pdf = new Blob([response.data], { type: "application/zip" });
    const url = window.URL.createObjectURL(pdf);
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", `${requestId}-izveštaj.zip`);
    document.body.appendChild(link);
    link.click();
  });
};
