import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import NutricalRequestsContainer from "./containers/NutricalRequestsContainer";
import RecipeContainer from "./containers/RecipeContainer";
import PdfPreview from "./containers/PdfPreview";
import { ReactKeycloakProvider } from "@react-keycloak/web";
import keycloak from "./security/Keycloak";
import PrivateRoute from "./security/PrivateRoute";
import FontProvider from "./components/FontProvider";
import ClientsContainer from "./containers/ClientsContainer";
import ClientRequestsContainer from "./containers/ClientRequestsContainer";
import { RecipeType } from "./service/RecipeService";
import RestaurantMenuContainer from "./containers/RestaurantMenuContainer";
import RestaurantReportContainer from "./containers/RestaurantReportContainer";
import NutricalReportContainer from "./containers/NutricalReportContainer";
import RestaurantMenuPreview from "./containers/RestaurantMenuPreview";
import {QueryClient, QueryClientProvider} from "react-query";

const root = ReactDOM.createRoot(document.getElementById("root"));

const router = createBrowserRouter([
  {
    path: "/",
    element: (
      <PrivateRoute>
        <NutricalRequestsContainer />
      </PrivateRoute>
    ),
  },
  {
    path: "/requests",
    element: (
      <PrivateRoute>
        <NutricalRequestsContainer />
      </PrivateRoute>
    ),
  },
  {
    path: "/requests/:requestId",
    element: (
      <PrivateRoute>
        <RecipeContainer recipeType={RecipeType.NUTRICAL} />
      </PrivateRoute>
    ),
  },
  {
    path: "/requests/:requestId/report",
    element: (
      <PrivateRoute>
        <NutricalReportContainer />
      </PrivateRoute>
    ),
  },
  {
    path: "/requests/:requestId/report/preview",
    element: (
      <PrivateRoute>
        <PdfPreview />
      </PrivateRoute>
    ),
  },
  {
    path: "/clients/:clientId/requests/:requestId/report/preview",
    element: (
      <PrivateRoute>
        <PdfPreview />
      </PrivateRoute>
    ),
  },
  {
    path: "/clients",
    element: (
      <PrivateRoute>
        <ClientsContainer />
      </PrivateRoute>
    ),
  },
  {
    path: "/clients/:clientId/menu",
    element: (
      <PrivateRoute>
        <RestaurantMenuContainer />
      </PrivateRoute>
    ),
  },
  {
    path: "/clients/:clientId/menu/preview",
    element: (
      <PrivateRoute>
        <RestaurantMenuPreview />
      </PrivateRoute>
    ),
  },
  {
    path: "/clients/:clientId/requests",
    element: (
      <PrivateRoute>
        <ClientRequestsContainer />
      </PrivateRoute>
    ),
  },
  {
    path: "/clients/:clientId/requests/:requestId",
    element: (
      <PrivateRoute>
        <RecipeContainer recipeType={RecipeType.RESTAURANT} />
      </PrivateRoute>
    ),
  },
  {
    path: "/clients/:clientId/requests/:requestId/report",
    element: (
      <PrivateRoute>
        <RestaurantReportContainer />
      </PrivateRoute>
    ),
  },
]);

const queryClient = new QueryClient()


root.render(
    <QueryClientProvider client={queryClient}>
      <ReactKeycloakProvider
        initOptions={{ onLoad: "login-required" }}
        authClient={keycloak}
      >
        <FontProvider>
          <RouterProvider router={router} />
        </FontProvider>
      </ReactKeycloakProvider>
    </QueryClientProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
