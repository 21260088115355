import React from "react"
import {CheckboxIcon} from "../icons";


const CheckboxInput = ({checked}) => {

    return (
        <div className="relative">
            <div className={`w-[18px] h-[18px] rounded-[4px] flex items-center justify-center
                            ${checked ? 'bg-checkbox-checked': 'bg-checkbox-unchecked'}`}>
                {
                    checked && <CheckboxIcon  width={10} height={7} src="/checkbox.svg"/>
                }
            </div>
        </div>
    )
}

export default CheckboxInput