import * as React from "react";
const SvgPackegingTypeIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={16}
    height={16}
    fill="none"
    {...props}
  >
    <g fill="#313131" clipPath="url(#packegingTypeIcon_svg__a)">
      <path d="M13.11 2.262V0H2.89v2.262L.782 5.355V16H15.22V5.355l-2.11-3.093ZM3.827.937h8.344v1H3.828v-1Zm-2.11 14.126V5.645l1.89-2.77h8.146l-1.691 2.48v9.707H1.719Zm12.563 0H11V5.645l1.64-2.407 1.641 2.407v9.418Z" />
      <path d="M5.875 6.813c-.735 0-1.413.396-1.91 1.117-.461.668-.715 1.547-.715 2.476 0 .93.254 1.808.714 2.476C4.462 13.603 5.14 14 5.875 14c.735 0 1.413-.397 1.91-1.118.461-.668.715-1.547.715-2.476s-.254-1.808-.714-2.476c-.498-.72-1.176-1.117-1.911-1.117Zm-1.688 3.593c0-1.44.773-2.656 1.688-2.656.049 0 .097.004.145.01-.055 1.159-.311 1.8-.58 2.472-.247.617-.5 1.25-.606 2.247-.391-.491-.646-1.245-.646-2.073Zm1.688 2.656c-.049 0-.097-.003-.145-.01.055-1.159.311-1.799.58-2.472.247-.616.5-1.25.606-2.246.391.49.646 1.245.646 2.072 0 1.44-.772 2.656-1.687 2.656Z" />
    </g>
    <defs>
      <clipPath id="packegingTypeIcon_svg__a">
        <path fill="#fff" d="M0 0h16v16H0z" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgPackegingTypeIcon;
