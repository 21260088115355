import React, { useEffect, useRef, useState } from "react";
import Navigation from "../components/Navigation";
import Button from "../components/Button";
import { EditIcon } from "../icons";
import classNames from "classnames";
import { Search } from "../components/Search";
import RestaurantRequestTable from "../components/RestaurantRequestTable";
import useRestaurantRequests from "../hooks/useRestaurantRequests";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import RestaurantRequestForm from "../components/RestaurantRequestForm";
import NextStep from "../components/NextStep";
import { getRestaurant, updateRestaurant } from "../service/RestaurantService";
import Pagination from "../components/Pagination";
import useUrlParams from "../hooks/useUrlParams";
import { updateUrlQueryParams } from "../service/UrlUtils";
import RestaurantForm from "../components/RestaurantForm";
import {useRestaurant} from "../store/RestaurantStore";
import {useQueryClient} from "react-query";

export const RestaurantRequestType = {
  RECIPE: {
    label: "Recepti",
    value: "RECIPE",
  },
  SUBRECIPE: {
    label: "Sub-recepti",
    value: "SUBRECIPE",
  },
};

const ClientRequestsContainer = (props) => {
  const queryClient = useQueryClient();
  const { clientId } = useParams();
  const navigate = useNavigate();

  const {data: restaurant} = useRestaurant(clientId)
  const restaurantNameRef = useRef();
  const [showEditForm, setShowEditForm] = useState(false);

  const urlParams = useUrlParams();
  let [params, setParams] = useState({
    page: urlParams["page"] | 0,
    size: urlParams["size"] | 10,
    "restaurant-request-type": urlParams["restaurant-request-type"]
      ? urlParams["restaurant-request-type"]
      : RestaurantRequestType.RECIPE.value,
    "search-text": urlParams["search-text"],
    "restaurant-id": clientId,
  });

  let [
    restaurantRequests,
    setRestaurantRequests,
    refreshRestaurantRequests,
    isLoading,
    error,
    handleDeleteRestaurantRequest,
    totalPages,
  ] = useRestaurantRequests(clientId);

  const [showRestaurantRequestForm, setShowRestaurantRequestForm] =
    useState(false);

  useEffect(() => {
/*    getRestaurant(clientId)
      .then((response) => setRestaurant(response.data))
      .catch((error) => alert("Server error"));*/

    refreshRestaurantRequests(params);
  }, []);

  const handleSearchChange = (value) => {
    let localParams = {
      ...params,
      "search-text": value,
      page: 0,
    };
    setParams(localParams);
    refreshRestaurantRequests(localParams);
    updateUrlQueryParams(localParams);
  };

  const handlePageChange = (page) => {
    if (!page) {
      page = 0;
    }

    let localParams = {
      ...params,
      page: page,
    };

    setParams(localParams);
    refreshRestaurantRequests(localParams);
    updateUrlQueryParams(localParams);
  };

  const handleTypeChange = (type) => {
    let localParams = {
      ...params,
      "restaurant-request-type": type.value,
      page: 0,
    };

    setParams(localParams);
    refreshRestaurantRequests(localParams);
    updateUrlQueryParams(localParams);
  };

  const handleRestaurantNameChange = (e) => {
  /*  let restaurantLocal = {
      ...restaurant,
      name: e.target.value,
    };

    setRestaurant(restaurantLocal);
    updateRestaurant(restaurantLocal).catch((err) => {});*/
  };

  const handleEditRestaurantNameBtnClick = () => {
   /* if (restaurantNameRef && restaurantNameRef.current) {
      restaurantNameRef.current.focus();
    }*/
    setShowEditForm(true)
  };



  return (
    <div className="flex flex-col items-center justify-center mt-8">
      <Navigation />
      <div className="w-full max-w-[1000px] pb-[10px]">
        <div
          className="font-semibold text-primary text-[18px] flex justify-between
                       bg-white rounded-[16px] h-[58px] shadow-section w-full items-center px-[20px]"
        >
          <input
            className="px-4 py-2 w-full mr-8"
            ref={restaurantNameRef}
            type="text"
            value={restaurant?.name || ""}
            onChange={handleRestaurantNameChange}
          />
          <div
            onClick={handleEditRestaurantNameBtnClick}
            className="w-[26px] h-[26px] flex items-center justify-center rounded-[6px] bg-secondary-action
                            hover:cursor-pointer hover:opacity-90"
          >
            <EditIcon width={13} height={13} />
          </div>
        </div>
        <div className="w-full px-[16px] bg-white flex items-center justify-between rounded-[16px] mt-8">
          <div className="rounded-[8px] bg-input h-[42px] flex items-center p-[3px] rounded-[8px]">
            {[
              RestaurantRequestType.RECIPE,
              RestaurantRequestType.SUBRECIPE,
            ].map((type) => (
              <div
                onClick={() => handleTypeChange(type)}
                className={classNames(
                  "w-[140px] flex items-center justify-center h-full rounded-[8px] hover:cursor-pointer text-sm",
                  {
                    "bg-primary-action text-white font-semibold":
                      params["restaurant-request-type"] === type.value,
                    "bg-input text-light hover:text-black":
                      params["restaurant-request-type"] !== type.value,
                  }
                )}
              >
                {type.label}
              </div>
            ))}
          </div>
          <Search
            placeholder="Pretraži recept"
            value={params["search-text"]}
            onChange={handleSearchChange}
          />
          <Button
            color="secondary"
            size="large"
            onClick={() => setShowRestaurantRequestForm(true)}
          >
            {params["restaurant-request-type"] ===
              RestaurantRequestType.RECIPE.value && <div>+ Kreiraj recept</div>}
            {params["restaurant-request-type"] ===
              RestaurantRequestType.SUBRECIPE.value && (
              <div>+ Kreiraj sub-recept</div>
            )}
          </Button>
        </div>

        <RestaurantRequestTable
          restaurantId={clientId}
          data={restaurantRequests}
          isLoading={isLoading}
          onDelete={handleDeleteRestaurantRequest}
        />

        <Pagination
          totalPages={totalPages}
          page={params?.page}
          onPageChange={handlePageChange}
        />

        <NextStep
          onNext={() => navigate(`/clients/${clientId}/menu`)}
          title="Kreiraj jelovnik"
          isValid={() => restaurantRequests && restaurantRequests.length > 0}
        />
      </div>
      {showRestaurantRequestForm && (
        <RestaurantRequestForm
          restaurantId={clientId}
          restaurantRequestType={params["restaurant-request-type"]}
          onClose={() => setShowRestaurantRequestForm(false)}
          onSubmit={() => {
            setShowRestaurantRequestForm(false);
            refreshRestaurantRequests(params);
          }}
        />
      )}
      {showEditForm && (
          <RestaurantForm
              onClose={() => setShowEditForm(false)}
              onSubmit={() => {
                setShowEditForm(false);
                queryClient.invalidateQueries(["restaurant", clientId])
                //refreshRestaurants(params);
              }}
              defaultValue={restaurant}
          />
      )}
    </div>
  );
};

export default ClientRequestsContainer;
