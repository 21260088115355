import { useEffect, useState } from "react";
import axios from "axios";
import { recipes_url } from "../config/api";
import { getRecipe } from "../service/RecipeService";

const useRecipe = (id, onInitialFetchCallback, recipeType) => {
  const [recipe, setRecipe] = useState(null);
  let [isLoading, setIsLoading] = useState(false);
  const [isFetching, setIsFetching] = useState(false);
  let [error, setError] = useState(null);
  const [timeoutExec, setTimeoutExec] = useState(null);

  useEffect(() => {
    getRecipe(id, recipeType)
      .then((response) => {
        let recipe = response.data;
        setRecipe(recipe);
        setError(null);
        onInitialFetchCallback(response.data);
      })
      .catch((err) => {
        setError(err.message);
        setRecipe(null);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  const refreshRecipe = () => {
    return getRecipe(id).then((response) => {
      setRecipe(response.data);
      return response.data;
    });
  };

  const updateRecipe = ({ recipe, push = true, fetch = false, delay = 0 }) => {
    if (!recipe) {
      return;
    }

    setRecipe(recipe);

    if (!push) {
      return;
    }

    if (timeoutExec) {
      clearTimeout(timeoutExec);
    }

    if (fetch) {
      setIsFetching(true);
    }

    const exec = setTimeout(() => {
      setIsLoading(true);
      axios({
        url: `${recipes_url}/${recipe.id}`,
        method: "POST",
        data: recipe,
      })
        .then((response) => {
          if (fetch) {
            let recipe = response.data;
            setRecipe(recipe);
          }
          setError(null);
        })
        .catch((err) => {
          setError(err.message);
        })
        .finally(() => {
          setIsLoading(false);
          setIsFetching(false);
        });
    }, delay);

    setTimeoutExec(exec);
  };

  return [recipe, updateRecipe, isLoading, isFetching, refreshRecipe];
};

export default useRecipe;
