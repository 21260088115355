import axios from "axios";
import { host } from "../config/api";

export const createNutricalRequest = (nutricalRequest) => {
  return axios({
    method: "POST",
    url: `${host}/v1/nutrical-requests/operators`,
    data: nutricalRequest,
  });
};

export const updateSubscriptionPackage = (
  nutricalRequestId,
  subscriptionPackage
) => {
  return axios({
    method: "post",
    url: `${host}/v1/nutrical-requests/${nutricalRequestId}/update-subscription-package/${subscriptionPackage}`,
  });
};

export const updateNutricalRequestStatus = (requestId, status) => {
  return axios({
    method: "POST",
    url: `${host}/v1/nutrical-requests/${requestId}/update-status/${status}`,
  });
};
