import React, {useEffect, useState} from "react"
import Modal from "./Modal";
import {density_url} from "../config/api";
import axios from "axios";
import {SearchIcon} from "../icons";
import Button from "./Button";
import TextField from "./TextField";
import NumberField from "./NumberField";


const DensityModal = ({show, onClose, onSubmit}) => {

    const [data, setData] = useState(null)
    const [searchText, setSearchText] = useState("")
    const [density, setDensity] = useState("")

    useEffect(() => {
        axios({
            method: "GET",
            url: density_url,
        }).then((response) => {
            setData(response.data)
        }).catch(error => {
            console.log(error)
        })
    }, [])


    return (
        <Modal contentClassName="bg-white w-[600px] h-[80%]" show={show} onClose={onClose}>
            <Modal.Header>Izaberi gustinu</Modal.Header>
            <Modal.Body>
                <div className="overflow-hidden h-full flex flex-col">
                    <div className="text-primary text-sm">Pretrazite bazu ili rucno definisite gustinu</div>
                    <div className="relative mt-[15px]">
                        <SearchIcon className="fill-primary-icon absolute left-[12px] top-[12px]" width={16}/>
                        <input className="w-full bg-input rounded-[6px] h-[42px] text-sm px-10" type="text"
                               value={searchText}
                               onChange={(e) => setSearchText(e.target.value)}/>
                    </div>
                    <div className="grow bg-section-header p-[16px] rounded-[6px] mt-[12px] overflow-auto scroll-content">
                        {
                            data?.categories.filter((category) => category?.densities.length > 0).map(category => (
                                <div key={category.name} className="category mb-4">
                                    <div className="font-semibold text-primary text-xs mb-2">{category.name}</div>
                                    <div className="text-xs">
                                        {
                                            category?.densities
                                                .filter(density => density.name.toLowerCase().includes(searchText.toLowerCase()))
                                                .map(density => (
                                                    <div key={density.name} className="density h-[28px] flex space-between items-center
                                                    pl-4 pr-2 rounded-[4px]
                                                    hover:bg-white-shade
                                                    hover:cursor-pointer"
                                                    onClick={() => setDensity(density.density)}>
                                                        <div>{density.name}</div>
                                                        <div>{density.density}</div>
                                                    </div>
                                                ))
                                        }
                                    </div>
                                </div>
                            ))
                        }
                    </div>
                    <div className="bg-white flex space-between items-center  flex-shrink-0 flex-grow-0 mt-4">
                        <div className="flex items-center text-sm text-primary">
                            <div>Gustina</div>
                            <NumberField inputClassName="w-[120px] ml-2 text-center"
                                         isDecimal={true}
                                         value={density}
                                         onChange={(e) => setDensity(e.target.value)}/>
                        </div>
                        <div>
                            <Button color="primary" size="large" onClick={() => onSubmit(density)}>Primeni</Button>
                        </div>
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    )
}

export default DensityModal