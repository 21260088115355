import * as React from "react";
const SvgSearchIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="#fff"
    viewBox="0 0 19 19"
    {...props}
  >
    <path d="m18.786 17.753-4.569-4.568a8.04 8.04 0 1 0-1.032 1.032l4.568 4.57a.73.73 0 1 0 1.033-1.034ZM1.48 8.05a6.57 6.57 0 1 1 6.57 6.57 6.577 6.577 0 0 1-6.57-6.57Z" />
  </svg>
);
export default SvgSearchIcon;
