import React, {useState} from "react";
import axios from "axios";
import {nutrical_requests_url} from "../config/api";

const useNutricalRequests = () => {

    const [nutricalRequests, setNutricalRequests] = useState([])
    const [totalPages, setTotalPages] = useState(null)
    const [isLoading, setIsLoading] = useState(false)
    const [error, setError] = useState(null)
    const [timeoutExec, setTimeoutExec] = useState(null)



    const refreshNutricalRequests = (params) => {
        setIsLoading(true)
        setNutricalRequests([])

        if (timeoutExec) {
            clearTimeout(timeoutExec)
        }

        const exec = setTimeout(() => {
            axios({
                url: `${nutrical_requests_url}`,
                method: 'GET',
                params: params
            }).then(response => {
                setNutricalRequests(response.data.nutrical_requests);
                setTotalPages(response.data.total_pages)
                setError(null);
            }).catch(err => {
                setError(err.message);
                setNutricalRequests(null);
            }).finally(() => {
                setIsLoading(false);
            })
        }, 300)

        setTimeoutExec(exec)
    }

    const deleteNutricalRequest = (id) => {
        let result = nutricalRequests.filter(e => e.id !== id)
        setNutricalRequests(result)

        axios({
            url: `${nutrical_requests_url}/${id}`,
            method: 'DELETE',
        })
    }

    return [nutricalRequests, setNutricalRequests, refreshNutricalRequests, isLoading, error, deleteNutricalRequest, totalPages]
}

export default useNutricalRequests