import * as React from "react";
const SvgArrowRightIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={100}
    height={100}
    fill="none"
    stroke="#fff"
    viewBox="0 0 16 16"
    {...props}
  >
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M3.334 8h9.333M8 3.333 12.667 8 8 12.667"
    />
  </svg>
);
export default SvgArrowRightIcon;
