import React, {useState} from "react";
import "../css/textAreaField.css"

const TextAreaField = ({value, onChange, label, id, className, rows, placeholder}) => {

    const [isEmpty, setIsEmpty] = useState(!value)

    const handleOnChangeEvent = (e) => {
        setIsEmpty(!e.target.value)
        onChange(e)
    }

    let classList = isEmpty ? "text-area-field empty-text-area-field" : "text-area-field"
    classList = classList.concat(" ", className)

    return (
        <div className={classList}>
            {label && <label htmlFor={id} className="label">{label}</label>}
            <textarea id={id} onChange={handleOnChangeEvent} value={value || ''} rows={rows || 0}
                      placeholder={placeholder}></textarea>
        </div>
    )
}

export default TextAreaField