import * as React from "react";
const SvgArrowIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={13}
    height={7}
    fill="#5A5A5A"
    {...props}
  >
    <path d="M12.81 6.813a.652.652 0 0 1-.46.187.662.662 0 0 1-.46-.187L6.5 1.536 1.11 6.813a.657.657 0 0 1-.92 0 .63.63 0 0 1 0-.9L6.04.187A.651.651 0 0 1 6.5 0a.662.662 0 0 1 .46.187l5.85 5.726a.638.638 0 0 1 .19.45.625.625 0 0 1-.19.45Z" />
  </svg>
);
export default SvgArrowIcon;
