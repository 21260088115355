import {
    deleteMealCategory,
    getMealCategories,
    saveMealCategory,
    updateMealCategories
} from "../service/MealCategoryService";
import {useMutation, useQuery, useQueryClient} from "react-query";


export const useMealCategories = (clientId) => {
    return useQuery({
        queryKey: ['meal-categories'],
        queryFn: () => getMealCategories(clientId).then((response) => response.data.meal_categories),
    })
}

export const useRestaurantCategoriesDeletion = () => {
    const client = useQueryClient()

    return useMutation({
        mutationFn: deleteMealCategory,
        onSuccess: () => {
            client.invalidateQueries(['meal-categories'])
            client.invalidateQueries(['restaurant-meals'])
        },
        onError: (err) => {
            console.log(err)
            alert('Techical Errror')
        },
    })
}

export const useRestaurantCategoriesCreation = () => {
    const client = useQueryClient()

    return useMutation({
        mutationFn: saveMealCategory,
        onSuccess: () => {
            client.invalidateQueries(['meal-categories'])
        },
        onError: (err) => {
            console.log(err)
            alert('Techical Errror')
        },
    })
}

export const useRestaurantCategoriesUpdate = () => {
    const client = useQueryClient()

    return useMutation({
        mutationFn: updateMealCategories,
        onSuccess: () => {
            client.invalidateQueries(['meal-categories'])
        },
        onError: (err) => {
            console.log(err)
            alert('Techical Errror')
        },
    })
}