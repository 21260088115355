import * as React from "react";
const SvgTrafficLightIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={18}
    height={18}
    fill="#989898"
    {...props}
  >
    <path d="M17.438 9a3.937 3.937 0 1 0-6.75 2.752v3.998a.562.562 0 0 0 .814.503l1.998-1 1.998 1a.562.562 0 0 0 .815-.503v-3.998A3.924 3.924 0 0 0 17.438 9ZM13.5 6.187a2.812 2.812 0 1 1 0 5.625 2.812 2.812 0 0 1 0-5.624Zm.252 7.935a.562.562 0 0 0-.504 0l-1.435.718v-2.283a3.932 3.932 0 0 0 3.374 0v2.283l-1.435-.718Zm-4.19-.622a.562.562 0 0 1-.562.563H2.812a1.125 1.125 0 0 1-1.124-1.126v-9a1.125 1.125 0 0 1 1.125-1.124h12.374a1.125 1.125 0 0 1 1.126 1.124.563.563 0 0 1-1.125 0H2.812v9H9a.563.563 0 0 1 .563.563ZM8.438 9.562a.562.562 0 0 1-.563.563H5.062a.563.563 0 0 1 0-1.125h2.813a.563.563 0 0 1 .563.563Zm0-2.25a.563.563 0 0 1-.563.563H5.062a.563.563 0 1 1 0-1.125h2.813a.563.563 0 0 1 .563.563Z" />
  </svg>
);
export default SvgTrafficLightIcon;
