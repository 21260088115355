import React, { useState } from "react";
import ReactPaginate from "react-paginate";
import NextPage from "../icons/NextPage";
import PrevPage from "../icons/PrevPage";
import { NUMBER_REGEX } from "../config/config";

const Pagination = ({ totalPages, page, onPageChange }) => {
  const [pageInput, setPageInput] = useState("");

  if (totalPages < 2) {
    return;
  }

  const handlePageInputKeyUp = (e) => {
    if (e.key === "Enter") {
      handlePageInputChange(pageInput);
    }
  };

  const handlePageInputChange = (value) => {
    let page = value;

    if (!page) {
      setPageInput(page);
      return;
    }

    if (page > totalPages) {
      page = 1;
    }

    setPageInput(page);
    onPageChange(page - 1);
  };

  return (
    <div>
      {totalPages && (
        <div
          className="flex space-between items-center w-full  bg-white rounded-[12px]
                    mt-4 mb-8 h-[46px] px-4 text-xs"
        >
          <div className="basis-[200px]">
            Strana {page + 1} od {totalPages}
          </div>
          <ReactPaginate
            className="flex grow justify-center items-center]"
            pageClassName="w-[26px] h-[26px] flex items-center justify-center text-xs"
            activeClassName="bg-body rounded-[8px] font-semibold text-sm"
            nextClassName="flex items-center ml-4"
            previousClassName="flex items-center mr-4"
            breakLabel="..."
            nextLabel={<NextPage />}
            previousLabel={<PrevPage />}
            onPageChange={(e) => onPageChange(e.selected)}
            pageRangeDisplayed={5}
            pageCount={totalPages}
            renderOnZeroPageCount={null}
            forcePage={Number.parseInt(page)}
          />
          <div className="basis-[200px] flex items-center justify-end">
            <div>Idi na stranu:</div>
            <input
              className="w-[32px] h-[26px] ml-2 bg-input rounded-[6px] text-center"
              type="text"
              pattern={NUMBER_REGEX}
              value={pageInput}
              onChange={(e) => setPageInput(e.target.value)}
              onBlur={(e) => handlePageInputChange(e.target.value)}
              onKeyUp={handlePageInputKeyUp}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default Pagination;
