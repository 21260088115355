import React from "react";
import { ClearInputIcon, SearchIcon } from "../icons";

export const Search = (props) => {
  let { value, onChange, placeholder } = props;

  return (
    <div className="w-full">
      <div className="w-full bg-white rounded-[12px] p-[16px] relative">
        <SearchIcon
          width={16}
          className="absolute left-[28px] top-[28px] fill-primary-icon"
        />
        <input
          className="bg-input w-full h-[42px] pl-[45px] pr-[20px] rounded-[6px] text-sm text-primary"
          value={value || ""}
          onChange={(e) => onChange(e.target.value)}
          type="text"
          placeholder={placeholder}
        />
        <ClearInputIcon
          className="absolute right-[30px] top-[29px] hover:cursor-pointer"
          onClick={() => onChange("")}
        />
      </div>
    </div>
  );
};
