import React, {useState} from "react";
import "../css/textField.css"
import classNames from "classnames";

const TextField = ({value, onChange, label, id, className, pattern, mandatory, errorMsg, placeholder,
                    inputClassName}) => {

    const [isEmpty, setIsEmpty] = useState(!value)

    const handleOnChangeEvent = (e) => {
        if (!e.target.validity.valid) {
            return
        }
        setIsEmpty(!e.target.value)
        onChange(e)
    }

    const classes = classNames(className, {
        "text-field": true,
        "empty-text-field": isEmpty
    })


    const inputClasses = inputClassName ? inputClassName : "py-0 px-[15px] bg-input"

    // background-color: #EFEFEF !important;
    // border: 1px solid transparent !important;

    return (
        <div className={classes}>
            { label &&
                <label htmlFor={id} className="label">
                    <span>{label}</span>
                    {mandatory && <span className="mandatory">*</span>}
                </label>
            }
            <input className={inputClasses} pattern={pattern} id={id} type="text" value={value || ""}
                   onChange={handleOnChangeEvent} autoComplete="off" placeholder={placeholder}/>
            <div className="error">{errorMsg}</div>
        </div>
    )
}

export default TextField