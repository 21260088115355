import React from "react";
import {CheckboxIcon} from '../icons';
import classNames from "classnames";

const Checkmark = ({checked, size='md'}) => {

    let width = 13
    if (size === "sm") {
        width = 10
    }

    return (
        <div className={classNames({
            "rounded-[6px] flex items-center justify-center shrink-0 grow-0 hover:cursor-pointer": true,
            "w-[24px] h-[24px]": size === "md",
            "w-[20px] h-[20px]": size === "sm",
            "bg-checked": checked,
            "bg-unchecked": !checked,

        })}>
            {checked && <CheckboxIcon width={width} height={width}/>}
        </div>
    )
}

export default Checkmark