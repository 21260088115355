import React from "react";


const useUrlParams = () => {
    const searchParams = new URLSearchParams(window.location.search);

    let params = {}
    for (const [key, value] of searchParams) {
        params[key] = value;
    }

    return params
}

export default useUrlParams