
import axios from "axios";
import {host} from "../config/api";

export const publishDigitalMenu = (restaurantId) => {
    return axios({
        url: `${host}/v1/restaurant-digital-menu/publish/restaurants/${restaurantId}`,
        method: "POST",
    });
};

export const getQR = (restaurantId) => {
    return axios({
        url: `${host}/v1/restaurant-digital-menu/qr-code/restaurants/${restaurantId}`,
        method: "GET",
    });
}