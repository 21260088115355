import * as React from "react";
const SvgDrinkFoodIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={21}
    height={18}
    fill="#989898"
    {...props}
  >
    <path d="M19.915 1.28a.78.78 0 0 0 .156-.818.744.744 0 0 0-.265-.336.697.697 0 0 0-.4-.126H.72a.698.698 0 0 0-.4.126.744.744 0 0 0-.264.337.78.78 0 0 0 .156.818l9.133 9.53V16.5H6.47a.704.704 0 0 0-.508.22.767.767 0 0 0-.21.53c0 .199.075.39.21.53.135.141.318.22.508.22h7.188c.19 0 .373-.079.508-.22a.767.767 0 0 0 .21-.53.767.767 0 0 0-.21-.53.704.704 0 0 0-.508-.22h-2.875v-5.69l9.133-9.53Zm-17.46.22h15.217L16.234 3H3.892L2.454 1.5Zm7.608 7.94L5.33 4.5h9.468l-4.734 4.94Z" />
  </svg>
);
export default SvgDrinkFoodIcon;
