import React from "react"
import {selectStyle} from '../service/Select';
import Select from 'react-select';

export const SubscriptionType = {
	BASIC: {
		value: "BASIC",
		label: "Basic",
	},
	STANDARD: {
		value: "STANDARD",
		label: "Standard",
	},
	PREMIUM: {
		value: "PREMIUM",
		label: "Premium",
	},
}

const options = [
	SubscriptionType.BASIC, SubscriptionType.STANDARD, SubscriptionType.PREMIUM
]

const SubscriptionSelect = ({defaultValue, onChange, className, isClearable= true}) => {

	return (
		<Select
			className={className}
			styles={selectStyle}
			placeholder=""
			defaultValue={defaultValue}
			onChange={onChange}
			options={options}
			isClearable={isClearable}
		/>
	)
}

export default SubscriptionSelect