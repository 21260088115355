import axios from "axios";
import { host } from "../config/api";

export const getIngredientSources = () => {
  return axios({
    url: `${host}/v1/ingredients/sources`,
    method: "GET",
  });
};

export const getIngredients = ({ searchText, sources, recipeId }, signal) => {
  return axios({
    url: `${host}/v1/ingredients`,
    method: "GET",
    params: {
      "search-text": searchText,
      sources: sources ? sources.join(",") : [],
      "recipe-id": recipeId,
    },
    signal: signal,
  });
};
