import React, {useEffect} from "react"
import WebFont from 'webfontloader';


const FontProvider = (props) => {

    useEffect(() => {
        WebFont.load({
            google: {
                families: ['Poppins:300,400,500,600,700']
            }
        });
    }, []);

    return (
        props.children
    )
}

export default FontProvider