import * as React from "react";
const SvgXCircleIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="#313131"
    viewBox="0 0 14 14"
    {...props}
  >
    <path d="M9.354 5.354 7.707 7l1.647 1.646a.5.5 0 1 1-.708.708L7 7.707 5.354 9.354a.5.5 0 1 1-.708-.708L6.293 7 4.646 5.354a.5.5 0 1 1 .708-.708L7 6.293l1.646-1.647a.5.5 0 1 1 .708.708ZM13.5 7A6.5 6.5 0 1 1 7 .5 6.507 6.507 0 0 1 13.5 7Zm-1 0A5.5 5.5 0 1 0 7 12.5 5.507 5.507 0 0 0 12.5 7Z" />
  </svg>
);
export default SvgXCircleIcon;
