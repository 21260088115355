import * as React from "react";
const SvgPhoneIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={16}
    height={16}
    fill="none"
    {...props}
  >
    <path
      fill="#313131"
      d="m13.898 9.904-2.944-1.32-.008-.003a1 1 0 0 0-.995.122L8.429 10c-.963-.468-1.958-1.456-2.426-2.407L7.3 6.05a1 1 0 0 0 .118-.99v-.007l-1.323-2.95a1 1 0 0 0-1.038-.594A3.516 3.516 0 0 0 2 5c0 4.963 4.038 9 9 9a3.516 3.516 0 0 0 3.492-3.057 1 1 0 0 0-.594-1.04ZM11 13a8.009 8.009 0 0 1-8-8 2.512 2.512 0 0 1 2.18-2.5v.007l1.312 2.938L5.2 6.991a1 1 0 0 0-.098 1.03c.566 1.158 1.733 2.316 2.904 2.882a1 1 0 0 0 1.03-.107l1.52-1.296 2.937 1.316h.007A2.513 2.513 0 0 1 11 13Z"
    />
  </svg>
);
export default SvgPhoneIcon;
