import * as React from "react";
const SvgDensityIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={8}
    height={12}
    fill="none"
    {...props}
  >
    <path
      fill="#313131"
      d="M.98 11.36V4.15c0-.85.135-1.554.406-2.114.27-.56.644-.98 1.12-1.26.485-.28 1.04-.42 1.666-.42.728 0 1.349.159 1.862.476.523.308.92.756 1.19 1.344.27.588.406 1.302.406 2.142 0 .793-.145 1.48-.434 2.058-.29.57-.69 1.008-1.204 1.316-.504.299-1.083.448-1.736.448-.373 0-.737-.051-1.092-.154a3.063 3.063 0 0 1-.938-.476h-.07c.019.15.033.392.042.728.01.336.014.714.014 1.134v1.988H.98Zm3.262-4.242c.448 0 .83-.107 1.148-.322.317-.215.555-.527.714-.938.168-.42.252-.933.252-1.54 0-.999-.191-1.736-.574-2.212-.373-.476-.92-.714-1.638-.714-.625 0-1.101.233-1.428.7-.327.457-.49 1.148-.49 2.072v2.268c.27.233.579.406.924.518.355.112.719.168 1.092.168Z"
      opacity={0.7}
    />
  </svg>
);
export default SvgDensityIcon;
