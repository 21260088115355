import axios from "axios";
import { get_recipe_by_nutrical_request, host } from "../config/api";

export const RecipeType = {
  NUTRICAL: {
    value: "NUTRICAL",
  },
  RESTAURANT: {
    value: "RESTAURANT",
  },
};

export const getRecipe = (id, recipeType = RecipeType.NUTRICAL) => {
  return axios({
    url:
      recipeType === RecipeType.RESTAURANT
        ? `${host}/v1/recipes/restaurant-request/${id}`
        : `${host}/v1/recipes/nutrical-request/${id}`,
    method: "GET",
  });
};

export const isValid = (recipe) => {
  if (!recipe || !recipe.ingredients) {
    return false;
  }

  let ingredientWithEmptyValue = recipe.ingredients.find((e) => !e.amount);
  return !ingredientWithEmptyValue;
};

export const hasIngredients = (recipe) => {
  if (!recipe || !recipe.ingredients) {
    return false;
  }

  return recipe.ingredients.length > 0;
};

export const getDeclarationText = (recipe) => {
  if (!recipe || !recipe.ingredients) {
    return "";
  }

  let recipeLocal = Object.assign({}, recipe);

  recipeLocal.ingredients = recipe.ingredients.sort(
    (a, b) => -(a.amount - b.amount)
  );

  let ingredientsText =
    "<div style='font-size: 9pt; font-family: Roboto-Regular'>";

  recipeLocal.ingredients.forEach((e, index) => {
    let name = e.ingredient.name
      ? e.ingredient.name.toLowerCase()
      : e.ingredient.name;
    let correctedName = e.corrected_name;
    let text = e.ingredient.ingredients_text;
    let pct = e.percentage;
    let includePct = e.percentage_flag;

    if (isEmpty(correctedName)) {
      ingredientsText += `<span>${name}</span>`;
    } else {
      ingredientsText += `<span>${correctedName}</span>`;
    }

    if (includePct) {
      ingredientsText += ` <b style="color: #EC8E00; font-weight: bold; font-family: Roboto-Medium">(${pct}%)</b>`;
    }

    if (text) {
      ingredientsText += `<span> (${text})</span>`;
    }

    if (recipeLocal.ingredients.length > index + 1) {
      ingredientsText += `<span>, </span>`;
    } else {
      ingredientsText += `<span>.</span>`;
    }
  });

  ingredientsText += "</div>";

  ingredientsText = ingredientsText
    .split("<b>")
    .join("<b style='font-family: Roboto-Medium'>");
  ingredientsText = ingredientsText.replace(
    /<\/?([a-z][a-z0-9]*)\b[^>]*>/gi,
    function (match, tag) {
      return tag === "b" ? match : " ";
    }
  );

  return ingredientsText;
};

function isEmpty(htmlString) {
  if (!htmlString) {
    return true;
  }

  let div = document.createElement("div");
  div.innerHTML = htmlString;
  return div.textContent.trim().length === 0;
}
