import * as React from "react";
const SvgPibIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={16}
    height={16}
    fill="none"
    {...props}
  >
    <path
      fill="#313131"
      d="M14 5.5h-3.037l.529-2.91a.5.5 0 0 0-.984-.18L9.946 5.5H6.963l.529-2.91a.5.5 0 0 0-.984-.18L5.946 5.5H3a.5.5 0 1 0 0 1h2.764l-.545 3H2a.5.5 0 1 0 0 1h3.037l-.529 2.91a.5.5 0 0 0 .983.18l.563-3.09h2.983l-.529 2.91a.5.5 0 0 0 .983.18l.563-3.09H13a.5.5 0 0 0 0-1h-2.764l.545-3H14a.5.5 0 0 0 0-1Zm-4.781 4H6.236l.545-3h2.983l-.545 3Z"
    />
  </svg>
);
export default SvgPibIcon;
