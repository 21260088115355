import * as React from "react";
const SvgStatementsIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={18}
    height={18}
    fill="#989898"
    {...props}
  >
    <path d="M11.813 7.875a.562.562 0 0 1-.563.563h-4.5a.563.563 0 1 1 0-1.125h4.5a.562.562 0 0 1 .563.562Zm-.563 1.688h-4.5a.563.563 0 0 0 0 1.125h4.5a.562.562 0 1 0 0-1.125ZM16.313 9a7.312 7.312 0 0 1-10.75 6.456l-2.394.798a1.125 1.125 0 0 1-1.423-1.423l.798-2.394A7.312 7.312 0 1 1 16.312 9Zm-1.125 0a6.189 6.189 0 1 0-11.545 3.098.563.563 0 0 1 .046.46l-.877 2.63 2.63-.877a.55.55 0 0 1 .46.046A6.188 6.188 0 0 0 15.186 9Z" />
  </svg>
);
export default SvgStatementsIcon;
