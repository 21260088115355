import * as React from "react";
const SvgAddressIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={16}
    height={16}
    fill="none"
    {...props}
  >
    <path
      fill="#313131"
      d="M8 4a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5Zm0 4a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3Zm0-7a5.506 5.506 0 0 0-5.5 5.5c0 1.963.907 4.043 2.625 6.016.772.891 1.64 1.694 2.59 2.393a.5.5 0 0 0 .574 0c.948-.7 1.816-1.502 2.586-2.393C12.591 10.543 13.5 8.463 13.5 6.5A5.506 5.506 0 0 0 8 1Zm0 12.875c-1.033-.813-4.5-3.797-4.5-7.375a4.5 4.5 0 0 1 9 0c0 3.577-3.467 6.563-4.5 7.375Z"
    />
  </svg>
);
export default SvgAddressIcon;
