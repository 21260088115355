import * as React from "react";
const SvgEditIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 20 20"
    {...props}
  >
    <path
      stroke="#fff"
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M9.166 3.333H3.333A1.667 1.667 0 0 0 1.666 5v11.667a1.667 1.667 0 0 0 1.667 1.666h11.666a1.667 1.667 0 0 0 1.667-1.666v-5.834"
    />
    <path
      stroke="#fff"
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M15.416 2.083a1.768 1.768 0 0 1 2.5 2.5L9.999 12.5l-3.333.833L7.499 10l7.917-7.917Z"
    />
  </svg>
);
export default SvgEditIcon;
