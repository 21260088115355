import React, {useCallback, useState} from "react";
import { useDropzone } from "react-dropzone";
import axios from "axios";
import Button from "./Button";
import { UploadIcon, XCircleIcon } from "../icons";
import classNames from "classnames";
import { MEDIA_API } from "../config/api";

const Tag = ({ fileName, isLoading, hasErrors, onRemove, className }) => {
  const rootClassNames = classNames(
    className,
    "mt-2 mr-20 flex items-center h-[32px] rounded-full pl-4 pr-1 text-sm",
    {
      "bg-gray-100": !hasErrors,
      "bg-red-50": hasErrors,
      "border-red-600": hasErrors,
      "text-red-600": hasErrors,
      border: true,
    }
  );

  const xCircleClassNames = classNames("ml-2 cursor-pointer");

  return (
    <div className="nowrap">
      <div className={rootClassNames}>
        <span className="nowrap">{fileName}</span>
        {isLoading && <span> (Loading...)</span>}
        {!hasErrors && (
          <XCircleIcon
            width={15}
            className={xCircleClassNames}
            onClick={onRemove}
          />
        )}
        {hasErrors && (
          <XCircleIcon
            width={15}
            fill="red"
            className={xCircleClassNames}
            onClick={onRemove}
          />
        )}
      </div>
      <div>
        {hasErrors && (
          <span className="text-red-500 ml-2 text-xs">
            {" "}
            Greska: neispravan format
          </span>
        )}
      </div>
    </div>
  );
};

const FileUpload = ({
  onUploadStart,
  onUploadEnd,
  onRemove,
  defaultUploadedFiles = [],
}) => {

    const [uploadedFiles, setUploadedFiles] = useState(defaultUploadedFiles)


    const onDrop = useCallback(async (acceptedFiles) => {
    console.log(acceptedFiles)

        for (let i = 0; i < acceptedFiles.length; i++) {
      const file = acceptedFiles[i];
      const config = { headers: { "Content-Type": "multipart/form-data" } };
      const formData = new FormData();
      formData.append("file", file);

      try {
        onUploadStart(file);
        const response = await axios.post(MEDIA_API, formData, config);

        setUploadedFiles(prevState => [
            ...prevState,
            {
                storage: response ? { ...response.data } : null,
                hasError: false,
                isLoading: false,
            }
        ])

        onUploadEnd(file, response, false);
      } catch (e) {
        onUploadEnd(file, null, true);
      }
    }
  }, []);

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isFocused,
    isDragAccept,
    isDragReject,
  } = useDropzone({ onDrop });

  const handleRemove = (storageId) => {
      setUploadedFiles(uploadedFiles.filter(uploadedFile => uploadedFile?.storage?.id !== storageId))
      onRemove(storageId)
  }

  return (
    <section className="container">
      <div {...getRootProps({ className: "dropzone" })}>
        <input {...getInputProps()} />
        <p className="text-sm">Privuci fajl ili selektuj sa svog računara</p>
        <Button
          className="mt-6"
          color="neutral"
          size="large"
          startIcon={<UploadIcon />}
        >
          Selektuj
        </Button>
      </div>
      <aside>
        <div className="flex flex-wrap">
          {uploadedFiles.map((status, index) => (
            <Tag
              key={`${status?.storage?.id}`}
              fileName={status?.storage?.file_name}
              onRemove={() => handleRemove(status?.storage?.id)}
              hasErrors={status?.hasError}
              isLoading={status?.isLoading}
            />
          ))}
        </div>
      </aside>
    </section>
  );
};

export default FileUpload;
