import * as React from "react";
const SvgDrag = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <g
      stroke="#000"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
    >
      <path d="M14 18a1 1 0 1 0 2 0 1 1 0 0 0-2 0ZM8 18a1 1 0 1 0 2 0 1 1 0 0 0-2 0ZM14 12a1 1 0 1 0 2 0 1 1 0 0 0-2 0ZM8 12a1 1 0 1 0 2 0 1 1 0 0 0-2 0ZM14 6a1 1 0 1 0 2 0 1 1 0 0 0-2 0ZM8 6a1 1 0 1 0 2 0 1 1 0 0 0-2 0Z" />
    </g>
  </svg>
);
export default SvgDrag;
