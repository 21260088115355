import * as React from "react";
const SvgEyeIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={13}
    height={9}
    fill="#fff"
    {...props}
  >
    <path d="M12.963 4.318c-.02-.045-.478-1.102-1.498-2.16C10.105.745 8.389 0 6.5 0 4.61 0 2.894.746 1.535 2.157.515 3.217.055 4.275.037 4.317a.465.465 0 0 0 0 .366c.02.045.478 1.101 1.498 2.16C2.895 8.254 4.611 9 6.5 9c1.89 0 3.606-.746 4.965-2.157 1.02-1.059 1.479-2.115 1.498-2.16a.464.464 0 0 0 0-.365ZM6.5 8.1c-1.667 0-3.124-.63-4.33-1.87A7.491 7.491 0 0 1 .92 4.5a7.484 7.484 0 0 1 1.25-1.73C3.376 1.53 4.833.9 6.5.9c1.667 0 3.124.63 4.33 1.87a7.49 7.49 0 0 1 1.252 1.73c-.39.757-2.092 3.6-5.582 3.6Zm0-6.3c-.514 0-1.017.158-1.444.455a2.68 2.68 0 0 0-.958 1.212 2.797 2.797 0 0 0-.148 1.56c.1.523.348 1.005.712 1.382.363.378.826.635 1.33.74a2.51 2.51 0 0 0 1.503-.155c.475-.204.881-.55 1.167-.994.286-.444.438-.966.438-1.5 0-.716-.275-1.402-.762-1.908A2.555 2.555 0 0 0 6.5 1.8Zm0 4.5c-.343 0-.678-.106-.963-.303a1.787 1.787 0 0 1-.638-.808 1.864 1.864 0 0 1-.099-1.04c.067-.35.232-.67.474-.922.243-.252.552-.423.888-.492.336-.07.685-.034 1.001.102.317.136.588.367.778.663.19.296.292.644.292 1 0 .477-.182.935-.507 1.273A1.701 1.701 0 0 1 6.5 6.3Z" />
  </svg>
);
export default SvgEyeIcon;
