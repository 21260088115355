import axios from "axios";
import {host} from "../config/api";

export const getMealCategories = (clientId) => {
    return axios({
        method: 'get',
        url: `${host}/v1/meal-categories/restaurants/${clientId}`,
    })
}

export const saveMealCategory = ({name, clientId}) => {
    return axios({
        method: 'post',
        url: `${host}/v1/meal-categories`,
        data: {
            category: name,
            color: '#ffffff',
            restaurant: {
                id: clientId
            }
        }
    })
}

export const updateMealCategories = (categories) => {
    return axios({
        method: 'post',
        url: `${host}/v1/meal-categories/list`,
        data: {
            "meal_categories": categories
        }
    })
}


export const deleteMealCategory = (categoryId) => {
    return axios({
        method: 'delete',
        url: `${host}/v1/meal-categories/${categoryId}`,
    })
}

