import React, {useState} from "react";
import classNames from "classnames";
import "../css/numberField.css"
import {DECIMAL_NUMBER_REGEX, NUMBER_REGEX} from "../config/config";


const NumberField  = ({value, onChange, label, id, className, inputClassName, textRight, rounded= false, isDecimal}) => {

    const [isEmpty, setIsEmpty] = useState(!value)

    const handleOnChangeEvent = (e) => {
        if (!e.target.validity.valid) {
            return
        }

        setIsEmpty(!e.target.value)
        onChange(e)
    }

    const handleOnClick = (e) => {
        e.stopPropagation()
    }

    const classes = classNames(className, {
        "number-field": true,
        "empty-number-field": isEmpty,
        "number-field-rounded": rounded
    })

    const inputClasses = classNames(inputClassName)

    const regex= isDecimal ? DECIMAL_NUMBER_REGEX : NUMBER_REGEX

    return (
        <div className={classes}>
            <label htmlFor={id} className="label">{label}</label>
            <input className={inputClasses} id={id} type="text" pattern={regex} value={value} onChange={handleOnChangeEvent} onClick={handleOnClick}/>
            <span className="text-right">{textRight}</span>
        </div>
    )
}

export default NumberField
