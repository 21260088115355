import * as React from "react";
const SvgCheckboxIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={100}
    height={100}
    fill="none"
    stroke="#fff"
    viewBox="0 0 13 11"
    {...props}
  >
    <path strokeLinecap="round" strokeWidth={2} d="m1 6 3 3 8-8" />
  </svg>
);
export default SvgCheckboxIcon;
