import {host} from "../config/api";
import axios from 'axios';

export const downloadCalculation = (recipeId, requestId) => {
	return axios({
		method: 'get',
		url: `${host}/v1/reports/calculation/recipe/${recipeId}`,
		responseType: 'blob'
	}).then(response => {
		const url = window.URL.createObjectURL(new Blob([response.data]));
		const link = document.createElement('a');
		link.href = url;
		link.setAttribute('download', `${requestId}-račun.txt`);
		document.body.appendChild(link);
		link.click();
	})
}