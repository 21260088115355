import * as React from "react";
const SvgAttachmentIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={16}
    height={16}
    fill="#313131"
    {...props}
  >
    <path d="M13.104 7.646a.5.5 0 0 1 0 .708l-5.129 5.125a3.5 3.5 0 0 1-4.95-4.95L9.23 2.232a2.5 2.5 0 1 1 3.538 3.534l-6.205 6.295a1.503 1.503 0 0 1-2.125-2.125L9.644 4.65a.5.5 0 1 1 .712.7l-5.207 5.295a.501.501 0 0 0 .705.71l6.204-6.292a1.5 1.5 0 0 0-2.12-2.125L3.734 9.23a2.5 2.5 0 0 0 3.533 3.539l5.129-5.125a.5.5 0 0 1 .707.002Z" />
  </svg>
);
export default SvgAttachmentIcon;
