import React, { useRef, useState } from "react";
import IngredientTable from "../components/IngredientTable";
import PortionCalc from "../components/PortionCalc";
import NutritionTable from "../components/NutritionTable";
import NutricalRequestCard from "../components/NutricalRequestCard";
import IngredientForm from "../components/IngredientForm";
import Loader from "../components/Loader";
import NextStep from "../components/NextStep";
import { isValid, RecipeType } from "../service/RecipeService";
import RecipeComment from "../components/RecipeComment";
import useIngredient from "../hooks/useIngredient";
import Navigation from "../components/Navigation";
import useNutricalValues from "../hooks/useNutricalValues";
import useRecipe from "../hooks/useRecipe";
import "../css/recipeContainer.css";
import Expandable from "../components/Expandable";
import Button from "../components/Button";
import {
  CalculatorIcon,
  DrinkFoodIcon,
  HamburgerIcon,
  LiquidFoodIcon,
  SearchIcon,
  SolidFoodIcon,
  StatementsIcon,
  TableIcon,
  TrafficLightIcon,
  VitaminsIcon,
} from "../icons";
import VitaminsTable from "../components/VitaminsTable";
import IngredientSearch from "../components/IngredientSearch";
import RecipeDetailsModal from "../components/RecipeDetailsModal";
import DensityModal from "../components/DensityModal";
import DensityIcon from "../icons/DensityIcon";
import NutrientStatement from "../components/NutrientStatement";
import FattyAcid from "../components/FattyAcid";
import TrafficLight from "../components/TrafficLight";
import { useNavigate, useParams } from "react-router-dom";
import RestaurantRequestCard from "../components/RestaurantRequestCard";

export const Unit = {
  G: "G",
  ML: "ML",
};

export const units = [
  {
    label: "na 100g",
    value: Unit.G,
  },
  {
    label: "na 100ml",
    value: Unit.ML,
  },
];

export const FoodForm = {
  FOOD_SOLID: {
    value: "FOOD_SOLID",
    label: "Solid food",
  },
  FOOD_LIQUID: {
    value: "FOOD_LIQUID",
    label: "Liquid food",
  },
  DRINK_LIQUID: {
    value: "DRINK_LIQUID",
    label: "Drink",
  },
};

const SectionLink = ({
  title,
  icon,
  sectionId,
  scrollContainerRef,
  isSelected,
  onSelect,
}) => {
  const scrollToSection = () => {
    const container = scrollContainerRef.current;
    const section = document.getElementById(sectionId);

    if (container && section) {
      const offset = -145;
      const containerTop = container.getBoundingClientRect().top;
      const sectionTop = section.getBoundingClientRect().top;
      const scrollAmount =
        sectionTop - containerTop + container.scrollTop + offset;

      container.scrollTo({
        top: scrollAmount,
        behavior: "smooth",
      });
    }
  };

  return (
    <div>
      <div
        className={`flex items-center mr-[25px] text-xs text-light
                           ${
                             isSelected ? "text-tab-selected font-semibold" : ""
                           }
                             hover:cursor-pointer hover:underline `}
        onClick={() => {
          scrollToSection();
          onSelect();
        }}
      >
        <div>
          {React.cloneElement(icon, {
            className: isSelected ? "fill-tab-selected" : "",
          })}
        </div>

        <div className="ml-[8px]">{title}</div>
      </div>
    </div>
  );
};

const FoodFormSelect = ({ label, icon, selected, onClick }) => {
  return (
    <div
      className={`h-full w-[100px]
                        ml-[-1px]
                        first:ml-0
                        border-x
                        flex flex-col items-center justify-center
                        hover:cursor-pointer
                        ${
                          selected
                            ? "bg-primary-action text-white border-primary-action"
                            : "bg-white border-gray-100"
                        }`}
      onClick={onClick}
    >
      {React.cloneElement(icon, {
        fill: selected ? "white" : "",
      })}

      <div className="whitespace-nowrap mt-2">{label}</div>
    </div>
  );
};

const RecipeContainer = ({ recipeType }) => {
  const navigate = useNavigate();
  const { clientId, requestId } = useParams();
  const rightSectionRef = useRef(null);

  const [
    nutricalValues,
    refreshNutricalValues,
    isNutricalValuesLoading,
    nutricalValuesError,
  ] = useNutricalValues();
  const [recipe, updateRecipe, isLoading, isFetching, refreshRecipe] =
    useRecipe(requestId, refreshNutricalValues, recipeType);
  const [selectedTabIndex, setSelectedTabIndex] = useState(1);

  const [showSearch, setShowSearch] = useState(false);
  const [showRecipeDetails, setShowRecipeDetails] = useState(false);
  const [showDensity, setShowDensity] = useState(false);

  const [
    selectedIngredient,
    isVisibleIngredientForm,
    openIngredientForm,
    closeIngredientForm,
    saveIngredient,
    updateIngredient,
    deleteIngredient,
  ] = useIngredient();

  if (!recipe) {
    return;
  }

  const onFoodFormChange = (value) => {
    let recipeLocal = Object.assign({}, recipe);
    recipeLocal.food_form = value;

    if (value === FoodForm.FOOD_SOLID.value) {
      recipeLocal.default_unit = Unit.G;
      recipeLocal.density = "1.000";
    }

    updateRecipe({
      recipe: recipeLocal,
      push: true,
      fetch: false,
      delay: 2000,
    });

    refreshNutricalValues(recipeLocal);
  };

  const onUnitChange = (unit) => {
    let recipeLocal = Object.assign({}, recipe);
    recipeLocal.default_unit = unit;

    if (unit === Unit.G) {
      recipeLocal.density = "1.000";
    }

    updateRecipe({
      recipe: recipeLocal,
      push: true,
      fetch: false,
      delay: 2000,
    });

    refreshNutricalValues(recipeLocal);
  };

  const onNutrientFlagChange = (flagName) => {
    let recipeLocal = Object.assign({}, recipe);
    recipeLocal.nutrient_flag[flagName] = !recipeLocal.nutrient_flag[flagName];
    updateRecipe({
      recipe: recipeLocal,
      push: true,
      fetch: false,
      delay: 2000,
    });
  };

  const onDensityChange = (density) => {
    let recipeLocal = Object.assign({}, recipe);
    recipeLocal.density = density;
    updateRecipe({
      recipe: recipeLocal,
      push: true,
      fetch: false,
      delay: 2000,
    });

    setShowDensity(false);
    refreshNutricalValues(recipeLocal);
  };

  const handleStatementsChange = (statements) => {
    let recipeLocal = Object.assign({}, recipe);
    recipeLocal.nutrient_statements = statements;

    updateRecipe({
      recipe: recipeLocal,
      push: true,
      fetch: false,
      delay: 0,
    });
  };

  const saveAndAddIngredient = async (ingredient) => {
    const ingredientDB = await saveIngredient(ingredient);
    addIngredient(ingredientDB);
  };

  const updateAndAddIngredient = async (ingredient) => {
    const ingredientDB = await updateIngredient(ingredient);
    addIngredient(ingredientDB);
  };

  const addIngredient = (ingredient) => {
    let recipeLocal = Object.assign({}, recipe);
    recipeLocal.ingredients.push({
      ingredient_type: ingredient.ingredient_type,
      ingredient: {
        id: ingredient.id,
        name: ingredient.name,
      },
    });

    updateRecipe({
      recipe: recipeLocal,
      push: true,
      fetch: true,
      delay: 0,
    });
  };

  const onNextStep = () => {
    updateRecipe({
      recipe: recipe,
      push: true,
      fetch: false,
      delay: 0,
    });

    navigate("report");

    // window.location = `/report?requestId=${recipeId}`;
  };

  const setNutrientFlag = (nutrientFlag) => {
    let recipeLocal = Object.assign({}, recipe);
    recipeLocal.nutrient_flag = nutrientFlag;
    updateRecipe({
      recipe: recipeLocal,
      push: true,
      fetch: false,
      delay: 2000,
    });
  };

  return (
    <div className="h-screen overflow-hidden flex flex-col mt-8">
      <Navigation page="recipes" />
      <div className="flex grow overflow-hidden px-[50px]">
        <div
          id="left-section"
          className="nutrical-request-container h-full overflow-auto scroll-content pb-[250px]"
        >
          {recipeType.value === RecipeType.NUTRICAL.value && (
            <NutricalRequestCard
              recipe={recipe}
              onRecipeDetailsClick={() => setShowRecipeDetails(true)}
            />
          )}
          {recipeType.value === RecipeType.RESTAURANT.value && (
            <RestaurantRequestCard recipe={recipe} />
          )}
          <Expandable className="mt-4" title="Beleške">
            <RecipeComment recipeId={recipe.id} />
          </Expandable>
        </div>
        {showSearch && (
          <IngredientSearch
            recipe={recipe}
            updateRecipe={updateRecipe}
            refreshNutricalValues={refreshNutricalValues}
            refreshRecipe={refreshRecipe}
            onCreate={() => openIngredientForm(null)}
            onEdit={openIngredientForm}
            onDelete={deleteIngredient}
            onClose={() => setShowSearch(false)}
          />
        )}
        {!showSearch && (
          <div
            id="right-section"
            ref={rightSectionRef}
            className="recipes-container overflow-auto scroll-content pb-[250px]"
          >
            <div className="sticky z-10 top-[0px] bg-body shadow-[1px_1px_20px_20px_#efefef]">
              <div className="h-[60px] bg-white rounded-[8px] px-[20px] flex items-center space-between">
                <div className="flex items-center">
                  <Button
                    className="rounded-[8px]"
                    size="large"
                    color="secondary"
                    startIcon={<SearchIcon width={19} fill="white" />}
                    onClick={() => setShowSearch(true)}
                  >
                    Pretraži sastojke
                  </Button>
                  <div
                    className="ml-4 text-sm font-medium hover:underline hover:cursor-pointer"
                    onClick={() => openIngredientForm(null)}
                  >
                    + Kreiraj složeni sastojak
                  </div>
                </div>
                <div className="h-full text-xs ml-4 flex items-center">
                  <div className="h-full flex items-center">
                    <FoodFormSelect
                      label="Čvrsta hrana"
                      icon={<SolidFoodIcon />}
                      selected={FoodForm.FOOD_SOLID.value === recipe.food_form}
                      onClick={() =>
                        onFoodFormChange(FoodForm.FOOD_SOLID.value)
                      }
                    />
                    <FoodFormSelect
                      label="Tečna hrana"
                      icon={<LiquidFoodIcon />}
                      selected={FoodForm.FOOD_LIQUID.value === recipe.food_form}
                      onClick={() =>
                        onFoodFormChange(FoodForm.FOOD_LIQUID.value)
                      }
                    />
                    <FoodFormSelect
                      label="Piće"
                      icon={<DrinkFoodIcon />}
                      selected={
                        FoodForm.DRINK_LIQUID.value === recipe.food_form
                      }
                      onClick={() =>
                        onFoodFormChange(FoodForm.DRINK_LIQUID.value)
                      }
                    />
                  </div>

                  <div
                    className="flex flex-shrink-0 flex-grow-0 flex-nowrap h-[30px] ml-4
                                                text-xs font-medium text-primary w-[134px] bg-body  relative rounded-[8px]"
                  >
                    {units.map((unit) => (
                      <div
                        key={unit.value}
                        className={`grow h-full  text-align-center 
                                                             middle flex items-center justify-center 
                                                             hover:cursor-pointer hover:opacity-90
                                                             ${
                                                               recipe.default_unit ===
                                                               unit.value
                                                                 ? "bg-primary-action text-white rounded-[8px]"
                                                                 : ""
                                                             }`}
                        onClick={() => onUnitChange(unit.value)}
                      >
                        {unit.label}
                      </div>
                    ))}

                    {recipe?.food_form === FoodForm.FOOD_SOLID.value && (
                      <div className="absolute rounded-[8px] h-full w-full bg-gray-200  opacity-50"></div>
                    )}
                  </div>
                  {
                    <div className="flex flex-shrink-0 flex-grow-0 min-w-[70px] h-[30px] ml-4 relative">
                      <div
                        className="h-[30px] bg-input w-full
                                                        rounded-[6px] items-center flex px-2
                                                        hover:bg-gray-100
                                                        hover:cursor-pointer "
                        onClick={() => setShowDensity(true)}
                      >
                        <DensityIcon />
                        <div className="ml-2 text-sm font-medium grow text-center">
                          {recipe?.density || "1.000"}
                        </div>
                      </div>
                      {recipe.default_unit !== Unit.ML && (
                        <div className="absolute rounded-[8px] h-full w-full bg-gray-200  opacity-50"></div>
                      )}
                    </div>
                  }
                </div>
              </div>
              <div className=" h-[48px] bg-white rounded-[8px] flex items-center px-[20px] mt-[8px]">
                <SectionLink
                  title="Sastojci"
                  icon={<HamburgerIcon />}
                  sectionId="ingredients"
                  scrollContainerRef={rightSectionRef}
                  onSelect={() => setSelectedTabIndex(1)}
                  isSelected={selectedTabIndex === 1}
                />
                <SectionLink
                  title="Kalkulator porcija"
                  icon={<CalculatorIcon width={16} />}
                  sectionId="calculator"
                  scrollContainerRef={rightSectionRef}
                  onSelect={() => setSelectedTabIndex(2)}
                  isSelected={selectedTabIndex === 2}
                />
                <SectionLink
                  title="Nutritivna tabela"
                  icon={<TableIcon width={16} />}
                  sectionId="nutrition-table"
                  scrollContainerRef={rightSectionRef}
                  onSelect={() => setSelectedTabIndex(3)}
                  isSelected={selectedTabIndex === 3}
                />
                <SectionLink
                  title="Vitamini i minerali"
                  icon={<VitaminsIcon width={16} />}
                  sectionId="vitamins"
                  scrollContainerRef={rightSectionRef}
                  onSelect={() => setSelectedTabIndex(4)}
                  isSelected={selectedTabIndex === 4}
                />
                <SectionLink
                  title="Izjave"
                  icon={<StatementsIcon width={16} />}
                  sectionId="statements"
                  scrollContainerRef={rightSectionRef}
                  onSelect={() => setSelectedTabIndex(5)}
                  isSelected={selectedTabIndex === 5}
                />
                <SectionLink
                  title="Traffic Light"
                  icon={<TrafficLightIcon width={16} />}
                  sectionId="traffic-light"
                  scrollContainerRef={rightSectionRef}
                  onSelect={() => setSelectedTabIndex(6)}
                  isSelected={selectedTabIndex === 6}
                />
              </div>
            </div>
            <Expandable
              className="mt-[25px] recipe"
              id="ingredients"
              title="Sastojci"
              defaultExpanded={true}
            >
              <IngredientTable
                recipe={recipe}
                updateRecipe={updateRecipe}
                refreshNutricalValues={refreshNutricalValues}
              />
            </Expandable>
            <Expandable
              className="mt-[25px]"
              id="calculator"
              title="Kalkulator porcija"
            >
              <PortionCalc
                recipe={recipe}
                updateRecipe={updateRecipe}
                refreshNutricalValues={refreshNutricalValues}
              />
            </Expandable>
            <Expandable
              className="mt-[25px]"
              id="nutrition-table"
              title="Nutritivna tabela"
              defaultExpanded={true}
            >
              <NutritionTable
                nutricalValues={nutricalValues}
                nutrientFlag={recipe.nutrient_flag}
                setNutrientFlag={setNutrientFlag}
                onNutrientFlagChange={onNutrientFlagChange}
                isLoading={isNutricalValuesLoading}
                unit={recipe.default_unit}
                isValid={() => isValid(recipe)}
                portionAmount={recipe.portion_amount}
              />
            </Expandable>
            <Expandable
              className="mt-[25px]"
              id="vitamins"
              title="Vitamini i minerali"
              defaultExpanded={false}
            >
              <VitaminsTable
                nutrientValues={nutricalValues}
                nutrientFlag={recipe.nutrient_flag}
                setNutrientFlag={setNutrientFlag}
                onNutrientFlagChange={onNutrientFlagChange}
                portionAmount={recipe.portion_amount}
                unit={recipe.default_unit}
                foodForm={recipe.food_form}
              />
            </Expandable>
            <Expandable
              className="mt-[25px]"
              id="omega-3"
              title="Omega 3 masne kiseline"
              defaultExpanded={false}
            >
              <FattyAcid
                nutrientValues={nutricalValues}
                portionAmount={recipe.portion_amount}
                nutrientFlag={recipe.nutrient_flag}
                setNutrientFlag={setNutrientFlag}
                onNutrientFlagChange={onNutrientFlagChange}
              />
            </Expandable>
            <Expandable
              className="mt-[25px]"
              id="statements"
              title="Izjave"
              defaultExpanded={false}
            >
              <NutrientStatement
                statements={nutricalValues?.nutrient_statements || []}
                checkedCodes={recipe?.nutrient_statements || []}
                onChange={handleStatementsChange}
              />
            </Expandable>
            <Expandable
              className="mt-[25px]"
              id="traffic-light"
              title="Traffic Light"
              defaultExpanded={false}
            >
              <TrafficLight
                value={nutricalValues?.traffic_light?.traffic_light_standard}
                unit={recipe.default_unit}
                reportFlag={recipe?.nutrient_flag?.traffic_light_standard}
                onReportFlagChange={() =>
                  onNutrientFlagChange("traffic_light_standard")
                }
              />

              {recipe.portion_amount && (
                <div className="mt-8">
                  <TrafficLight
                    value={nutricalValues?.traffic_light?.traffic_light_portion}
                    portionAmount={recipe.portion_amount}
                    unit={recipe.default_unit}
                    energy={
                      nutricalValues?.traffic_light?.traffic_light_standard
                        ?.energy
                    }
                    nutrientFlag={recipe?.nutrient_flag}
                    onNutrientFlagChange={onNutrientFlagChange}
                    reportFlag={recipe?.nutrient_flag?.traffic_light_portion}
                    onReportFlagChange={() =>
                      onNutrientFlagChange("traffic_light_portion")
                    }
                  />
                </div>
              )}
            </Expandable>
          </div>
        )}
      </div>
      {isVisibleIngredientForm && (
        <IngredientForm
          show={isVisibleIngredientForm}
          defaultValue={selectedIngredient}
          onClose={closeIngredientForm}
          onSave={saveIngredient}
          onSaveAndAdd={saveAndAddIngredient}
          onUpdateAndAdd={updateAndAddIngredient}
          onUpdate={updateIngredient}
        />
      )}
      {showRecipeDetails && (
        <RecipeDetailsModal
          show={true}
          nutricalRequest={recipe?.nutrical_request}
          onClose={() => setShowRecipeDetails(false)}
        />
      )}
      {showDensity && (
        <DensityModal
          show={true}
          onClose={() => setShowDensity(false)}
          onSubmit={onDensityChange}
        />
      )}
      <Loader isLoading={isFetching} />
      <NextStep
        onNext={onNextStep}
        hide={!nutricalValues}
        isValid={() => isValid(recipe)}
        title="Korekcija sastojaka i prikaz deklaracije"
      />
    </div>
  );
};

export default RecipeContainer;
