import * as React from "react";
const SvgGiftIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={16}
    height={16}
    fill="#313131"
    {...props}
  >
    <path d="M13.5 4.5h-2.193c.025-.02.05-.04.074-.063A1.846 1.846 0 0 0 12 3.098 2.039 2.039 0 0 0 9.902 1a1.846 1.846 0 0 0-1.34.62c-.233.27-.423.575-.562.904a3.434 3.434 0 0 0-.563-.905A1.846 1.846 0 0 0 6.098 1 2.039 2.039 0 0 0 4 3.099a1.846 1.846 0 0 0 .62 1.34c.023.02.048.04.072.062H2.5a1 1 0 0 0-1 1v2a1 1 0 0 0 1 1v4a1 1 0 0 0 1 1h9a1 1 0 0 0 1-1v-4a1 1 0 0 0 1-1v-2a1 1 0 0 0-1-1ZM9.312 2.282A.856.856 0 0 1 9.938 2h.03A1.039 1.039 0 0 1 11 3.067a.856.856 0 0 1-.281.625c-.593.525-1.578.71-2.188.775.075-.661.281-1.623.781-2.185Zm-4.005.022A1.04 1.04 0 0 1 6.037 2h.03a.856.856 0 0 1 .625.281c.525.593.71 1.575.775 2.183-.608-.063-1.59-.25-2.183-.775a.856.856 0 0 1-.28-.625 1.037 1.037 0 0 1 .3-.76h.003ZM2.5 5.5h5v2h-5v-2Zm1 3h4v4h-4v-4Zm9 4h-4v-4h4v4Zm1-5h-5v-2h5v2Z" />
  </svg>
);
export default SvgGiftIcon;
