import * as React from "react";
const SvgCodeBlockIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={16}
    height={16}
    fill="none"
    {...props}
  >
    <path
      fill="#313131"
      d="m3.647 6.354-2-2a.5.5 0 0 1 0-.708l2-2a.5.5 0 0 1 .707.708L2.707 4l1.647 1.646a.5.5 0 1 1-.707.708Zm2.5 0a.5.5 0 0 0 .707 0l2-2a.5.5 0 0 0 0-.708l-2-2a.5.5 0 0 0-.707.708L7.794 4 6.147 5.646a.5.5 0 0 0 0 .708ZM12.5 2.5H11a.5.5 0 0 0 0 1h1.5v9h-9v-4a.5.5 0 0 0-1 0v4a1 1 0 0 0 1 1h9a1 1 0 0 0 1-1v-9a1 1 0 0 0-1-1Z"
    />
  </svg>
);
export default SvgCodeBlockIcon;
