import * as React from "react";
const SvgEmailIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={16}
    height={16}
    fill="none"
    {...props}
  >
    <path
      fill="#313131"
      d="M14 3H2a.5.5 0 0 0-.5.5V12a1 1 0 0 0 1 1h11a1 1 0 0 0 1-1V3.5A.5.5 0 0 0 14 3ZM8 8.322 3.286 4h9.428L8 8.322ZM6.17 8 2.5 11.363V4.637L6.17 8Zm.74.678.75.69a.5.5 0 0 0 .676 0l.75-.69L12.71 12H3.286l3.623-3.322ZM9.83 8l3.67-3.364v6.728L9.83 8Z"
    />
  </svg>
);
export default SvgEmailIcon;
