import React, {useEffect, useState} from "react";
import "../css/reportBuilderContainer.css";
import TextAreaField from "../components/TextAreaField";
import TextField from "../components/TextField";
import IngredientDeclarationTable from "../components/IngredientDeclarationTable";
import "../css/textDeclaration.css";
import {DocType} from "../hooks/useRecipeReport";
import Loader from "../components/Loader";
import Navigation from "../components/Navigation";
import NutrientDeclarationTable from "../components/NutrientDeclarationTable";
import Expandable from "../components/Expandable";
import {format} from "date-fns";
import {BackIcon, DownloadIcon, EyeIcon, SaveIcon} from "../icons";
import PackageTitle from "../components/PackageTitle";
import Button from "../components/Button";
import ComparativeStatements from "../components/ComparativeStatements";
import {downloadReport, downloadRestaurantReport} from "../service/ReportService";
import {downloadCalculation} from "../service/CalculationService";
import {useNavigate} from "react-router-dom";
import CheckboxInput from "../components/CheckboxInput";
import {
    Allergens,
    getRestaurantRequest, MealCharacteristic,
    updateRestaurant, updateRestaurantRequest, updateRestaurantRequestName,
} from "../service/RestaurantService";

const DELAY = 2000;

const FlagCheckbox = ({label, checked, onClick}) => {
    return (
        <div
            className="flex mt-6 items-center w-[20%] text-sm hover:cursor-pointer hover:opacity-70"
            onClick={onClick}
        >
            <CheckboxInput checked={checked}/>
            <div className="ml-2">{label}</div>
        </div>
    );
};

const RestaurantRecipeInfoSection = ({requestId}) => {
    const [restaurantRequest, setRestaurantRequest] = useState(null);

    useEffect(() => {
        getRestaurantRequest(requestId)
            .then((response) => setRestaurantRequest(response.data))
            .catch((err) => {
                console.log(err);
                alert("Server error");
            });
    }, [requestId]);

    const handleRecipeNameChange = (e) => {
        const recipeName = e.target.value
        const restaurantRequestLocal = {
            ...restaurantRequest,
            recipe_name: recipeName,
        };

        setRestaurantRequest(restaurantRequestLocal);

        if (recipeName) {
            updateRestaurantRequestName(requestId, recipeName)
        }
    };

    const handleExternalIdChange = (e) => {
        const restaurantRequestLocal = {
            ...restaurantRequest,
            external_id: e.target.value,
        };

        setRestaurantRequest(restaurantRequestLocal);

        updateRestaurantRequest(restaurantRequestLocal);
    };

    return (
        <div className="flex items-center p-4">
            <TextField
                value={restaurantRequest?.recipe_name}
                onChange={handleRecipeNameChange}
                label="Naziv recepta"
                className="grow text-light"
            />
            <TextField
                value={restaurantRequest?.external_id}
                onChange={handleExternalIdChange}
                label="Eksterni ID"
                className="ml-2 text-light"
            />
        </div>
    );
};

const ReportContainer = ({
                             clientId,
                             requestId,
                             report,
                             handleReportChange,
                             hasUnsavedChanges,
                             onComplete,
                         }) => {
    const navigate = useNavigate();
    const [isLoading, setLoading] = useState(false);
    const [isPackageUpdating, setIsPackageUpdating] = useState(false);

    const onBack = () => {
        if (hasUnsavedChanges || isPackageUpdating) {
            return;
        }

        handleReportChange({
            report: {...report},
            push: true,
            fetch: false,
            delay: 0,
        });

        navigate(-1);
    };

    const handleComplete = () => {
        if (hasUnsavedChanges || isPackageUpdating) {
            return;
        }

        onComplete(requestId, "DONE")
            .then(() => navigate(-2))
            .catch((error) => {
                console.log(error);
                alert("Server error");
            });
    };

    const onFieldChange = (fieldName, fieldValue) => {
        let reportLocal = {
            ...report,
            [fieldName]: fieldValue,
        };

        handleReportChange({
            report: reportLocal,
            push: true,
            fetch: false,
            delay: DELAY,
        });
    };

    const handleNewComparativeStatement = () => {
        let reportLocal = {
            ...report,
            comparative_statements: [
                ...report.comparative_statements,
                {
                    id: null,
                    statement: "",
                },
            ],
        };

        handleReportChange({
            report: reportLocal,
            push: true,
            fetch: true,
            delay: 0,
        });
    };

    const handleAllergenFlagToggle = (allergen) => {
        let reportLocal = {
            ...report,
            allergen_flag: {
                ...report.allergen_flag,
                [allergen]: !report.allergen_flag[allergen],
            },
        };

        handleReportChange({
            report: reportLocal,
            push: true,
            fetch: false,
            delay: 0,
        });
    };

    const handleMealCharacteristicsFlagToggle = (characteristic) => {
        let reportLocal = {
            ...report,
            meal_characteristics_flag: {
                ...report.meal_characteristics_flag,
                [characteristic]: !report.meal_characteristics_flag[characteristic],
            },
        };

        handleReportChange({
            report: reportLocal,
            push: true,
            fetch: false,
            delay: 0,
        });
    };


    const handleMealCharacteristicsOtherTextChange = (e) => {
        let reportLocal = {
            ...report,
            meal_characteristics_flag: {
                ...report.meal_characteristics_flag,
                other_text: e.target.value
            },
        };

        handleReportChange({
            report: reportLocal,
            push: true,
            fetch: false,
            delay: 2000,
        });
    };

    const onPdfPreviewBtnClick = (docType) => {
        if (isPackageUpdating || hasUnsavedChanges) {
            return;
        }

        navigate(`preview?recipeId=${report.recipe.id}&docType=${docType}`);
    };

    const onReportDownloadBtnClick = async () => {
        if (isPackageUpdating || hasUnsavedChanges) {
            return;
        }

        setLoading(true);
        downloadReport(report.recipe.id, requestId).finally(() =>
            setLoading(false)
        );
    };

    const onCalculationDownloadBtnClick = async () => {
        if (isPackageUpdating || hasUnsavedChanges) {
            return;
        }

        setLoading(true);
        downloadCalculation(report.recipe.id, requestId).finally(() =>
            setLoading(false)
        );
    };

    if (!report) {
        return <Loader isLoading={true}/>;
    }

    const reportDate = clientId
        ? report?.recipe?.restaurant_request?.created_at
        : report?.recipe?.nutrical_request?.created_at;

    const hasStatements =
        report &&
        report.nutrient_statements &&
        report.nutrient_statements.length > 0;

    return (
        <div className="report-builder-container flex flex-col">
            <Navigation page="recipes"/>
            <div className="flex p-[30px] mb-[50px] items-start">
                <div className="grow">
                    {clientId && (
                        <Expandable title="Recept">
                            <RestaurantRecipeInfoSection requestId={requestId}/>
                        </Expandable>
                    )}
                    {!clientId && (
                        <Expandable className="mt-20" title="Naziv i vrsta proizvoda">
                            <div className="p-4">
                                <TextField
                                    className="mt-20"
                                    label="Naziv proizvoda"
                                    value={report.corrected_name || ""}
                                    onChange={(e) =>
                                        onFieldChange("corrected_name", e.target.value)
                                    }
                                />
                                <TextField
                                    className="mt-20"
                                    label="Vrsta proizvoda"
                                    value={report.corrected_category || ""}
                                    onChange={(e) =>
                                        onFieldChange("corrected_category", e.target.value)
                                    }
                                />
                            </div>
                        </Expandable>
                    )}

                    <Expandable className="mt-[25px]" title="Sastojci">
                        <IngredientDeclarationTable
                            report={report}
                            handleReportChange={handleReportChange}
                        />
                    </Expandable>

                    <Expandable
                        className="mt-[25px]"
                        title="Dodatne informacije o sastojcima:"
                    >
                        <div className="px-4 pt-4 pb-2">
                            <TextAreaField
                                placeholder="Unesite dodatne informacije o sastojcima..."
                                value={report.ingredients_additional}
                                onChange={(e) =>
                                    onFieldChange("ingredients_additional", e.target.value)
                                }
                            ></TextAreaField>
                        </div>
                    </Expandable>

                    {clientId && (
                        <Expandable className="mt-20" title="Alergeni">
                            <div className="p-4 flex flex-wrap">

                                <FlagCheckbox
                                    label={Allergens.EGGS.label}
                                    checked={
                                        report?.allergen_flag?.[Allergens.EGGS.key.toLowerCase()]
                                    }
                                    onClick={() =>
                                        handleAllergenFlagToggle(Allergens.EGGS.key.toLowerCase())
                                    }
                                />


                                <FlagCheckbox
                                    label={Allergens.CELERY.label}
                                    checked={
                                        report?.allergen_flag?.[Allergens.CELERY.key.toLowerCase()]
                                    }
                                    onClick={() =>
                                        handleAllergenFlagToggle(Allergens.CELERY.key.toLowerCase())
                                    }
                                />


                                <FlagCheckbox
                                    label={Allergens.PEANUTS.label}
                                    checked={
                                        report?.allergen_flag?.[Allergens.PEANUTS.key.toLowerCase()]
                                    }
                                    onClick={() =>
                                        handleAllergenFlagToggle(
                                            Allergens.PEANUTS.key.toLowerCase()
                                        )
                                    }
                                />


                                <FlagCheckbox
                                    label={Allergens.LUPIN.label}
                                    checked={
                                        report?.allergen_flag?.[Allergens.LUPIN.key.toLowerCase()]
                                    }
                                    onClick={() =>
                                        handleAllergenFlagToggle(Allergens.LUPIN.key.toLowerCase())
                                    }
                                />


                                <FlagCheckbox
                                    label={Allergens.SESAME_SEEDS.label}
                                    checked={
                                        report?.allergen_flag?.[
                                            Allergens.SESAME_SEEDS.key.toLowerCase()
                                            ]
                                    }
                                    onClick={() =>
                                        handleAllergenFlagToggle(
                                            Allergens.SESAME_SEEDS.key.toLowerCase()
                                        )
                                    }
                                />

                                <FlagCheckbox
                                    label={Allergens.SOYBEANS.label}
                                    checked={
                                        report?.allergen_flag?.[
                                            Allergens.SOYBEANS.key.toLowerCase()
                                            ]
                                    }
                                    onClick={() =>
                                        handleAllergenFlagToggle(
                                            Allergens.SOYBEANS.key.toLowerCase()
                                        )
                                    }
                                />

                                <FlagCheckbox
                                    label={Allergens.GLUTEN.label}
                                    checked={
                                        report?.allergen_flag?.[Allergens.GLUTEN.key.toLowerCase()]
                                    }
                                    onClick={() =>
                                        handleAllergenFlagToggle(Allergens.GLUTEN.key.toLowerCase())
                                    }
                                />

                                <FlagCheckbox
                                    label={Allergens.CRUSTACEANS.label}
                                    checked={
                                        report?.allergen_flag?.[
                                            Allergens.CRUSTACEANS.key.toLowerCase()
                                            ]
                                    }
                                    onClick={() =>
                                        handleAllergenFlagToggle(
                                            Allergens.CRUSTACEANS.key.toLowerCase()
                                        )
                                    }
                                />

                                <FlagCheckbox
                                    label={Allergens.MILK.label}
                                    checked={
                                        report?.allergen_flag?.[Allergens.MILK.key.toLowerCase()]
                                    }
                                    onClick={() =>
                                        handleAllergenFlagToggle(Allergens.MILK.key.toLowerCase())
                                    }
                                />

                                <FlagCheckbox
                                    label={Allergens.FISH.label}
                                    checked={
                                        report?.allergen_flag?.[Allergens.FISH.key.toLowerCase()]
                                    }
                                    onClick={() =>
                                        handleAllergenFlagToggle(Allergens.FISH.key.toLowerCase())
                                    }
                                />

                                <FlagCheckbox
                                    label={Allergens.MOLLUSCS.label}
                                    checked={
                                        report?.allergen_flag?.[
                                            Allergens.MOLLUSCS.key.toLowerCase()
                                            ]
                                    }
                                    onClick={() =>
                                        handleAllergenFlagToggle(
                                            Allergens.MOLLUSCS.key.toLowerCase()
                                        )
                                    }
                                />

                                <FlagCheckbox
                                    label={Allergens.NUTS.label}
                                    checked={
                                        report?.allergen_flag?.[Allergens.NUTS.key.toLowerCase()]
                                    }
                                    onClick={() =>
                                        handleAllergenFlagToggle(Allergens.NUTS.key.toLowerCase())
                                    }
                                />

                                <FlagCheckbox
                                    label={Allergens.MUSTARD.label}
                                    checked={
                                        report?.allergen_flag?.[Allergens.MUSTARD.key.toLowerCase()]
                                    }
                                    onClick={() =>
                                        handleAllergenFlagToggle(
                                            Allergens.MUSTARD.key.toLowerCase()
                                        )
                                    }
                                />

                                <FlagCheckbox
                                    label={Allergens.SULPHURE_DIOXIDE_SULPHITES.label}
                                    checked={
                                        report?.allergen_flag?.[
                                            Allergens.SULPHURE_DIOXIDE_SULPHITES.key.toLowerCase()
                                            ]
                                    }
                                    onClick={() =>
                                        handleAllergenFlagToggle(
                                            Allergens.SULPHURE_DIOXIDE_SULPHITES.key.toLowerCase()
                                        )
                                    }
                                />
                            </div>
                        </Expandable>
                    )}

                    {clientId && (
                        <Expandable className="mt-20" title="Ostale karakteristike">
                            <div className="p-4 flex flex-wrap">


                                <FlagCheckbox
                                    label={MealCharacteristic.VEGETARIAN.label}
                                    checked={
                                        report?.meal_characteristics_flag?.[
                                            MealCharacteristic.VEGETARIAN.key.toLowerCase()
                                            ]
                                    }
                                    onClick={() =>
                                        handleMealCharacteristicsFlagToggle(
                                            MealCharacteristic.VEGETARIAN.key.toLowerCase()
                                        )
                                    }
                                />

                                <FlagCheckbox
                                    label={MealCharacteristic.VEGAN.label}
                                    checked={
                                        report?.meal_characteristics_flag?.[
                                            MealCharacteristic.VEGAN.key.toLowerCase()
                                            ]
                                    }
                                    onClick={() =>
                                        handleMealCharacteristicsFlagToggle(
                                            MealCharacteristic.VEGAN.key.toLowerCase()
                                        )
                                    }
                                />

                                <FlagCheckbox
                                    label={MealCharacteristic.FASTING.label}
                                    checked={
                                        report?.meal_characteristics_flag?.[
                                            MealCharacteristic.FASTING.key.toLowerCase()
                                            ]
                                    }
                                    onClick={() =>
                                        handleMealCharacteristicsFlagToggle(
                                            MealCharacteristic.FASTING.key.toLowerCase()
                                        )
                                    }
                                />

                                <div className="flex items-center grow h-[50px]">
                                    <FlagCheckbox
                                        label={MealCharacteristic.OTHER.label}
                                        checked={
                                            report?.meal_characteristics_flag?.[
                                                MealCharacteristic.OTHER.key.toLowerCase()
                                                ]
                                        }
                                        onClick={() =>
                                            handleMealCharacteristicsFlagToggle(
                                                MealCharacteristic.OTHER.key.toLowerCase()
                                            )
                                        }
                                    />

                                    {
                                        report?.meal_characteristics_flag?.other &&
                                        <div className=" ml-6 grow mt-6">
                                            <TextField className="w-full"
                                                       value={report?.meal_characteristics_flag?.other_text}
                                                       onChange={handleMealCharacteristicsOtherTextChange}/>
                                        </div>
                                    }
                                </div>

                            </div>
                        </Expandable>
                    )}

                    <Expandable className="mt-[25px]" title="Nutritivna tabela">
                        {report && report.recipe && (
                            <NutrientDeclarationTable recipe={report.recipe}/>
                        )}
                    </Expandable>

                    <Expandable className="mt-[25px]" title="Dostupne nutritivne izjave">
                        {hasStatements &&
                            report.nutrient_statements.map((statement) => (
                                <div className="flex items-cent ml-2 text-sm py-4 px-2 border-b border-table">
                                    <div className="">{statement.nutrient_statement}</div>
                                    {statement?.statement_value && (
                                        <div className="font-normal text-light ml-2">
                                            ({statement?.statement_value})
                                        </div>
                                    )}
                                </div>
                            ))}
                        {!hasStatements && (
                            <div className="p-4 w-full text-center text-sm bg-body">
                                Nema preporučenih izjava
                            </div>
                        )}
                    </Expandable>

                    <Expandable className="mt-[25px]" title="Komparativne izjave">
                        <ComparativeStatements
                            statements={report?.comparative_statements}
                            onChange={(statements) =>
                                onFieldChange("comparative_statements", statements)
                            }
                            handleNewComparativeStatement={handleNewComparativeStatement}
                        />
                    </Expandable>

                    <Expandable className="mt-[25px]" title="Deklaracija">
                        <div className="additional-fields">
                            <TextAreaField
                                className="mt-20"
                                label="Uslovi čuvanja"
                                value={report.storage_condition || ""}
                                onChange={(e) =>
                                    onFieldChange("storage_condition", e.target.value)
                                }
                            />
                            <TextAreaField
                                className="mt-20"
                                label="Način upotrebe/pripreme"
                                value={report.method_preparation || ""}
                                onChange={(e) =>
                                    onFieldChange("method_preparation", e.target.value)
                                }
                            />
                            <TextField
                                className="mt-20"
                                label="Neto količina"
                                value={report.neto_amount || ""}
                                onChange={(e) => onFieldChange("neto_amount", e.target.value)}
                            />
                            <TextField
                                className="mt-20"
                                label="Upotrebiti do"
                                value={report.expiry_date || ""}
                                onChange={(e) => onFieldChange("expiry_date", e.target.value)}
                            />
                            <TextField
                                className="mt-20"
                                label="Serija / lot"
                                value={report.series || ""}
                                onChange={(e) => onFieldChange("series", e.target.value)}
                            />
                            <TextField
                                className="mt-20"
                                label="Slobodno polje"
                                value={report.free_text || ""}
                                onChange={(e) => onFieldChange("free_text", e.target.value)}
                            />
                            <TextField
                                className="mt-20"
                                label="Proizvodjač"
                                value={report.producer || ""}
                                onChange={(e) => onFieldChange("producer", e.target.value)}
                            />
                        </div>
                    </Expandable>
                </div>

                <div
                    className="flex flex-col bg-white rounded-[8px] border-[2px] border-white basis-[350px] shrink-0 ml-[30px]">
                    {!clientId && (
                        <PackageTitle
                            title="Paket"
                            nutricalRequestId={requestId}
                            subscription={
                                report?.recipe?.nutrical_request?.subscription_package
                            }
                            onChangeStart={() => {
                                setIsPackageUpdating(true);
                            }}
                            onChangeend={() => {
                                setIsPackageUpdating(false);
                            }}
                        />
                    )}

                    <div className="text-sm p-[24px] text-primary">
                        <div className="flex">
                            <div>
                                <span>Datum:</span>
                                <span className="font-semibold ml-2">
                  {reportDate ? format(new Date(reportDate), "dd.MM.yyyy") : ""}
                </span>
                            </div>
                            <div className="ml-4">
                                <span>Broj izveštaja: </span>
                                <span className="font-semibold ml-2">
                  {!clientId && report?.recipe?.nutrical_request?.id}
                                    {clientId && report?.recipe?.restaurant_request?.id}
                </span>
                            </div>
                        </div>

                        <div className="mt-2">
                            <span>Podnosilac zahteva: </span>
                            <span className="font-semibold ml-2">
                {!clientId && report?.recipe?.nutrical_request?.customer_name}
                                {clientId &&
                                    report?.recipe?.restaurant_request?.restaurant?.contact_name}
              </span>
                        </div>

                        <div className="flex flex-col">
                            <button
                                onClick={() => onPdfPreviewBtnClick(DocType.REGULAR)}
                                className={`h-[32px] rounded-[4px] bg-section-header
                                                mt-[16px] font-semibold
                                                flex items-center justify-center
                                                hover:opacity-80
                                                ${
                                    isPackageUpdating ||
                                    hasUnsavedChanges
                                        ? "hover:cursor-progress"
                                        : ""
                                }`}
                            >
                                <EyeIcon fill="black" width={16}/>
                                <div className="ml-1">Preview izveštaja</div>
                            </button>
                            <button
                                onClick={() => onPdfPreviewBtnClick(DocType.DECLARATION)}
                                className={`h-[32px] rounded-[4px] bg-section-header
                                                mt-[16px] font-semibold
                                                flex items-center justify-center
                                                hover:opacity-80
                                                ${
                                    isPackageUpdating ||
                                    hasUnsavedChanges
                                        ? "hover:cursor-progress"
                                        : ""
                                }`}
                            >
                                <EyeIcon fill="black" width={16}/>
                                <div className="ml-1">Preview etikete</div>
                            </button>
                            <button
                                onClick={() => onPdfPreviewBtnClick(DocType.SIMPLE)}
                                className={`h-[32px] rounded-[4px] bg-section-header
                                                mt-[16px] font-semibold
                                                flex items-center justify-center
                                                hover:opacity-80
                                                ${
                                    isPackageUpdating ||
                                    hasUnsavedChanges
                                        ? "hover:cursor-progress"
                                        : ""
                                }`}
                            >
                                <EyeIcon fill="black" width={16}/>
                                <div className="ml-1"> Preview proizvodjačke specifikacije</div>
                            </button>
                            <button
                                onClick={onCalculationDownloadBtnClick}
                                className={`h-[32px] rounded-[4px] bg-section-header
                                                mt-[16px] font-semibold
                                                flex items-center justify-center
                                                hover:opacity-80
                                                ${
                                    isPackageUpdating ||
                                    hasUnsavedChanges
                                        ? "hover:cursor-progress"
                                        : ""
                                }`}
                            >
                                <DownloadIcon stroke="gray" width={16}/>
                                <div className="ml-1">Download - Analiza računa</div>
                            </button>
                            <button
                                onClick={onReportDownloadBtnClick}
                                className={`h-[32px] rounded-[4px] bg-primary-action
                                               mt-[16px] font-semibold text-white
                                               flex items-center justify-center
                                               hover:opacity-80
                                               ${
                                    isPackageUpdating ||
                                    hasUnsavedChanges
                                        ? "hover:cursor-progress"
                                        : ""
                                }`}
                            >
                                <DownloadIcon stroke="white" width={16}/>
                                <div className="ml-1">Download .zip</div>
                            </button>
                        </div>
                    </div>
                </div>
            </div>

            <div className="flex space-between items-center grow h-[56px] bg-white px-4 fixed bottom-0 left-0 right-0">
                <Button
                    onClick={onBack}
                    className={`border border-black ${
                        isPackageUpdating || hasUnsavedChanges
                            ? "hover:cursor-progress"
                            : ""
                    }`}
                    color="white"
                >
                    <BackIcon/>
                    <span className="ml-1">Nazad</span>
                </Button>
                <Button color="green" onClick={handleComplete}>
                    <SaveIcon/>
                    <span
                        className={`ml-1 ${
                            isPackageUpdating || hasUnsavedChanges
                                ? "hover:cursor-progress"
                                : ""
                        }`}
                    >
            Završi
          </span>
                </Button>
            </div>

            <Loader isLoading={isLoading}/>
        </div>
    );
};

export default ReportContainer;
