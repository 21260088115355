import * as React from "react";
const SvgModalCloseBtn = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={12}
    height={12}
    fill="none"
    {...props}
  >
    <path
      stroke="#fff"
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M9 3 3 9M3 3l6 6"
    />
  </svg>
);
export default SvgModalCloseBtn;
