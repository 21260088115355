import * as React from "react";
const SvgLiquidFoodIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="#989898"
    {...props}
  >
    <g clipPath="url(#liquidFoodIcon_svg__a)">
      <path d="M8.25 4.5v-3a.75.75 0 0 1 1.5 0v3a.75.75 0 1 1-1.5 0Zm3.75.75a.75.75 0 0 0 .75-.75v-3a.75.75 0 1 0-1.5 0v3a.75.75 0 0 0 .75.75Zm3 0a.75.75 0 0 0 .75-.75v-3a.75.75 0 1 0-1.5 0v3a.75.75 0 0 0 .75.75Zm8.7 4.35L21 11.625v5.625a3 3 0 0 1-3 3H6a3 3 0 0 1-3-3v-5.625L.3 9.6a.75.75 0 0 1 .9-1.2L3 9.75v-1.5a1.5 1.5 0 0 1 1.5-1.5h15a1.5 1.5 0 0 1 1.5 1.5v1.5l1.8-1.35a.75.75 0 0 1 .9 1.2Zm-4.2-1.35h-15v9a1.5 1.5 0 0 0 1.5 1.5h12a1.5 1.5 0 0 0 1.5-1.5v-9Z" />
    </g>
    <defs>
      <clipPath id="liquidFoodIcon_svg__a">
        <path d="M0 0h24v24H0z" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgLiquidFoodIcon;
