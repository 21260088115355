import React, {useEffect, useRef, useState} from "react"
import {ArrowIcon} from "../icons";
import classNames from "classnames";


const MealCategorySelect = ({id, clientId, categories, onChange}) => {

    const parentRef = useRef(null)
    const [isInputFocused, setIsInputFocused] = useState(false);
    const [searchText, setSearchText] = useState('')

    useEffect(() => {
        const handleDocumentClick = (e) => {
            // Close the menu only if the click is outside the input and menu
            if (parentRef.current && !parentRef.current.contains(e.target)) {
                setIsInputFocused(false);
            }
        };

        document.addEventListener('click', handleDocumentClick);

        return () => {
            document.removeEventListener('click', handleDocumentClick);
        };
    }, [clientId]);

    const handleOptionSelect = (category) => {
        setIsInputFocused(false)
        onChange(category)
    }

    if (!clientId) {
        return
    }

    return (
        <div className="relative" ref={parentRef}>
            <div className="flex items-center relative">
                <input
                    className="flex w-full bg-input rounded-[6px] px-4 h-[40px] font-light text-sm hover:cursor-pointer border hover:border-gray-800"
                    type="text"
                    value={''}
                    onChange={(e) => {}}
                    onFocus={() => setIsInputFocused(true)} placeholder="Izaberi kategoriju"/>
                <ArrowIcon className=" absolute right-[10px] top-[10px] rotate-180" key={id} width={16} height={16}/>
            </div>

            {
                isInputFocused &&
                <div
                    className="absolute top-[44px] left-0 bg-white rounded-[8px] shadow-2xl z-30 overflow-auto  w-[300px] max-h-[200px] dropdown p-2 border border-gray-200">

                    {
                        categories && categories.map((category, index) => (
                            <div key={category.id} className={classNames({
                                "flex items-center justify-between h-[40px]  px-2 rounded-[6px] bg-gray-50 hover:cursor-pointer hover:bg-gray-200": true,
                            })} onClick={() => handleOptionSelect(category)}>
                                {category.category}
                            </div>
                        ))
                    }

                    {
                        (!categories || categories.length === 0) &&
                        <div className="text-xs text-light font-semibold p-2">Nema kreiranih kategorija</div>
                    }

                </div>
            }

        </div>
    )
}

export default MealCategorySelect