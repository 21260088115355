import * as React from "react";
const SvgContactIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={16}
    height={16}
    fill="none"
    {...props}
  >
    <path
      fill="#313131"
      d="M12.5 7a.5.5 0 0 1-.5.5H9.5a.5.5 0 1 1 0-1H12a.5.5 0 0 1 .5.5ZM12 8.5H9.5a.5.5 0 1 0 0 1H12a.5.5 0 0 0 0-1Zm2.5-5v9a1 1 0 0 1-1 1h-11a1 1 0 0 1-1-1v-9a1 1 0 0 1 1-1h11a1 1 0 0 1 1 1Zm-1 9v-9h-11v9h11Zm-5.016-2.125a.5.5 0 0 1-.969.25C7.351 9.984 6.699 9.5 5.999 9.5c-.699 0-1.35.484-1.515 1.125a.5.5 0 1 1-.969-.25 2.49 2.49 0 0 1 1.074-1.459 2 2 0 1 1 2.82 0c.53.334.913.855 1.075 1.459ZM6 8.5a1 1 0 1 0 0-2 1 1 0 0 0 0 2Z"
    />
  </svg>
);
export default SvgContactIcon;
