import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import Loader from "../components/Loader";
import Navigation from "../components/Navigation";
import Button from "../components/Button";
import { BackIcon, DownloadIcon } from "../icons";
import { Document, Page } from "react-pdf";
import { getMenuPDF } from "../service/RestaurantService";
import { executeDownload } from "../service/HtmlUtils";

const RestaurantMenuPreview = () => {
  const navigate = useNavigate();

  const { clientId } = useParams();
  const [pdf, setPdf] = useState(null);
  const [numPages, setNumPages] = useState(0);

  const [docWidth, setDocWidth] = useState(600);

  useEffect(() => {
    getMenuPDF(clientId).then((response) => {
      setPdf(response);
    });
  }, [clientId]);

  const handlePdfLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  };

  const onPdfDownloadBtnClick = async () => {
    executeDownload(pdf, `${clientId}-jelovnik.pdf`);
  };

  const onBack = () => {
    navigate(-1);
  };

  const handleDocWidthChange = (width) => {
    if (width <= 0) {
      return;
    }

    setDocWidth(width);
  };

  return (
    <Loader isLoading={!pdf}>
      <div className="pdf-preview-container">
        <Navigation page="recipes" />
        {pdf && (
          <div className="pdf-container pt-[30px] pb-[100px]">
            <div className="title">
              <div className="font-medium text-primary text-[26px] mr-4">
                Pregled zbirnog izveštaja
              </div>

              <Button
                color="primary"
                onClick={onPdfDownloadBtnClick}
                startIcon={<DownloadIcon />}
              >
                <span>Preuzimanje zbirnog izveštaja</span>
              </Button>
            </div>
            <div className="content border-2 border-white">
              <Document
                file={pdf}
                pageLayout="oneColumn"
                options={{ workerSrc: "pdf.worker.js" }}
                onLoadSuccess={handlePdfLoadSuccess}
              >
                {Array.from(new Array(numPages), (el, index) => (
                  <Page
                    key={`page_${index + 1}`}
                    pageNumber={index + 1}
                    wrap={false}
                    width={docWidth}
                  />
                ))}
              </Document>
            </div>
          </div>
        )}

        {pdf && (
          <div className="flex items-center fixed bottom-2 z-20 border border-black bg-opacity-1 w-[100px] py-1 justify-center rounded-xl">
            <div
              className="bg-report-header w-8 h-8 rounded-full text-white flex items-center justify-center font-semibold hover:cursor-pointer hover:opacity-80"
              onClick={() => handleDocWidthChange(docWidth + 100)}
            >
              +
            </div>
            <div
              className="bg-gray-100 ml-4 w-8 h-8  rounded-full text-gray-900 flex items-center justify-center font-semibold hover:cursor-pointer hover:opacity-100"
              onClick={() => handleDocWidthChange(docWidth - 100)}
            >
              -
            </div>
          </div>
        )}

        <div className="flex space-between items-center grow h-[56px] bg-white px-4 fixed bottom-0 left-0 right-0">
          <Button
            onClick={onBack}
            className="border border-black"
            color="white"
          >
            <BackIcon />
            <span className="ml-1">Nazad</span>
          </Button>
        </div>
      </div>
    </Loader>
  );
};

export default RestaurantMenuPreview;
