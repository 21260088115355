import React, {useEffect, useRef, useState} from "react"

import "../css/menu.css"
import classNames from "classnames";


const Options = ({children, setOpen, btnRef}) => {

    useEffect(() => {

        const handleClickOutside = (event) => {
            if (!btnRef.current || btnRef.current.contains(event.target)) {
                return
            }

            setOpen(false)
        }

        document.addEventListener("click", handleClickOutside)

        return () => {
            document.removeEventListener("click", handleClickOutside)
        }
    }, [])

    return (
        <div className="menu-items">
            {children}
        </div>
    )
}

const Menu = (props) => {

    const {position, element} = props
    const [open, setOpen] = useState(false)
    const btnRef = useRef(null)

    const toggleMenu = (e) => {
        setOpen(!open)
    }

    const classes = classNames("menu", {
        "right": position === "right",
        "left": position === "left"
    })

    return (
        <div className={classes} >
            <div ref={btnRef}>
                {!element && <span id="action-btn" className="options" onClick={toggleMenu}><img src="/actions.svg" /></span>}
                {element && <span onClick={toggleMenu}>{element}</span>}
            </div>

            {
                open &&
                <Options setOpen={setOpen} btnRef={btnRef}>{props.children}</Options>
            }
        </div>
    )
}

export default Menu