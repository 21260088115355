import React from "react";
import Button from "./Button";

import "../css/nextStep.css";
import { ArrowRightIcon, BackIcon } from "../icons";
import { useNavigate } from "react-router-dom";

const NextStep = ({
  onNext,
  hide,
  isValid = () => true,
  title,
  showBack = true,
  wait = false,
  rightButton,
}) => {
  const navigate = useNavigate();

  if (hide || !isValid()) {
    return;
  }

  return (
    <div className="next-step-wrapper">
      <div className="next-step text-primary text-sm">
        <div className="ml-4">
          <Button
            onClick={() => navigate(-1)}
            className={`border border-black ${
              wait ? "hover:cursor-progress" : ""
            }`}
            color="white"
          >
            <BackIcon />
            <span className="ml-1">Nazad</span>
          </Button>
        </div>
        <div className="grow">
          {title && (
            <>
              <span>Sledeći korak:</span>
              <span className="font-medium">{title}</span>
            </>
          )}
        </div>
        <div className="mr-4">
          {!rightButton && (
            <Button
              className=""
              onClick={onNext}
              size="medium"
              color="primary"
              endIcon={<ArrowRightIcon width={16} height={16} />}
            >
              Dalje
            </Button>
          )}
          {rightButton}
        </div>
      </div>
    </div>
  );
};

export default NextStep;
