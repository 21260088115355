import * as React from "react";
const SvgSolidFoodIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={20}
    height={20}
    fill="#989898"
    {...props}
  >
    <path d="M19.957 3.61a1.498 1.498 0 0 0-.235-.526 1.441 1.441 0 0 0-.412-.391A17.512 17.512 0 0 0 10 0C6.716 0 3.496.931.688 2.693c-.16.1-.3.233-.412.39a1.506 1.506 0 0 0-.26 1.103c.03.192.095.377.192.543L8.78 19.287c.128.218.307.398.52.523a1.389 1.389 0 0 0 1.402 0c.214-.125.394-.305.521-.523L19.793 4.73a1.498 1.498 0 0 0 .164-1.12ZM4.248 8.746c.232-.227.51-.397.814-.499a2.07 2.07 0 0 1 1.827.255c.267.18.492.42.658.703a2.298 2.298 0 0 1 .19 1.9c-.107.312-.28.595-.505.829a2.133 2.133 0 0 1-.799.522l-2.185-3.71Zm7.85 6.197a2.25 2.25 0 0 1-.654-1.322 2.289 2.289 0 0 1 .295-1.453 2.16 2.16 0 0 1 1.113-.935c.463-.17.97-.17 1.432 0l-2.186 3.71Zm2.94-4.992a3.454 3.454 0 0 0-2.526-.168 3.593 3.593 0 0 0-1.998 1.61 3.818 3.818 0 0 0-.468 2.578 3.73 3.73 0 0 0 1.298 2.253l-1.345 2.287-2.812-4.773c.461-.216.873-.531 1.209-.925.335-.393.586-.856.735-1.358a3.833 3.833 0 0 0-.363-3.022 3.65 3.65 0 0 0-1.035-1.129 3.502 3.502 0 0 0-1.391-.588 3.448 3.448 0 0 0-1.501.057c-.491.13-.95.365-1.346.692l-.567-.963a13.213 13.213 0 0 1 7.07-2.06c2.495 0 4.942.712 7.071 2.06l-2.031 3.449Zm2.777-4.715a14.614 14.614 0 0 0-7.816-2.276c-2.758 0-5.462.788-7.816 2.276l-.755-1.279a16.131 16.131 0 0 1 8.57-2.477c3.022 0 5.986.857 8.572 2.477l-.755 1.28Z" />
  </svg>
);
export default SvgSolidFoodIcon;
