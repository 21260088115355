import axios from "axios";
import {host} from "../config/api";


export const getAllLangualFoodGroupCodes = () => {
    return axios({
        method: 'get',
        url: `${host}/v1/langual/eurofir-codes`
    }).then(response => {
        return langualCodesToOptions(response.data)
    })
}

const langualCodesToOptions = (data) => {
    if (!data || !data.langual_codes) {
        return []
    }

    return data.langual_codes.map(e => ({
        label: e.name,
        value: e.code
    }))
}