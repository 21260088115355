import React from "react";
import ReportContainer from "./ReportContainer";
import { useParams } from "react-router-dom";
import useRecipeReport from "../hooks/useRecipeReport";
import { RecipeType } from "../service/RecipeService";
import { updateRestaurantRequestStatus } from "../service/RestaurantService";

const RestaurantReportContainer = () => {
  const { clientId, requestId } = useParams();
  const { report, handleReportChange, hasUnsavedChanges } = useRecipeReport(
    requestId,
    RecipeType.RESTAURANT
  );

  return (
    <ReportContainer
      clientId={clientId}
      requestId={requestId}
      report={report}
      handleReportChange={handleReportChange}
      hasUnsavedChanges={hasUnsavedChanges}
      onComplete={updateRestaurantRequestStatus}
    />
  );
};

export default RestaurantReportContainer;
