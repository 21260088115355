import * as React from "react";
const SvgHamburgerIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={16}
    height={16}
    fill="none"
    {...props}
  >
    <path
      fill="#313131"
      d="M3.004 6.5h9.992a1 1 0 0 0 .982-1.211C13.514 3.094 11 1.5 8 1.5c-3 0-5.514 1.594-5.978 3.789A1 1 0 0 0 3.004 6.5ZM8 2.5c2.489 0 4.638 1.288 4.996 3H3.004L3 5.496C3.362 3.788 5.511 2.5 8 2.5Zm6.329 7.03-2.57.938-2.322-.932a.5.5 0 0 0-.37 0l-2.313.925-2.316-.925a.5.5 0 0 0-.357-.006l-2.75 1a.5.5 0 0 0 .342.938l.827-.3v.332A2.5 2.5 0 0 0 5 14h6a2.5 2.5 0 0 0 2.5-2.5v-.604l1.17-.426a.5.5 0 1 0-.341-.938V9.53ZM12.5 11.5A1.5 1.5 0 0 1 11 13H5a1.5 1.5 0 0 1-1.5-1.5v-.695l.742-.27 2.32.93a.5.5 0 0 0 .372 0l2.312-.926 2.313.925a.5.5 0 0 0 .356.006l.58-.21.005.24ZM1 8a.5.5 0 0 1 .5-.5h13a.5.5 0 0 1 0 1h-13A.5.5 0 0 1 1 8Z"
    />
  </svg>
);
export default SvgHamburgerIcon;
