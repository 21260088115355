import * as React from "react";
const SvgCheckCircleIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={16}
    height={16}
    fill="none"
    {...props}
  >
    <g
      stroke="#4D4D4D"
      strokeLinecap="round"
      strokeLinejoin="round"
      clipPath="url(#checkCircleIcon_svg__a)"
    >
      <path d="M14.667 7.387V8a6.666 6.666 0 1 1-3.953-6.093" />
      <path d="M14.667 2.667 8 9.34l-2-2" />
    </g>
    <defs>
      <clipPath id="checkCircleIcon_svg__a">
        <path fill="#fff" d="M0 0h16v16H0z" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgCheckCircleIcon;
