import React, {useState} from "react"
import Checkbox from "./Checkbox";
import ThCheckbox from "./ThCheckbox";
import classNames from "classnames";
import {FoodForm, Unit} from "../containers/RecipeContainer";
import {NValue} from "./NutritionTable";

export const Tr = ({children, className, selectable, isMinRu, ...props}) => {

    const trClasses = classNames(className, {
        "h-48px border-b border-table  text-sm text-primary": true,
        "hover:cursor-pointer ": selectable,
        "hover:bg-gray-50": !isMinRu,
        "bg-table-tr-error": isMinRu
    })

    return (
        <tr className={trClasses} {...props}>
            {children}
        </tr>
    )
}

const Td = ({children, className}) => {

    const tdClasses = classNames(className, "px-[10px]")

    return (
        <td className={tdClasses}>
            {children}
        </td>
    )
}

export const NCheckbox = ({label, flagValue,  }) => {

    return (
        <div className="flex items-center font-normal">
            <Checkbox checked={flagValue}/>
            <span className="px-4">{label}</span>
        </div>
    )
}

const getNutrientFlagSelected = (selected) => {

    return {
        "biotin_b7": selected,
        "calcium": selected,
        "chloride": selected,
        "chromium": selected,
        "copper": selected,
         "folate_b9": selected,
        "iron": selected,
        "magnesium": selected,
        "pantothenic_acid_b5": selected,
        "phosphorus": selected,
        "potassium": selected,
        "riboflavin_b2": selected,
        "selenium": selected,
        "thiamin_b1": selected,
        "vitamin_a": selected,
        "vitamin_b12": selected,
        "vitamin_b6": selected,
        "vitamin_d": selected,
        "vitamin_e": selected,
        "vitamin_k": selected,
        "zinc": selected,
        "vitamin_c": selected,
        "niacin_b3": selected,
        "iodine": selected,
        "molybdenum": selected,
        "manganese": selected,
        "fluoride": selected
    }
}

const isLowLevelRU = (value, unit, foodForm) => {

    if (value == null || !foodForm || !unit) {
        return false
    }

    if (FoodForm.DRINK_LIQUID.value === foodForm && unit === Unit.ML) {
        return value < 7.5
    }

    return value < 15
}

const Vitamin = ({checked, onClick,
                  value, valueRdi, valuePortion, valuePortionRdi,
                  foodForm, foodFormUnit, unit,
                  selectable, label, showPortion,
                  }) => {

    return (
        <Tr selectable={selectable} onClick={onClick}
            isMinRu={checked && isLowLevelRU(valueRdi, foodFormUnit, foodForm)}>
            <Td><NCheckbox label={label} flagValue={checked}/></Td>
            <Td><NValue display={checked} unit={unit} value={value}/></Td>
            <Td><NValue display={checked} unit="%"  value={valueRdi}/></Td>
            <Td><NValue display={checked && showPortion} unit={unit} value={valuePortion}/></Td>
            <Td><NValue display={checked && showPortion} unit="%"  value={valuePortionRdi}/></Td>
        </Tr>
    )
}

const VitaminsTable = ({nutrientValues, nutrientFlag, onNutrientFlagChange, setNutrientFlag, portionAmount, unit, foodForm}) => {

    const [selectAllChecked, setSelectAllChecked] = useState(false)

    const getVitamin =  (attrName) => nutrientValues?.nutrient_standard?.nutrient_vitamin[attrName]
    const getVitaminRdi =  (attrName) => nutrientValues?.nutrient_standard?.nutrient_vitamin_rdi[attrName]
    const getVitaminPortion =  (attrName) => nutrientValues?.nutrient_portion?.nutrient_vitamin[attrName]
    const getVitaminPortionRdi =  (attrName) => nutrientValues?.nutrient_portion?.nutrient_vitamin_rdi[attrName]


    const getMineral =  (attrName) => nutrientValues?.nutrient_standard?.nutrient_mineral[attrName]
    const getMineralRdi =  (attrName) => nutrientValues?.nutrient_standard?.nutrient_mineral_rdi[attrName]
    const getMineralPortion =  (attrName) => nutrientValues?.nutrient_portion?.nutrient_mineral[attrName]
    const getMineralPortionRdi =  (attrName) => nutrientValues?.nutrient_portion?.nutrient_mineral_rdi[attrName]

    const handleSelectAllClick = (e) => {
        const nutrientFlagChecked = getNutrientFlagSelected(!selectAllChecked)
        setNutrientFlag({
            ...nutrientFlag,
            ...nutrientFlagChecked
        })
        setSelectAllChecked((prevState) => !prevState)
    }

    if (!nutrientFlag) {
        return
    }

    return (
        <div>
            <table className="w-full">
                <thead>
                <tr>
                    <th></th>
                    <th className="w-[100px]"></th>
                    <th className="w-[100px]"></th>
                    <th className="w-[100px]"></th>
                    <th className="w-[100px]"></th>
                </tr>
                </thead>
                <tbody>
                    <Tr className="h-[48px]">
                        <Td>
                            <div className="flex items-center hover:cursor-pointer hover:underline" onClick={handleSelectAllClick}>
                                <Checkbox checked={selectAllChecked} />
                                <span className="px-4">Označi sve</span>
                            </div>
                        </Td>
                        <ThCheckbox description="na" label="100g" checked={true} disabled={true}/>
                        <ThCheckbox description="" label="NRV%" checked={true} disabled={true}/>
                        <ThCheckbox description="Porcija" label={portionAmount ? portionAmount + 'g' : ''}
                                    checked={nutrientFlag?.portion}
                                    onChange={() => onNutrientFlagChange("portion")}/>
                        <ThCheckbox description="" label="NRV%" disabled={true}
                                    checked={nutrientFlag?.portion}/>
                    </Tr>

                    <Tr className="bg-section-header" selectable={true} onClick={() => onNutrientFlagChange('vitamin_a')}
                        isMinRu={nutrientFlag.vitamin_a &&isLowLevelRU(getVitaminRdi("vitamin_a"), unit, foodForm)}>
                        <Td><NCheckbox display={nutrientFlag.vitamin_a} label="Vitamin A" flagValue={nutrientFlag.vitamin_a}/></Td>
                        <Td><NValue display={nutrientFlag.vitamin_a} unit="µg" value={getVitamin("vitamin_a")}/></Td>
                        <Td><NValue display={nutrientFlag.vitamin_a} unit="%"  value={getVitaminRdi("vitamin_a")}/></Td>
                        <Td><NValue display={nutrientFlag.vitamin_a && nutrientFlag.portion} unit="µg" value={getVitaminPortion("vitamin_a")}/></Td>
                        <Td><NValue display={nutrientFlag.vitamin_a && nutrientFlag.portion} unit="%"  value={getVitaminPortionRdi("vitamin_a")}/></Td>
                    </Tr>
                    <Tr selectable={true} onClick={() => onNutrientFlagChange('vitamin_d')}
                        isMinRu={nutrientFlag.vitamin_d && isLowLevelRU(getVitaminRdi("vitamin_d"), unit, foodForm)}>
                        <Td><NCheckbox label="Vitamin D" flagValue={nutrientFlag.vitamin_d}/></Td>
                        <Td><NValue display={nutrientFlag.vitamin_d} unit="µg" value={getVitamin("vitamin_d")}/></Td>
                        <Td><NValue display={nutrientFlag.vitamin_d} unit="%"  value={getVitaminRdi("vitamin_d")}/></Td>
                        <Td><NValue display={nutrientFlag.vitamin_d && nutrientFlag.portion} unit="µg" value={getVitaminPortion("vitamin_d")}/></Td>
                        <Td><NValue display={nutrientFlag.vitamin_d && nutrientFlag.portion} unit="%"  value={getVitaminPortionRdi("vitamin_d")}/></Td>
                    </Tr>
                    <Tr className="bg-section-header" selectable={true} onClick={() => onNutrientFlagChange('vitamin_e')}
                        isMinRu={nutrientFlag.vitamin_e && isLowLevelRU(getVitaminRdi("vitamin_e"), unit, foodForm)}>
                        <Td><NCheckbox label="Vitamin E" flagValue={nutrientFlag.vitamin_e}/></Td>
                        <Td><NValue display={nutrientFlag.vitamin_e} unit="mg" value={getVitamin("vitamin_e")}/></Td>
                        <Td><NValue display={nutrientFlag.vitamin_e} unit="%"  value={getVitaminRdi("vitamin_e")}/></Td>
                        <Td><NValue display={nutrientFlag.vitamin_e && nutrientFlag.portion} unit="mg" value={getVitaminPortion("vitamin_e")}/></Td>
                        <Td><NValue display={nutrientFlag.vitamin_e && nutrientFlag.portion} unit="%"  value={getVitaminPortionRdi("vitamin_e")}/></Td>
                    </Tr>
                    <Tr selectable={true} onClick={() => onNutrientFlagChange('vitamin_k')}
                        isMinRu={nutrientFlag.vitamin_k && isLowLevelRU(getVitaminRdi("vitamin_k"), unit, foodForm)}>
                        <Td><NCheckbox label="Vitamin K" flagValue={nutrientFlag.vitamin_k}/></Td>
                        <Td><NValue display={nutrientFlag.vitamin_k} unit="µg" value={getVitamin("vitamin_k")}/></Td>
                        <Td><NValue display={nutrientFlag.vitamin_k} unit="%"  value={getVitaminRdi("vitamin_k")}/></Td>
                        <Td><NValue display={nutrientFlag.vitamin_k && nutrientFlag.portion} unit="µg" value={getVitaminPortion("vitamin_k")}/></Td>
                        <Td><NValue display={nutrientFlag.vitamin_k && nutrientFlag.portion} unit="%"  value={getVitaminPortionRdi("vitamin_k")}/></Td>
                    </Tr>
                    {/*<Vitamin*/}
                    {/*    label="Vitamin C"*/}
                    {/*    checked={nutrientFlag.vitamin_c}*/}
                    {/*    onClick={() => onNutrientFlagChange('vitamin_c')}*/}
                    {/*    selectable={true}*/}
                    {/*    value={getVitamin("vitamin_c")}*/}
                    {/*    valueRdi={getVitaminRdi("vitamin_c")}*/}
                    {/*    valuePortion={getVitaminPortion("vitamin_c")}*/}
                    {/*    valuePortionRdi={getVitaminPortionRdi("vitamin_c")}*/}
                    {/*    unit="mg"*/}
                    {/*    foodForm={foodForm}*/}
                    {/*    foodFormUnit={unit}*/}
                    {/*    showPortion={nutrientFlag.portion}*/}
                    {/*/>*/}
                    <Tr selectable={true} onClick={() => onNutrientFlagChange('vitamin_c')}
                        isMinRu={nutrientFlag.vitamin_c && isLowLevelRU(getVitaminRdi("vitamin_c"), unit, foodForm)}>
                        <Td><NCheckbox label="Vitamin C" flagValue={nutrientFlag.vitamin_c}/></Td>
                        <Td><NValue display={nutrientFlag.vitamin_c} unit="mg" value={getVitamin("vitamin_c")}/></Td>
                        <Td><NValue display={nutrientFlag.vitamin_c} unit="%"  value={getVitaminRdi("vitamin_c")}/></Td>
                        <Td><NValue display={nutrientFlag.vitamin_c && nutrientFlag.portion} unit="mg" value={getVitaminPortion("vitamin_c")}/></Td>
                        <Td><NValue display={nutrientFlag.vitamin_c && nutrientFlag.portion} unit="%"  value={getVitaminPortionRdi("vitamin_c")}/></Td>
                    </Tr>
                    <Tr className="bg-section-header" selectable={true} onClick={() => onNutrientFlagChange('thiamin_b1')}
                        isMinRu={nutrientFlag.thiamin_b1 && isLowLevelRU(getVitaminRdi("thiamin_b1"), unit, foodForm)}>
                        <Td><NCheckbox label="Tiamin" flagValue={nutrientFlag.thiamin_b1}/></Td>
                        <Td><NValue display={nutrientFlag.thiamin_b1} unit="mg" value={getVitamin("thiamin_b1")}/></Td>
                        <Td><NValue display={nutrientFlag.thiamin_b1} unit="%"  value={getVitaminRdi("thiamin_b1")}/></Td>
                        <Td><NValue display={nutrientFlag.thiamin_b1 && nutrientFlag.portion} unit="mg" value={getVitaminPortion("thiamin_b1")}/></Td>
                        <Td><NValue display={nutrientFlag.thiamin_b1 && nutrientFlag.portion} unit="%"  value={getVitaminPortionRdi("thiamin_b1")}/></Td>
                    </Tr>
                    <Tr selectable={true} onClick={() => onNutrientFlagChange('riboflavin_b2')}
                        isMinRu={nutrientFlag.riboflavin_b2 && isLowLevelRU(getVitaminRdi("riboflavin_b2"), unit, foodForm)}>
                        <Td><NCheckbox label="Riboflavin" flagValue={nutrientFlag.riboflavin_b2}/></Td>
                        <Td><NValue display={nutrientFlag.riboflavin_b2} unit="mg" value={getVitamin("riboflavin_b2")}/></Td>
                        <Td><NValue display={nutrientFlag.riboflavin_b2} unit="%"  value={getVitaminRdi("riboflavin_b2")}/></Td>
                        <Td><NValue display={nutrientFlag.riboflavin_b2 && nutrientFlag.portion} unit="mg" value={getVitaminPortion("riboflavin_b2")}/></Td>
                        <Td><NValue display={nutrientFlag.riboflavin_b2 && nutrientFlag.portion} unit="%"  value={getVitaminPortionRdi("riboflavin_b2")}/></Td>
                    </Tr>
                    <Tr selectable={true} onClick={() => onNutrientFlagChange('niacin_b3')}
                        isMinRu={nutrientFlag.niacin_b3 && isLowLevelRU(getVitaminRdi("niacin_b3"), unit, foodForm)}>
                        <Td><NCheckbox label="Niacin" flagValue={nutrientFlag.niacin_b3}/></Td>
                        <Td><NValue display={nutrientFlag.niacin_b3} unit="mg" value={getVitamin("niacin_b3")}/></Td>
                        <Td><NValue display={nutrientFlag.niacin_b3} unit="%"  value={getVitaminRdi("niacin_b3")}/></Td>
                        <Td><NValue display={nutrientFlag.niacin_b3 && nutrientFlag.portion} unit="mg" value={getVitaminPortion("niacin_b3")}/></Td>
                        <Td><NValue display={nutrientFlag.niacin_b3 && nutrientFlag.portion} unit="%"  value={getVitaminPortionRdi("niacin_b3")}/></Td>
                    </Tr>
                    <Tr className="bg-section-header" selectable={true} onClick={() => onNutrientFlagChange('vitamin_b6')}
                        isMinRu={nutrientFlag.vitamin_b6 && isLowLevelRU(getVitaminRdi("vitamin_b6"), unit, foodForm)}>
                        <Td><NCheckbox label="Vitamin B6" flagValue={nutrientFlag.vitamin_b6}/></Td>
                        <Td><NValue display={nutrientFlag.vitamin_b6} unit="mg" value={getVitamin("vitamin_b6")}/></Td>
                        <Td><NValue display={nutrientFlag.vitamin_b6} unit="%"  value={getVitaminRdi("vitamin_b6")}/></Td>
                        <Td><NValue display={nutrientFlag.vitamin_b6 && nutrientFlag.portion} unit="mg" value={getVitaminPortion("vitamin_b6")}/></Td>
                        <Td><NValue display={nutrientFlag.vitamin_b6 && nutrientFlag.portion} unit="%"  value={getVitaminPortionRdi("vitamin_b6")}/></Td>
                    </Tr>
                    <Tr selectable={true} onClick={() => onNutrientFlagChange('folate_b9')}
                        isMinRu={nutrientFlag.folate_b9 && isLowLevelRU(getVitaminRdi("folate_b9"), unit, foodForm)}>
                        <Td><NCheckbox label="Folna kiselina" flagValue={nutrientFlag.folate_b9}/></Td>
                        <Td><NValue display={nutrientFlag.folate_b9} unit="µg" value={getVitamin("folate_b9")}/></Td>
                        <Td><NValue display={nutrientFlag.folate_b9} unit="%"  value={getVitaminRdi("folate_b9")}/></Td>
                        <Td><NValue display={nutrientFlag.folate_b9 && nutrientFlag.portion} unit="µg" value={getVitaminPortion("folate_b9")}/></Td>
                        <Td><NValue display={nutrientFlag.folate_b9 && nutrientFlag.portion} unit="%"  value={getVitaminPortionRdi("folate_b9")}/></Td>
                    </Tr>
                    <Tr className="bg-section-header" selectable={true} onClick={() => onNutrientFlagChange('vitamin_b12')}
                        isMinRu={nutrientFlag.vitamin_b12 && isLowLevelRU(getVitaminRdi("vitamin_b12"), unit, foodForm)}>
                        <Td><NCheckbox label="Vitamin B12" flagValue={nutrientFlag.vitamin_b12}/></Td>
                        <Td><NValue display={nutrientFlag.vitamin_b12} unit="µg" value={getVitamin("vitamin_b12")}/></Td>
                        <Td><NValue display={nutrientFlag.vitamin_b12} unit="%"  value={getVitaminRdi("vitamin_b12")}/></Td>
                        <Td><NValue display={nutrientFlag.vitamin_b12 && nutrientFlag.portion} unit="µg" value={getVitaminPortion("vitamin_b12")}/></Td>
                        <Td><NValue display={nutrientFlag.vitamin_b12 && nutrientFlag.portion} unit="%"  value={getVitaminPortionRdi("vitamin_b12")}/></Td>
                    </Tr>
                    <Tr selectable={true} onClick={() => onNutrientFlagChange('biotin_b7')}
                        isMinRu={nutrientFlag.biotin_b7 && isLowLevelRU(getVitaminRdi("biotin_b7"), unit, foodForm)}>
                        <Td><NCheckbox label="Biotin" flagValue={nutrientFlag.biotin_b7}/></Td>
                        <Td><NValue display={nutrientFlag.biotin_b7} unit="µg" value={getVitamin("biotin_b7")}/></Td>
                        <Td><NValue display={nutrientFlag.biotin_b7} unit="%"  value={getVitaminRdi("biotin_b7")}/></Td>
                        <Td><NValue display={nutrientFlag.biotin_b7 && nutrientFlag.portion} unit="µg" value={getVitaminPortion("biotin_b7")}/></Td>
                        <Td><NValue display={nutrientFlag.biotin_b7 && nutrientFlag.portion} unit="%"  value={getVitaminPortionRdi("biotin_b7")}/></Td>
                    </Tr>
                    <Tr className="bg-section-header border-b border-b-black" selectable={true} onClick={() => onNutrientFlagChange('pantothenic_acid_b5')}
                        isMinRu={nutrientFlag.pantothenic_acid_b5 && isLowLevelRU(getVitaminRdi("pantothenic_acid_b5"), unit, foodForm)}>
                        <Td><NCheckbox label="Pantotenska kiselina" flagValue={nutrientFlag.pantothenic_acid_b5}/></Td>
                        <Td><NValue display={nutrientFlag.pantothenic_acid_b5} unit="mg" value={getVitamin("pantothenic_acid_b5")}/></Td>
                        <Td><NValue display={nutrientFlag.pantothenic_acid_b5} unit="%"  value={getVitaminRdi("pantothenic_acid_b5")}/></Td>
                        <Td><NValue display={nutrientFlag.pantothenic_acid_b5 && nutrientFlag.portion} unit="mg" value={getVitaminPortion("pantothenic_acid_b5")}/></Td>
                        <Td><NValue display={nutrientFlag.pantothenic_acid_b5 && nutrientFlag.portion} unit="%"  value={getVitaminPortionRdi("pantothenic_acid_b5")}/></Td>
                    </Tr>
                    <Tr selectable={true} onClick={() => onNutrientFlagChange('potassium')}
                        isMinRu={nutrientFlag.potassium && isLowLevelRU(getMineralRdi("potassium"), unit, foodForm)}>
                        <Td><NCheckbox label="Kalijum" flagValue={nutrientFlag.potassium}/></Td>
                        <Td><NValue display={nutrientFlag.potassium} unit="mg" value={getMineral("potassium")}/></Td>
                        <Td><NValue display={nutrientFlag.potassium} unit="%"  value={getMineralRdi("potassium")}/></Td>
                        <Td><NValue display={nutrientFlag.potassium && nutrientFlag.portion} unit="mg" value={getMineralPortion("potassium")}/></Td>
                        <Td><NValue display={nutrientFlag.potassium && nutrientFlag.portion} unit="%"  value={getMineralPortionRdi("potassium")}/></Td>
                    </Tr>
                    <Tr className="bg-section-header" selectable={true} onClick={() => onNutrientFlagChange('chloride')}
                        isMinRu={nutrientFlag.chloride && isLowLevelRU(getMineralRdi("chloride"), unit, foodForm)}>
                        <Td><NCheckbox label="Hloridi" flagValue={nutrientFlag.chloride}/></Td>
                        <Td><NValue display={nutrientFlag.chloride} unit="mg" value={getMineral("chloride")}/></Td>
                        <Td><NValue display={nutrientFlag.chloride} unit="%"  value={getMineralRdi("chloride")}/></Td>
                        <Td><NValue display={nutrientFlag.chloride && nutrientFlag.portion} unit="mg" value={getMineralPortion("chloride")}/></Td>
                        <Td><NValue display={nutrientFlag.chloride && nutrientFlag.portion} unit="%"  value={getMineralPortionRdi("chloride")}/></Td>
                    </Tr>
                    <Tr selectable={true} onClick={() => onNutrientFlagChange('calcium')}
                        isMinRu={nutrientFlag.calcium && isLowLevelRU(getMineralRdi("calcium"), unit, foodForm)}>
                        <Td><NCheckbox label="Kalcijum" flagValue={nutrientFlag.calcium}/></Td>
                        <Td><NValue display={nutrientFlag.calcium} unit="mg" value={getMineral("calcium")}/></Td>
                        <Td><NValue display={nutrientFlag.calcium} unit="%"  value={getMineralRdi("calcium")}/></Td>
                        <Td><NValue display={nutrientFlag.calcium && nutrientFlag.portion} unit="mg" value={getMineralPortion("calcium")}/></Td>
                        <Td><NValue display={nutrientFlag.calcium && nutrientFlag.portion} unit="%"  value={getMineralPortionRdi("calcium")}/></Td>
                    </Tr>
                    <Tr className="bg-section-header" selectable={true} onClick={() => onNutrientFlagChange('phosphorus')}
                        isMinRu={nutrientFlag.phosphorus && isLowLevelRU(getMineralRdi("phosphorus"), unit, foodForm)}>
                        <Td><NCheckbox label="Fosfor" flagValue={nutrientFlag.phosphorus}/></Td>
                        <Td><NValue display={nutrientFlag.phosphorus} unit="mg" value={getMineral("phosphorus")}/></Td>
                        <Td><NValue display={nutrientFlag.phosphorus} unit="%"  value={getMineralRdi("phosphorus")}/></Td>
                        <Td><NValue display={nutrientFlag.phosphorus && nutrientFlag.portion} unit="mg" value={getMineralPortion("phosphorus")}/></Td>
                        <Td><NValue display={nutrientFlag.phosphorus && nutrientFlag.portion} unit="%"  value={getMineralPortionRdi("phosphorus")}/></Td>
                    </Tr>
                    <Tr className="bg-section-header" selectable={true} onClick={() => onNutrientFlagChange('magnesium')}
                        isMinRu={nutrientFlag.magnesium && isLowLevelRU(getMineralRdi("magnesium"), unit, foodForm)}>
                        <Td><NCheckbox label="Magnezijum" flagValue={nutrientFlag.magnesium}/></Td>
                        <Td><NValue display={nutrientFlag.magnesium} unit="mg" value={getMineral("magnesium")}/></Td>
                        <Td><NValue display={nutrientFlag.magnesium} unit="%"  value={getMineralRdi("magnesium")}/></Td>
                        <Td><NValue display={nutrientFlag.magnesium && nutrientFlag.portion} unit="mg" value={getMineralPortion("magnesium")}/></Td>
                        <Td><NValue display={nutrientFlag.magnesium && nutrientFlag.portion} unit="%"  value={getMineralPortionRdi("magnesium")}/></Td>
                    </Tr>
                    <Tr selectable={true} onClick={() => onNutrientFlagChange('iron')}
                        isMinRu={nutrientFlag.iron && isLowLevelRU(getMineralRdi("iron"), unit, foodForm)}>
                        <Td><NCheckbox label="Gvožđe" flagValue={nutrientFlag.iron}/></Td>
                        <Td><NValue display={nutrientFlag.iron} unit="mg" value={getMineral("iron")}/></Td>
                        <Td><NValue display={nutrientFlag.iron} unit="%"  value={getMineralRdi("iron")}/></Td>
                        <Td><NValue display={nutrientFlag.iron && nutrientFlag.portion} unit="mg" value={getMineralPortion("iron")}/></Td>
                        <Td><NValue display={nutrientFlag.iron && nutrientFlag.portion} unit="%"  value={getMineralPortionRdi("iron")}/></Td>
                    </Tr>
                    <Tr className="bg-section-header" selectable={true} onClick={() => onNutrientFlagChange('zinc')}
                        isMinRu={nutrientFlag.zinc && isLowLevelRU(getMineralRdi("zinc"), unit, foodForm)}>
                        <Td><NCheckbox label="Cink" flagValue={nutrientFlag.zinc}/></Td>
                        <Td><NValue display={nutrientFlag.zinc} unit="mg" value={getMineral("zinc")}/></Td>
                        <Td><NValue display={nutrientFlag.zinc} unit="%"  value={getMineralRdi("zinc")}/></Td>
                        <Td><NValue display={nutrientFlag.zinc && nutrientFlag.portion} unit="mg" value={getMineralPortion("zinc")}/></Td>
                        <Td><NValue display={nutrientFlag.zinc && nutrientFlag.portion} unit="%"  value={getMineralPortionRdi("zinc")}/></Td>
                    </Tr>
                    <Tr selectable={true} onClick={() => onNutrientFlagChange('copper')}
                        isMinRu={nutrientFlag.copper && isLowLevelRU(getMineralRdi("copper"), unit, foodForm)}>
                        <Td><NCheckbox label="Bakar" flagValue={nutrientFlag.copper}/></Td>
                        <Td><NValue display={nutrientFlag.copper} unit="mg" value={getMineral("copper")}/></Td>
                        <Td><NValue display={nutrientFlag.copper} unit="%"  value={getMineralRdi("copper")}/></Td>
                        <Td><NValue display={nutrientFlag.copper && nutrientFlag.portion} unit="mg" value={getMineralPortion("copper")}/></Td>
                        <Td><NValue display={nutrientFlag.copper && nutrientFlag.portion} unit="%"  value={getMineralPortionRdi("copper")}/></Td>
                    </Tr>

                    <Tr selectable={true} onClick={() => onNutrientFlagChange('manganese')}
                        isMinRu={nutrientFlag.manganese && isLowLevelRU(getMineralRdi("manganese"), unit, foodForm)}>
                        <Td><NCheckbox label="Mangan" flagValue={nutrientFlag.manganese}/></Td>
                        <Td><NValue display={nutrientFlag.manganese} unit="mg" value={getMineral("manganese")}/></Td>
                        <Td><NValue display={nutrientFlag.manganese} unit="%"  value={getMineralRdi("manganese")}/></Td>
                        <Td><NValue display={nutrientFlag.manganese && nutrientFlag.portion} unit="mg" value={getMineralPortion("manganese")}/></Td>
                        <Td><NValue display={nutrientFlag.manganese && nutrientFlag.portion} unit="%"  value={getMineralPortionRdi("manganese")}/></Td>
                    </Tr>

                    <Tr selectable={true} onClick={() => onNutrientFlagChange('fluoride')}
                        isMinRu={nutrientFlag.fluoride && isLowLevelRU(getMineralRdi("fluoride"), unit, foodForm)}>
                        <Td><NCheckbox label="Fluorid" flagValue={nutrientFlag.fluoride}/></Td>
                        <Td><NValue display={nutrientFlag.fluoride} unit="mg" value={getMineral("fluoride")}/></Td>
                        <Td><NValue display={nutrientFlag.fluoride} unit="%"  value={getMineralRdi("fluoride")}/></Td>
                        <Td><NValue display={nutrientFlag.fluoride && nutrientFlag.portion} unit="mg" value={getMineralPortion("fluoride")}/></Td>
                        <Td><NValue display={nutrientFlag.fluoride && nutrientFlag.portion} unit="%"  value={getMineralPortionRdi("fluoride")}/></Td>
                    </Tr>

                    <Tr selectable={true} onClick={() => onNutrientFlagChange('selenium')}
                        isMinRu={nutrientFlag.selenium && isLowLevelRU(getMineralRdi("selenium"), unit, foodForm)}>
                        <Td><NCheckbox label="Selen" flagValue={nutrientFlag.selenium}/></Td>
                        <Td><NValue display={nutrientFlag.selenium} unit="µg" value={getMineral("selenium")}/></Td>
                        <Td><NValue display={nutrientFlag.selenium} unit="%"  value={getMineralRdi("selenium")}/></Td>
                        <Td><NValue display={nutrientFlag.selenium && nutrientFlag.portion} unit="µg" value={getMineralPortion("selenium")}/></Td>
                        <Td><NValue display={nutrientFlag.selenium && nutrientFlag.portion} unit="%"  value={getMineralPortionRdi("selenium")}/></Td>
                    </Tr>
                    <Tr className="bg-section-header" selectable={true} onClick={() => onNutrientFlagChange('chromium')}
                        isMinRu={nutrientFlag.chromium && isLowLevelRU(getMineralRdi("chromium"), unit, foodForm)}>
                        <Td><NCheckbox label="Hrom" flagValue={nutrientFlag.chromium}/></Td>
                        <Td><NValue display={nutrientFlag.chromium} unit="µg" value={getMineral("chromium")}/></Td>
                        <Td><NValue display={nutrientFlag.chromium} unit="%"  value={getMineralRdi("chromium")}/></Td>
                        <Td><NValue display={nutrientFlag.chromium && nutrientFlag.portion} unit="µg" value={getMineralPortion("chromium")}/></Td>
                        <Td><NValue display={nutrientFlag.chromium && nutrientFlag.portion} unit="%"  value={getMineralPortionRdi("chromium")}/></Td>
                    </Tr>

                    <Tr className="bg-section-header" selectable={true} onClick={() => onNutrientFlagChange('molybdenum')}
                        isMinRu={nutrientFlag.molybdenum && isLowLevelRU(getMineralRdi("molybdenum"), unit, foodForm)}>
                        <Td><NCheckbox label="Molibden" flagValue={nutrientFlag.molybdenum}/></Td>
                        <Td><NValue display={nutrientFlag.molybdenum} unit="µg" value={getMineral("molybdenum")}/></Td>
                        <Td><NValue display={nutrientFlag.molybdenum} unit="%"  value={getMineralRdi("molybdenum")}/></Td>
                        <Td><NValue display={nutrientFlag.molybdenum && nutrientFlag.portion} unit="µg" value={getMineralPortion("molybdenum")}/></Td>
                        <Td><NValue display={nutrientFlag.molybdenum && nutrientFlag.portion} unit="%"  value={getMineralPortionRdi("molybdenum")}/></Td>
                    </Tr>

                    <Tr className="bg-section-header" selectable={true} onClick={() => onNutrientFlagChange('iodine')}
                        isMinRu={nutrientFlag.iodine && isLowLevelRU(getMineralRdi("iodine"), unit, foodForm)}>
                        <Td><NCheckbox label="Jod" flagValue={nutrientFlag.iodine}/></Td>
                        <Td><NValue display={nutrientFlag.iodine} unit="µg" value={getMineral("iodine")}/></Td>
                        <Td><NValue display={nutrientFlag.iodine} unit="%"  value={getMineralRdi("iodine")}/></Td>
                        <Td><NValue display={nutrientFlag.iodine && nutrientFlag.portion} unit="µg" value={getMineralPortion("iodine")}/></Td>
                        <Td><NValue display={nutrientFlag.iodine && nutrientFlag.portion} unit="%"  value={getMineralPortionRdi("iodine")}/></Td>
                    </Tr>

                </tbody>
            </table>

            <div className="flex items-center p-4">
                <div className="w-[6px] h-[10px] bg-error"></div>
                <div className="text-xs text-base ml-2">Vrednosti obelezene crvenim markerom ne ispunjavaju minimalnu kolicinu od <span>{FoodForm.DRINK_LIQUID.value === foodForm && unit === Unit.ML ? '7%' : '15%'}</span> referentnog unosa</div>
            </div>
        </div>
    )
}

export default VitaminsTable