import React, { useState } from "react";
import Modal from "./Modal";
import { UserIcon } from "../icons/customer";
import { BasicField, TextAreaModalField } from "./NutricalRequestFormModal";
import { HamburgerIcon, MethodPreparationIcon, CodeBlockIcon } from "../icons";
import Button from "./Button";
import { saveRestaurantRequest } from "../service/RestaurantService";
import { RestaurantRequestType } from "../containers/ClientRequestsContainer";

const RestaurantRequestForm = ({
  restaurantId,
  restaurantRequestType,
  onClose,
  onSubmit,
}) => {
  const [restaurantRequest, setRestaurantRequest] = useState({
    id: null,
    recipe_name: "",
    external_id: "",
    recipe_ingredients: "",
    recipe_method_preparation: "",
    restaurant: {
      id: restaurantId,
    },
    restaurant_request_type: restaurantRequestType,
  });

  const validateForm = () => {
    let validation = {
      isValid: true,
      recipe_name: {
        errorMsg: "",
      },
    };

    if (!restaurantRequest.recipe_name) {
      validation.isValid = false;
    }

    if (
      restaurantRequest.recipe_name &&
      restaurantRequest.recipe_name.length < 3
    ) {
      validation.recipe_name.errorMsg = "Minimalan broj karaktera: 3";
      validation.isValid = false;
    }

    return validation;
  };

  const validation = validateForm();

  const handleRecipeNameChange = (e) => {
    setRestaurantRequest({ ...restaurantRequest, recipe_name: e.target.value });
  };

  const handleExternalIdChange = (e) => {
    setRestaurantRequest({ ...restaurantRequest, external_id: e.target.value });
  };

  const handleRecipeIngredientsChange = (e) => {
    setRestaurantRequest({
      ...restaurantRequest,
      recipe_ingredients: e.target.value,
    });
  };

  const handleRecipeMethodPreparationChange = (e) => {
    setRestaurantRequest({
      ...restaurantRequest,
      recipe_method_preparation: e.target.value,
    });
  };

  const handleSubmit = () => {
    saveRestaurantRequest(restaurantRequest)
      .then(() => {
        onSubmit();
      })
      .catch((error) => {
        alert("Došlo je do greške");
      });
  };

  return (
    <div>
      <Modal
        contentClassName="basis-[550px] bg-white"
        show={true}
        onClose={onClose}
      >
        <Modal.Header className="text-left w-full ">
          {restaurantRequestType === RestaurantRequestType.RECIPE.value && (
            <div>Kreiraj recept</div>
          )}
          {restaurantRequestType === RestaurantRequestType.SUBRECIPE.value && (
            <div>Kreiraj sub-recept</div>
          )}
        </Modal.Header>

        <Modal.Body className="w-full">
          <BasicField
            icon={<UserIcon />}
            label="Naziv recepta"
            value={restaurantRequest.recipe_name}
            onChange={handleRecipeNameChange}
            mandatory={true}
            errorMsg={validation?.recipe_name?.errorMsg}
          />

          <BasicField
            icon={<CodeBlockIcon />}
            label="Eksterni ID"
            value={restaurantRequest.external_id}
            onChange={handleExternalIdChange}
            mandatory={false}
            errorMsg={validation?.external_id?.errorMsg}
          />

          <TextAreaModalField
            icon={<HamburgerIcon />}
            label="Sastojci"
            value={restaurantRequest.recipe_ingredients}
            onChange={handleRecipeIngredientsChange}
            mandatory={false}
            errorMsg={validation?.recipe_ingredients?.errorMsg}
            rows={3}
          />

          <TextAreaModalField
            icon={<MethodPreparationIcon />}
            label="Način pripreme"
            value={restaurantRequest.recipe_method_preparation}
            onChange={handleRecipeMethodPreparationChange}
            mandatory={false}
            errorMsg={validation?.recipe_method_preparation?.errorMsg}
            rows={3}
          />
        </Modal.Body>
        <Modal.Footer
          className="flex justify-center p-8"
          isValid={validation.isValid}
        >
          <Button className="my-4" size="large" onClick={handleSubmit}>
            {restaurantRequestType === RestaurantRequestType.RECIPE.value && (
              <div>Kreiraj recept</div>
            )}
            {restaurantRequestType ===
              RestaurantRequestType.SUBRECIPE.value && (
              <div>Kreiraj sub-recept</div>
            )}
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default RestaurantRequestForm;
