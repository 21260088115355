import React from "react";
import CheckboxInput from "./CheckboxInput";


const NutrientStatement = ({statements, checkedCodes, onChange}) => {

    const isChecked = (code) => {
        return checkedCodes.find(checkedCode => checkedCode === code)
    }

    const handleCheck = (code) => {
        if (isChecked(code)) {
            onChange(checkedCodes.filter(checkedCode => checkedCode !== code))
            return
        }

        onChange([
            ...checkedCodes,
            code
        ])
    }

    const hasStatements = statements && statements.length > 0

    return (
        <div className="bg-white rounded-[12px]">
            {
                hasStatements && statements.map(statement => (
                    <div key={statement.code} className="flex items-cent font-medium text-sm py-4 px-2 border-b border-table
                                    hover:cursor-pointer
                                    hover:bg-gray-50"
                         onClick={() => handleCheck(statement.code)}>
                        <CheckboxInput checked={isChecked(statement.code)}/>
                        <div className="ml-4">{statement?.nutrient_statement}</div>
                        { statement?.statement_value &&
                            <div className="font-normal text-light ml-2">({statement?.statement_value})</div>
                        }
                    </div>
                ))
            }
            {
                !hasStatements && <div className="p-4 w-full text-center text-sm bg-body">Nema preporučenih izjava</div>
            }
        </div>
    )
}

export default NutrientStatement