import axios from "axios";
import {host} from "../config/api";
import {updateRestaurantRequestCover} from "./RestaurantService";

export const downloadFile = (id, fileName) => {

    axios({
        url: `${host}/v1/media/${id}`,
        method: "get",
        responseType: 'blob'
    }).then((response) => {

        const downloadUrl = window.URL.createObjectURL(new Blob([response.data]));

        const link = document.createElement('a');

        link.href = downloadUrl;

        link.setAttribute('download', `${fileName}`);

        document.body.appendChild(link);

        link.click();

        link.remove();
    })
}

export const uploadFile = (file) => {
    const formData = new FormData();
    formData.append('file', file);

    return axios.post(`${host}/v1/media`, formData, {
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    });
}

export const getFile = (uuid) => {
    return axios({
        url: `${host}/v1/media/${uuid}`,
        method: 'get',
        responseType: "blob"
    })
}