import React, { useState } from "react";
import { deleteRestaurant, getRestaurants } from "../service/RestaurantService";

const useRestaurants = () => {
  const [restaurants, setRestaurants] = useState([]);
  const [totalPages, setTotalPages] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [timeoutExec, setTimeoutExec] = useState(null);

  const refreshRestaurants = (params) => {
    setIsLoading(true);
    setRestaurants([]);

    if (timeoutExec) {
      clearTimeout(timeoutExec);
    }

    const exec = setTimeout(() => {
      getRestaurants(params)
        .then((response) => {
          console.log("Test2");
          console.log(response);
          setRestaurants(response.restaurants);
          setTotalPages(response.total_pages);
          setError(null);
        })
        .catch((err) => {
          setError(err.message);
          setRestaurants([]);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }, 300);

    setTimeoutExec(exec);
  };

  const handleDeleteRestaurant = (restaurantId) => {
    let result = restaurants.filter((e) => e.id !== restaurantId);
    setRestaurants(result);
    deleteRestaurant(restaurantId);
  };

  return {
    restaurants,
    setRestaurants,
    refreshRestaurants,
    isLoading,
    error,
    handleDeleteRestaurant,
    totalPages,
  };
};

export default useRestaurants;
