import React, { useState } from "react";

import "../css/nutricalRequestCard.css";
import AttachmentsInCard from "./AttachmentsInCard";

const RestaurantRequestCard = ({ recipe }) => {
  if (!recipe || !recipe.restaurant_request) {
    return;
  }

  const attachments = recipe.restaurant_request.restaurant.restaurant_files
    .filter((e) => !!e.storage)
    .map((e) => e.storage);

  return (
    <div className="nutrical-request-card text-primary">
      <div
        className="text-primary-color bg-section-header border-package-standard
                   flex space-between items-center h-[42px] pl-[15px] pr-[5px] rounded-t-[8px] border-b-[2px]"
      >
        <div className="text-sm font-medium ">Informacije o receptu</div>
      </div>

      <div className="p-[15px]">
        <div>
          <div className="flex space-between items-center">
            <div className="text-base font-medium">{recipe.name}</div>
          </div>

          <div className="text-sm">
            {recipe?.restaurant_request?.external_id}
          </div>
        </div>

        <div className="mt-[20px] text-sm">
          <div className="text-light mb-1">Sastojci</div>
          <div className="value">
            {recipe?.restaurant_request?.recipe_ingredients}
          </div>
        </div>

        <div className="mt-[20px] text-sm">
          <div className="text-light mb-1">Način pripreme</div>
          <div className="value">
            {recipe?.restaurant_request?.recipe_method_preparation}
          </div>
        </div>

        <div className="mt-[20px]">
          <div className="text-sm font-medium">
            {recipe?.restaurant_request?.restaurant?.name}
          </div>
          <div className="text-sm font-medium">
            {recipe?.restaurant_request?.restaurant?.contact_name}
          </div>
          <div className="text-sm font-medium">
            {recipe?.restaurant_request?.restaurant?.contact_email}
          </div>
          <div className="text-sm">
            {recipe?.restaurant_request?.restaurant?.contact_phone}
          </div>
        </div>

        <AttachmentsInCard attachments={attachments} />
      </div>
    </div>
  );
};

export default RestaurantRequestCard;
