import React, { useEffect, useState } from "react";
import Navigation from "../components/Navigation";
import { Search } from "../components/Search";
import NutricalRequestsTable from "../components/NutricalRequestsTable";
import useNutricalRequests from "../hooks/useNutricalRequests";
import Button from "../components/Button";
import NutricalRequestFormModal from "../components/NutricalRequestFormModal";
import Pagination from "../components/Pagination";
import useUrlParams from "../hooks/useUrlParams";
import { updateUrlQueryParams } from "../service/UrlUtils";

const NutricalRequestsContainer = (props) => {
  const urlParams = useUrlParams();
  let [params, setParams] = useState({
    page: urlParams["page"] | 0,
    size: urlParams["size"] | 10,
    "search-text": urlParams["search-text"],
  });

  let [
    nutricalRequests,
    setNutricalRequests,
    refreshNutricalRequests,
    isLoading,
    error,
    deleteNutricalRequest,
    totalPages,
  ] = useNutricalRequests();

  const [showNutricalRequestForm, setShowNutricalRequestForm] = useState(false);

  useEffect(() => {
    refreshNutricalRequests(params);
  }, []);

  const handleSearchChange = (value) => {
    let localParams = {
      ...params,
      "search-text": value,
      page: 0,
    };
    setParams(localParams);
    refreshNutricalRequests(localParams);
    updateUrlQueryParams(localParams);
  };

  const handlePageChange = (page) => {
    if (!page) {
      page = 0;
    }

    let localParams = {
      ...params,
      page: page,
    };

    setParams(localParams);
    refreshNutricalRequests(localParams);
    updateUrlQueryParams(localParams);
  };

  return (
    <div className="flex flex-col items-center justify-center">
      <Navigation page="requests" />
      <div className="w-full max-w-[1000px]">
        <div className="flex space-between items-center w-full mt-8 mb-4">
          <div className="font-semibold text-primary text-[18px]">
            Lista zahteva
          </div>
          <Button
            color="secondary"
            size="large"
            onClick={() => setShowNutricalRequestForm(true)}
          >
            + Kreiraj novi zahtev
          </Button>
        </div>
        <Search
          placeholder="Pretraži po klijentu, rednom broju, usluzi..."
          value={params["search-text"]}
          onChange={handleSearchChange}
        />
        <NutricalRequestsTable
          data={nutricalRequests}
          isLoading={isLoading}
          onDelete={deleteNutricalRequest}
        />
        <Pagination
          totalPages={totalPages}
          page={params?.page}
          onPageChange={handlePageChange}
        />
      </div>
      {showNutricalRequestForm && (
        <NutricalRequestFormModal
          onClose={() => {
            setShowNutricalRequestForm(false);
            refreshNutricalRequests(params);
          }}
        />
      )}
    </div>
  );
};

export default NutricalRequestsContainer;
