import React, { useState } from "react";
import axios from "axios";
import { nutrical_requests_url } from "../config/api";
import {
  deleteRestaurantRequest,
  getRestaurantRequests,
} from "../service/RestaurantService";

const useRestaurantRequests = (id) => {
  const [restaurantRequests, setRestaurantRequests] = useState([]);
  const [totalPages, setTotalPages] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [timeoutExec, setTimeoutExec] = useState(null);

  const refreshRestaurantRequests = (params) => {
    setIsLoading(true);
    setRestaurantRequests([]);

    if (timeoutExec) {
      clearTimeout(timeoutExec);
    }

    const exec = setTimeout(() => {
      getRestaurantRequests(params)
        .then((response) => {
          setRestaurantRequests(response.data.restaurant_requests);
          setTotalPages(response.data.total_pages);
          setError(null);
        })
        .catch((err) => {
          setError(err.message);
          setRestaurantRequests(null);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }, 300);

    setTimeoutExec(exec);
  };

  const handleDeleteRestaurantRequest = (restaurantRequestId) => {
    let result = restaurantRequests.filter((e) => e.id !== restaurantRequestId);
    setRestaurantRequests(result);

    deleteRestaurantRequest(restaurantRequestId);
  };

  return [
    restaurantRequests,
    setRestaurantRequests,
    refreshRestaurantRequests,
    isLoading,
    error,
    handleDeleteRestaurantRequest,
    totalPages,
  ];
};

export default useRestaurantRequests;
