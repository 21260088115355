import * as React from "react";
const SvgUserIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={16}
    height={16}
    fill="none"
    {...props}
  >
    <path
      fill="#313131"
      d="M14.433 13.25c-.952-1.646-2.419-2.826-4.13-3.385a4.5 4.5 0 1 0-4.604 0c-1.712.559-3.18 1.739-4.131 3.385a.5.5 0 1 0 .866.5C3.61 11.715 5.692 10.5 8 10.5c2.309 0 4.39 1.215 5.567 3.25a.499.499 0 0 0 .924-.116.499.499 0 0 0-.058-.384ZM4.5 6a3.5 3.5 0 1 1 7 0 3.5 3.5 0 0 1-7 0Z"
    />
  </svg>
);
export default SvgUserIcon;
