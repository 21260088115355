import * as React from "react";
const SvgMethodPreparationIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={14}
    height={14}
    fill="none"
    {...props}
  >
    <path
      fill="#313131"
      d="M7 .5A6.5 6.5 0 1 0 13.5 7 6.507 6.507 0 0 0 7 .5Zm0 12A5.5 5.5 0 1 1 12.5 7 5.507 5.507 0 0 1 7 12.5ZM7 3a4 4 0 1 0 0 8 4 4 0 0 0 0-8Zm0 7a3 3 0 1 1 0-6 3 3 0 0 1 0 6Z"
    />
  </svg>
);
export default SvgMethodPreparationIcon;
