import React from "react";
import "../css/loader.css"

const Loader = (props) => {

    const {isLoading} = props

    if (!isLoading) {
        return (props.children)
    }

    return (
        <div>
            {props.children}
            <div className="loader">
                <div className="lds-spinner">
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                </div>
            </div>
        </div>

    )
}
export default Loader