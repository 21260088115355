import {useMutation, useQuery, useQueryClient} from "react-query";
import {
    getRestaurantRequestMeals,
    updateRestaurantRequestCover,
    updateRestaurantRequestMeal
} from "../service/RestaurantService";
import _ from "lodash";
import {useMemo} from "react";

export const useRestaurantMeals = (restaurantId) => {
    return useQuery({
        queryKey: ['restaurant-meals', restaurantId],
        queryFn: () => getRestaurantRequestMeals(restaurantId).then((response) => response.data.restaurant_requests),
    })
}

const debounceUpdate = _.debounce(updateRestaurantRequestMeal, 1000)



export const useRestaurantMealSelectionUpdate = (restaurantId) => {
    return useMutation({
        mutationFn: updateRestaurantRequestMeal,
    })
}

export const useRestaurantMealUpdate = (restaurantId) => {
    const client = useQueryClient()

    return useMutation({
        mutationFn: debounceUpdate,
        onMutate: (meal) => {
            const prevMealsData = client.getQueryData('restaurant-meals', restaurantId);

            client.setQueriesData(['restaurant-meals', restaurantId], (prevData) => {

                if (!prevData) {
                    return []
                }

                return prevData.map(prevMeal => meal.id === prevMeal.id ? meal : prevMeal)
            })

            return () => client.setQueryData(['restaurant-meals', restaurantId], prevMealsData)
        },
        onError: (error, variables, rollback) => {
            rollback();
        },
        /*onSettled: () => {
            client.invalidateQueries(['restaurant-meals', restaurantId]);
        },*/
    })
}