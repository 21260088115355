import * as React from "react";
const SvgCalculatorIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={14}
    height={16}
    fill="#989898"
    {...props}
  >
    <path d="M3.625 7.438h6.75a.562.562 0 0 0 .563-.563V3.5a.563.563 0 0 0-.563-.563h-6.75a.563.563 0 0 0-.563.563v3.375a.563.563 0 0 0 .563.563Zm.563-3.375h5.625v2.25H4.186v-2.25ZM12.063.688H1.937A1.125 1.125 0 0 0 .813 1.813v12.374a1.125 1.125 0 0 0 1.125 1.126h10.126a1.125 1.125 0 0 0 1.124-1.126V1.813A1.125 1.125 0 0 0 12.063.688Zm0 13.5H1.937V1.812h10.126v12.376ZM5.03 9.405a.844.844 0 1 1-1.687 0 .844.844 0 0 1 1.687 0Zm2.813 0a.844.844 0 1 1-1.688 0 .844.844 0 0 1 1.688 0Zm2.812 0a.844.844 0 1 1-1.687 0 .844.844 0 0 1 1.687 0ZM5.031 12.22a.844.844 0 1 1-1.687 0 .844.844 0 0 1 1.687 0Zm2.813 0a.844.844 0 1 1-1.688 0 .844.844 0 0 1 1.688 0Zm2.812 0a.844.844 0 1 1-1.687 0 .844.844 0 0 1 1.687 0Z" />
  </svg>
);
export default SvgCalculatorIcon;
