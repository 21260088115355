import React, {useState} from "react";
import {nutrical_values_url} from "../config/api";
import axios from "axios";
import {hasIngredients, isEmpty, isValid} from "../service/RecipeService";

const UseNutricalValues = () => {

    const [nutricalValues, setNutricalValues] = useState(null)
    const [isLoading, setIsLoading] = useState(false)
    const [error, setError] = useState(null)

    const refreshNutricalValues = (recipe) => {
        if (!isValid(recipe) || !hasIngredients(recipe)) {
            console.log('Not valid')
            setNutricalValues(null)
            return
        }

        setIsLoading(true)
        axios({
            url: nutrical_values_url,
            method: 'POST',
            data: recipe,
        }).then(response => {
            setNutricalValues(response.data)
            setError(null)
        }).catch(error => {
            setNutricalValues(null)
            setError(error.message)
        }).finally(() => {
            setIsLoading(false)
        })
    }

    return [
        nutricalValues,
        refreshNutricalValues,
        isLoading,
        error
    ]
}

export default UseNutricalValues


