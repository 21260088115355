import React, { useEffect, useState } from "react";
import Navigation from "../components/Navigation";
import useRestaurants from "../hooks/useRestaurants";
import { Search } from "../components/Search";
import ClientsTable from "../components/ClientsTable";
import Button from "../components/Button";
import Pagination from "../components/Pagination";
import RestaurantForm from "../components/RestaurantForm";

const ClientsContainer = () => {
  let [params, setParams] = useState({
    page: 0,
    "search-text": "",
    size: 10,
  });

  let {
    restaurants,
    refreshRestaurants,
    isLoading,
    error,
    handleDeleteRestaurant,
    totalPages,
  } = useRestaurants();

  useEffect(() => {
    refreshRestaurants(params);
  }, []);

  const [showForm, setShowForm] = useState(false);

  const handleSearchChange = (value) => {
    let localParams = {
      ...params,
      "search-text": value,
      page: 0,
    };

    setParams(localParams);
    refreshRestaurants(localParams);
  };

  const handlePageChange = (page) => {
    if (!page) {
      page = 0;
    }

    let localParams = {
      ...params,
      page: page,
    };

    setParams(localParams);
    refreshRestaurants(localParams);
  };

  return (
    <div className="flex flex-col items-center justify-center">
      <Navigation page="restaurants" />
      <div className="w-full max-w-[1000px]">
        <div className="flex space-between items-center w-full mt-8 mb-4">
          <div className="font-semibold text-primary text-[18px]">
            Lista klijenata
          </div>
          <Button
            color="secondary"
            size="large"
            onClick={() => setShowForm(true)}
          >
            + Kreiraj novog klijenta
          </Button>
        </div>
        <Search
          placeholder="Pretraži po nazivu, rednom broju..."
          value={params["search-text"]}
          onChange={handleSearchChange}
        />
        <ClientsTable
          data={restaurants}
          isLoading={isLoading}
          onDelete={handleDeleteRestaurant}
        />
        <Pagination
          totalPages={totalPages}
          page={params?.page}
          onPageChange={handlePageChange}
        />
      </div>
      {showForm && (
        <RestaurantForm
          onClose={() => setShowForm(false)}
          onSubmit={() => {
            setShowForm(false);
            refreshRestaurants(params);
          }}
        />
      )}
    </div>
  );
};

export default ClientsContainer;
