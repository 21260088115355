import React from "react";
import { downloadFile } from "../service/MediaService";
import { DownloadIcon } from "../icons";

const AttachmentsInCard = ({ attachments }) => {
  return (
    <div>
      {attachments && attachments.length > 0 && (
        <div className="mt-[20px] text-sm">
          <div className="text-light mb-1">Prilozi</div>
          {attachments &&
            attachments.map((attachment) => (
              <div
                key={attachment.id}
                className="flex items-center space-between mb-[4px]"
              >
                <div className="whitespace-nowrap overflow-hidden text-ellipsis">
                  {attachment.file_name}
                </div>
                <div>
                  <div
                    className="w-[40px] h-[20px] rounded-[32px] bg-secondary-action
                                                        flex items-center justify-center
                                                        hover:cursor-pointer hover:opacity-90"
                    onClick={() =>
                      downloadFile(attachment.id, attachment.file_name)
                    }
                  >
                    <DownloadIcon />
                  </div>
                </div>
              </div>
            ))}
        </div>
      )}
    </div>
  );
};

export default AttachmentsInCard;
