import React from "react"
import PropTypes from "prop-types"
import classNames from "classnames"

import "../css/button.css"


const Button = (props) => {

    let {color, size, startIcon, endIcon, selected, className, disabled = false} = props

    let btnClassNames = classNames(className, {
        'btn': true,
        [`btn-${size}`]: true,
        [`btn-${color}`]: true,
        'btn-selected': selected,
        'hover:cursor-pointer': true,
        '!bg-gray-400': disabled
    })

    return (
        <button className={btnClassNames} onClick={props.onClick} disabled={disabled}>
            {startIcon && <div className="btn-start-icon">{startIcon}</div>}
            {props.children}
            {endIcon && <div className="btn-end-icon">{endIcon}</div>}
        </button>
    )
}

Button.propTypes = {
    color: PropTypes.oneOf(['primary', 'secondary', 'neutral', 'submit', 'white', 'red', 'green']),
    size: PropTypes.oneOf(['small', 'medium', 'large']),
    startIcon: PropTypes.element,
    endIcon: PropTypes.element,
    selected: PropTypes.bool
}

Button.defaultProps = {
    color: "primary",
    size: "medium",
    startIcon: null,
    endIcon: null,
    selected: false
}

export default Button;