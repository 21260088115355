import * as React from "react";
const SvgCityIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={16}
    height={16}
    fill="none"
    {...props}
  >
    <path
      fill="#313131"
      d="M14.307 3.106a.5.5 0 0 0-.428-.091l-3.82.955-3.835-1.918a.5.5 0 0 0-.345-.037l-4 1A.5.5 0 0 0 1.5 3.5v9a.5.5 0 0 0 .621.485l3.82-.955 3.835 1.917a.508.508 0 0 0 .345.038l4-1a.5.5 0 0 0 .379-.485v-9a.5.5 0 0 0-.193-.394ZM6.5 3.309l3 1.5v7.882l-3-1.5V3.31Zm-4 .582 3-.75v7.968l-3 .75V3.891Zm11 8.218-3 .75V4.891l3-.75v7.968Z"
    />
  </svg>
);
export default SvgCityIcon;
