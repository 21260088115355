import React, {useEffect, useState} from "react";
import "../css/ingredientForm.css"
import TextField from "./TextField";
import ContentEditable from "./ContentEditable";
import Modal from "./Modal";
import {TableIcon, VitaminsIcon} from "../icons";
import Button from "./Button";
import {DECIMAL_NUMBER_REGEX} from "../config/config";
import Select from "react-select";
import {selectStyle} from "../service/Select";
import {getAllLangualFoodGroupCodes} from "../service/LanguaLService";
import classNames from "classnames";


const TabName = {
    "MAIN": "MAIN",
    "VITAMINS": "VITAMINS"
}

const TabItem = ({icon, label, onClick, isSelected}) => {
    return (
        <div className={`flex grow justify-center items-center h-[38px] rounded-t-[8px]
                         hover:cursor-pointer hover:underline
                         text-light
                         ${isSelected ? 'bg-secondary-action text-white ' : ''}`}
             onClick={onClick}>

            <div>{icon}</div>
            <div className="ml-2">{label}</div>
        </div>
    )
}

const Nutrition = ({label, children, isSubcategory, isDashed, className}) => {

    const rootClassNames = classNames(className, `flex items-center py-2
                        bg-white
                        border-b border-table
                        ${isDashed ? 'border-dashed' : ''}`)

    return (
        <div className={rootClassNames}>
            <div className={`grow text-sm text-primary 
            
            ${isSubcategory ? 'ml-8' : 'ml-4'}`}>{label}</div>
            <div>
                {children}
            </div>
        </div>
    )
}

const NutritionValue = ({value, onChange, unit}) => {

    return (
        <div className="flex items-center">
            <TextField isDecimal={true}
                       className="text-sm text-primary w-[100px]"
                       value={value}
                       onChange={(e) => onChange(e.target.value)}
                       pattern={DECIMAL_NUMBER_REGEX}/>
            <div className="text-xs text-light w-[40px] ml-4">{unit}</div>
        </div>
    )
}

const IngredientForm = ({onClose, onSave, onSaveAndAdd, onUpdateAndAdd, onUpdate, defaultValue}) => {

    const [ingredient, setIngredient] = useState(defaultValue)

    const [tab, setTab] = useState(TabName.MAIN)

    const [langualCodes, setLangualCodes] = useState([])


    useEffect(() => {
        getAllLangualFoodGroupCodes()
            .then(langualCodes => setLangualCodes(langualCodes))
    }, [])

    const onNameChange = (e) => {
        setIngredient({
            ...ingredient,
            name: e.target.value
        })
    }

    const onIngredientTextChange = (html) => {
        setIngredient({
            ...ingredient,
            ingredients_text: html
        })
    }

    const onNutrientBasicChange = (name, value) => {
        setIngredient({
            ...ingredient,
            nutrient_basic: {
                ...ingredient.nutrient_basic,
                [name]: value
            }
        })
    }

    const onVitaminChange = (name, value) => {
        setIngredient((preState) => ({
            ...preState,
            nutrient_vitamin: {
                ...preState.nutrient_vitamin,
                [name]: value
            }
        }))
    }

    const onMineralChange = (name, value) => {
        setIngredient((preState) => ({
            ...preState,
            nutrient_mineral: {
                ...preState.nutrient_mineral,
                [name]: value
            }
        }))
    }

    const onNutrientExtendedChange = (name, value) => {
        setIngredient({
            ...ingredient,
            nutrient_extended: {
                ...ingredient.nutrient_extended,
                [name]: value
            }
        })
    }

    const onFattyAcidChange = (name, value) => {
        setIngredient((preState) => ({
            ...preState,
            nutrient_fatty_acid: {
                ...preState.nutrient_fatty_acid,
                [name]: value
            }
        }))
    }

    const onLangualCodeChange = (option) => {
        setIngredient({
            ...ingredient,
            langual_code: option ? option.value : null
        })
    }

    const validate = () => {

        if (!ingredient ||
            !ingredient.name) {
            return false
        }

        return true
    }

    const isValid = validate()

    const formatOptionLabel = ({label, value}) => {
        return (
            <div className="flex space-between items-center">
                <div>{label}</div>
                <div>{value}</div>
            </div>
        )
    }

    return (

        <Modal show={true} onClose={onClose} contentClassName="basis-[550px] bg-white-shade">
            <Modal.Header>Kreiraj sastojak</Modal.Header>

            <Modal.Body>
                <div className="text-sm mb-12">
                    <TextField id="name" value={ingredient.name}
                               label="Naziv sastojka"
                               placeholder="Unesite naziv sastojka"
                               onChange={onNameChange}/>

                    <ContentEditable rootClasses="mt-4"
                                     textAreaClasses="min-h-[90px]"
                                     value={ingredient.ingredients_text}
                                     onChange={onIngredientTextChange}
                                     label="Sastojci"/>

                    <div className="mt-4 mb-1">LanguaL code</div>
                    <Select
                        styles={selectStyle}
                        placeholder="Unesite kod namirnice"
                        defaultValue={ingredient?.langual_code}
                        onChange={onLangualCodeChange}
                        options={langualCodes}
                        formatOptionLabel={formatOptionLabel}
                        isClearable={true}
                    />

                    <div className="mt-4 overflow-hidden">
                        <div className="rounded-t-[8px] border-2 border-white flex bg-section-header">
                            <TabItem label="Nutritivna tabela"
                                     isSelected={tab === TabName.MAIN}
                                     icon={<TableIcon className={tab === TabName.MAIN ? "fill-white" : ""}/>}
                                     onClick={() => setTab(TabName.MAIN)}/>
                            <TabItem label="Vitamini i minerali"
                                     isSelected={tab === TabName.VITAMINS}
                                     icon={<VitaminsIcon className={tab === TabName.VITAMINS ? "fill-white" : ""}/>}
                                     onClick={() => setTab(TabName.VITAMINS)}/>
                        </div>
                        {
                            tab === TabName.MAIN && (
                                <div className="rounded-[8px] overflow-hidden">
                                    <div>
                                        <Nutrition label="Energija">
                                            <NutritionValue unit="kcal"
                                                            value={ingredient?.nutrient_basic?.energy_kcal}
                                                            onChange={(value) => onNutrientBasicChange("energy_kcal", value)}/>
                                            <div className="mt-2">
                                                <NutritionValue unit="KJ"
                                                                value={ingredient?.nutrient_basic?.energy_kj}
                                                                onChange={(value) => onNutrientBasicChange("energy_kj", value)}/>
                                            </div>
                                        </Nutrition>
                                        <Nutrition label="Masti" isDashed={true}>
                                            <NutritionValue unit="g"
                                                            value={ingredient?.nutrient_basic?.fat}
                                                            onChange={(value) => onNutrientBasicChange("fat", value)}
                                            />
                                        </Nutrition>
                                        <Nutrition label="Od kojih zasićene masne kiseline">
                                            <NutritionValue unit="g"
                                                            value={ingredient?.nutrient_basic?.fat_saturated}
                                                            onChange={(value) => onNutrientBasicChange("fat_saturated", value)}/>
                                        </Nutrition>
                                        <Nutrition label="Od kojih mononezasićene masne kiseline">
                                            <NutritionValue unit="g"
                                                            value={ingredient?.nutrient_extended?.mono_unsaturated}
                                                            onChange={(value) => onNutrientExtendedChange("mono_unsaturated", value)}/>
                                        </Nutrition>
                                        <Nutrition label="Od kojih polinezasićene masne kiseline">
                                            <NutritionValue unit="g"
                                                            value={ingredient?.nutrient_extended?.poly_unsaturated}
                                                            onChange={(value) => onNutrientExtendedChange("poly_unsaturated", value)}/>
                                        </Nutrition>

                                        <Nutrition label="Linoleinska kiselina (ALA)">
                                            <NutritionValue unit="mg"
                                                            value={ingredient?.nutrient_fatty_acid?.alpha_linolenic_acid}
                                                            onChange={(value) => onFattyAcidChange("alpha_linolenic_acid", value)}/>
                                        </Nutrition>

                                        <Nutrition label="Eikozapentaenoinska kiselina (EPA)">
                                            <NutritionValue unit="mg"
                                                            value={ingredient?.nutrient_fatty_acid?.eicopentaenoic_acid}
                                                            onChange={(value) => onFattyAcidChange("eicopentaenoic_acid", value)}/>
                                        </Nutrition>

                                        <Nutrition label="Dokozapentaenoinska kiselina (DPA)">
                                            <NutritionValue unit="mg"
                                                            value={ingredient?.nutrient_fatty_acid?.docosapentaenoic_acid}
                                                            onChange={(value) => onFattyAcidChange("docosapentaenoic_acid", value)}/>
                                        </Nutrition>

                                        <Nutrition label="Ugljeni hidrati">
                                            <NutritionValue unit="g"
                                                            value={ingredient?.nutrient_basic?.carbohydrate}
                                                            onChange={(value) => onNutrientBasicChange("carbohydrate", value)}/>
                                        </Nutrition>

                                        <Nutrition label="Od kojih šećeri">
                                            <NutritionValue unit="g"
                                                            value={ingredient?.nutrient_basic?.sugar}
                                                            onChange={(value) => onNutrientBasicChange("sugar", value)}/>
                                        </Nutrition>



                                        <Nutrition label="Od kojih polioli">
                                            <NutritionValue unit="g"
                                                            value={ingredient?.nutrient_extended?.polyol}
                                                            onChange={(value) => onNutrientExtendedChange("polyol", value)}/>
                                        </Nutrition>

                                        <Nutrition label="Od kojih eritritol">
                                            <NutritionValue unit="g"
                                                            value={ingredient?.nutrient_extended?.erythritol}
                                                            onChange={(value) => onNutrientExtendedChange("erythritol", value)}/>
                                        </Nutrition>

                                        <Nutrition label="Od kojih skrob">
                                            <NutritionValue unit="g"
                                                            value={ingredient?.nutrient_extended?.starch}
                                                            onChange={(value) => onNutrientExtendedChange("starch", value)}/>
                                        </Nutrition>
                                        <Nutrition label="Vlakna">
                                            <NutritionValue unit="g"
                                                            value={ingredient?.nutrient_extended?.fibre}
                                                            onChange={(value) => onNutrientExtendedChange("fibre", value)}/>
                                        </Nutrition>
                                        <Nutrition label="Proteini">
                                            <NutritionValue unit="g"
                                                            value={ingredient?.nutrient_basic?.protein}
                                                            onChange={(value) => onNutrientBasicChange("protein", value)}/>
                                        </Nutrition>
                                        <Nutrition label="So">
                                            <NutritionValue unit="g"
                                                            value={ingredient?.nutrient_basic?.salt}
                                                            onChange={(value) => onNutrientBasicChange("salt", value)}/>
                                        </Nutrition>
                                        <Nutrition label="Alkohol">
                                            <NutritionValue unit="g"
                                                            value={ingredient?.nutrient_extended?.alcohol}
                                                            onChange={(value) => onNutrientExtendedChange("alcohol", value)}/>
                                        </Nutrition>
                                        <Nutrition label="Pepeo">
                                            <NutritionValue unit="g"
                                                            value={ingredient?.nutrient_extended?.ash}
                                                            onChange={(value) => onNutrientExtendedChange("ash", value)}/>
                                        </Nutrition>
                                    </div>


                                    <div className="mt-4 rounded-[8px] overflow-hidden">
                                        <Nutrition label="Voda">
                                            <NutritionValue unit="g"
                                                            value={ingredient?.nutrient_basic?.water}
                                                            onChange={(value) => onNutrientBasicChange("water", value)}/>
                                        </Nutrition>
                                    </div>
                                </div>
                            )
                        }

                        {
                            tab === TabName.VITAMINS && (
                                <div className="rounded-b-[8px] overflow-hidden">
                                    <Nutrition label="Vitamin A" isDashed={false}>
                                        <NutritionValue unit="µg"
                                                        value={ingredient?.nutrient_vitamin?.vitamin_a}
                                                        onChange={(value) => onVitaminChange("vitamin_a", value)}
                                        />
                                    </Nutrition>
                                    <Nutrition label="Vitamin D" isDashed={false}>
                                        <NutritionValue unit="µg"
                                                        value={ingredient?.nutrient_vitamin?.vitamin_d}
                                                        onChange={(value) => onVitaminChange("vitamin_d", value)}
                                        />
                                    </Nutrition>
                                    <Nutrition label="Vitamin E" isDashed={false}>
                                        <NutritionValue unit="mg"
                                                        value={ingredient?.nutrient_vitamin?.vitamin_e}
                                                        onChange={(value) => onVitaminChange("vitamin_e", value)}
                                        />
                                    </Nutrition>
                                    <Nutrition label="Vitamin K" isDashed={false}>
                                        <NutritionValue unit="µg"
                                                        value={ingredient?.nutrient_vitamin?.vitamin_k}
                                                        onChange={(value) => onVitaminChange("vitamin_k", value)}
                                        />
                                    </Nutrition>
                                    <Nutrition label="Vitamin C" isDashed={false}>
                                        <NutritionValue unit="mg"
                                                        value={ingredient?.nutrient_vitamin?.vitamin_c}
                                                        onChange={(value) => onVitaminChange("vitamin_c", value)}
                                        />
                                    </Nutrition>
                                    <Nutrition label="Tiamin" isDashed={false}>
                                        <NutritionValue unit="mg"
                                                        value={ingredient?.nutrient_vitamin?.thiamin_b1}
                                                        onChange={(value) => onVitaminChange("thiamin_b1", value)}
                                        />
                                    </Nutrition>
                                    <Nutrition label="Riboflavin" isDashed={false}>
                                        <NutritionValue unit="mg"
                                                        value={ingredient?.nutrient_vitamin?.riboflavin_b2}
                                                        onChange={(value) => onVitaminChange("riboflavin_b2", value)}
                                        />
                                    </Nutrition>
                                    <Nutrition label="Niacin" isDashed={false}>
                                        <NutritionValue unit="mg"
                                                        value={ingredient?.nutrient_vitamin?.niacin_b3}
                                                        onChange={(value) => onVitaminChange("niacin_b3", value)}
                                        />
                                    </Nutrition>
                                    <Nutrition label="Vitamin B6" isDashed={false}>
                                        <NutritionValue unit="mg"
                                                        value={ingredient?.nutrient_vitamin?.vitamin_b6}
                                                        onChange={(value) => onVitaminChange("vitamin_b6", value)}
                                        />
                                    </Nutrition>
                                    <Nutrition label="Folna kiselina" isDashed={false}>
                                        <NutritionValue unit="µg"
                                                        value={ingredient?.nutrient_vitamin?.folate_b9}
                                                        onChange={(value) => onVitaminChange("folate_b9", value)}
                                        />
                                    </Nutrition>
                                    <Nutrition label="Vitamin B12" isDashed={false}>
                                        <NutritionValue unit="µg"
                                                        value={ingredient?.nutrient_vitamin?.vitamin_b12}
                                                        onChange={(value) => onVitaminChange("vitamin_b12", value)}
                                        />
                                    </Nutrition>
                                    <Nutrition label="Biotin" isDashed={false}>
                                        <NutritionValue unit="µg"
                                                        value={ingredient?.nutrient_vitamin?.biotin_b7}
                                                        onChange={(value) => onVitaminChange("biotin_b7", value)}
                                        />
                                    </Nutrition>
                                    <Nutrition label="Pantotenska kiselina" isDashed={false}>
                                        <NutritionValue unit="mg"
                                                        value={ingredient?.nutrient_vitamin?.pantothenic_acid_b5}
                                                        onChange={(value) => onVitaminChange("pantothenic_acid_b5", value)}
                                        />
                                    </Nutrition>


                                    <Nutrition label="Kalijum" isDashed={false}>
                                        <NutritionValue unit="mg"
                                                        value={ingredient?.nutrient_mineral?.potassium}
                                                        onChange={(value) => onMineralChange("potassium", value)}
                                        />
                                    </Nutrition>
                                    <Nutrition label="Hloridi" isDashed={false}>
                                        <NutritionValue unit="mg"
                                                        value={ingredient?.nutrient_mineral?.chloride}
                                                        onChange={(value) => onMineralChange("chloride", value)}
                                        />
                                    </Nutrition>
                                    <Nutrition label="Kalcijum" isDashed={false}>
                                        <NutritionValue unit="mg"
                                                        value={ingredient?.nutrient_mineral?.calcium}
                                                        onChange={(value) => onMineralChange("calcium", value)}
                                        />
                                    </Nutrition>
                                    <Nutrition label="Fosfor" isDashed={false}>
                                        <NutritionValue unit="mg"
                                                        value={ingredient?.nutrient_mineral?.phosphorus}
                                                        onChange={(value) => onMineralChange("phosphorus", value)}
                                        />
                                    </Nutrition>

                                    <Nutrition label="Magnezijum" isDashed={false}>
                                        <NutritionValue unit="mg"
                                                        value={ingredient?.nutrient_mineral?.magnesium}
                                                        onChange={(value) => onMineralChange("magnesium", value)}
                                        />
                                    </Nutrition>


                                    <Nutrition label="Gvožđe" isDashed={false}>
                                        <NutritionValue unit="mg"
                                                        value={ingredient?.nutrient_mineral?.iron}
                                                        onChange={(value) => onMineralChange("iron", value)}
                                        />
                                    </Nutrition>
                                    <Nutrition label="Cink" isDashed={false}>
                                        <NutritionValue unit="mg"
                                                        value={ingredient?.nutrient_mineral?.zinc}
                                                        onChange={(value) => onMineralChange("zinc", value)}
                                        />
                                    </Nutrition>
                                    <Nutrition label="Bakar" isDashed={false}>
                                        <NutritionValue unit="mg"
                                                        value={ingredient?.nutrient_mineral?.copper}
                                                        onChange={(value) => onMineralChange("copper", value)}
                                        />
                                    </Nutrition>

                                    <Nutrition label="Mangan" isDashed={false}>
                                        <NutritionValue unit="mg"
                                                        value={ingredient?.nutrient_mineral?.manganese}
                                                        onChange={(value) => onMineralChange("manganese", value)}
                                        />
                                    </Nutrition>

                                    <Nutrition label="Fluorid" isDashed={false}>
                                        <NutritionValue unit="mg"
                                                        value={ingredient?.nutrient_mineral?.fluoride}
                                                        onChange={(value) => onMineralChange("fluoride", value)}
                                        />
                                    </Nutrition>


                                    <Nutrition label="Selen" isDashed={false}>
                                        <NutritionValue unit="µg"
                                                        value={ingredient?.nutrient_mineral?.selenium}
                                                        onChange={(value) => onMineralChange("selenium", value)}
                                        />
                                    </Nutrition>
                                    <Nutrition label="Hrom" isDashed={false}>
                                        <NutritionValue unit="µg"
                                                        value={ingredient?.nutrient_mineral?.chromium}
                                                        onChange={(value) => onMineralChange("chromium", value)}
                                        />
                                    </Nutrition>

                                    <Nutrition label="Molibden" isDashed={false}>
                                        <NutritionValue unit="µg"
                                                        value={ingredient?.nutrient_mineral?.molybdenum}
                                                        onChange={(value) => onMineralChange("molybdenum", value)}
                                        />
                                    </Nutrition>

                                    <Nutrition label="Jod" isDashed={false}>
                                        <NutritionValue unit="µg"
                                                        value={ingredient?.nutrient_mineral?.iodine}
                                                        onChange={(value) => onMineralChange("iodine", value)}
                                        />
                                    </Nutrition>
                                </div>
                            )
                        }
                    </div>
                </div>
            </Modal.Body>

            <Modal.Footer isValid={isValid}>
                <div className="flex items-center grow py-4">
                    {
                        !ingredient.id &&
                        <Button size="large"
                                color="secondary"
                                startIcon={<img src="/save.svg"/>}
                                className="mr-10 basis-1 grow"
                                onClick={() => onSave(ingredient)}>Sačuvaj</Button>
                    }

                    {
                        !ingredient.id &&
                        <Button size="large"
                                color="primary"
                                className=" grow basis-1"
                            // endIcon={<img src="/next.svg"/>}
                                onClick={() => onSaveAndAdd(ingredient)}>+ Sačuvaj i ubaci u sastojke</Button>
                    }

                    {
                        ingredient.id &&
                        <Button size="large"
                                color="secondary"
                                startIcon={<img src="/save.svg"/>}
                                className="mr-10 grow basis-1"
                                onClick={() => onUpdate(ingredient)}>Izmeni</Button>
                    }

                    {
                        ingredient.id &&
                        <Button size="large"
                                color="primary"
                                className="grow basis-1"
                                onClick={() => onUpdateAndAdd(ingredient)}>+ Izmeni i ubaci u sastojke</Button>
                    }
                </div>
            </Modal.Footer>
        </Modal>
    )
}

export default IngredientForm