import * as React from "react";
const SvgClockIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={16}
    height={16}
    fill="none"
    stroke="#313131"
    {...props}
  >
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M8 14A6 6 0 1 0 8 2a6 6 0 0 0 0 12Z"
    />
    <path strokeLinecap="round" strokeLinejoin="round" d="M8 4v3.75L10 9" />
  </svg>
);
export default SvgClockIcon;
