import React, { useState } from "react";

import "../css/nutricalRequestCard.css";
import CustomerInfo from "./CustomerInfo";
import { CheckboxIcon, DownloadIcon } from "../icons";
import PackageTitle from "./PackageTitle";
import { downloadFile } from "../service/MediaService";
import {
  displayAllNutrients,
  getCheckedNutrients,
  NutrientType,
} from "../service/VitaminsAndMineralsService";
import AttachmentsInCard from "./AttachmentsInCard";

const NutricalRequestCard = ({ recipe, onRecipeDetailsClick }) => {
  const [isExtended, setIsExtended] = useState(false);

  if (!recipe || !recipe.nutrical_request) {
    return;
  }

  const attachments = recipe.nutrical_request.nutrients
    .filter((e) => !!e.storage)
    .map((e) => e.storage);

  const checkedVitamins = getCheckedNutrients(
    recipe.nutrical_request?.nutrient_flag,
    NutrientType.VITAMIN
  );
  const checkedMinerals = getCheckedNutrients(
    recipe.nutrical_request?.nutrient_flag,
    NutrientType.MINERAL
  );
  const allVitaminsChecked = displayAllNutrients(
    recipe.nutrical_request?.nutrient_flag,
    NutrientType.VITAMIN
  );
  const allMineralsChecked = displayAllNutrients(
    recipe.nutrical_request?.nutrient_flag,
    NutrientType.MINERAL
  );

  return (
    <div className="nutrical-request-card text-primary">
      <PackageTitle
        title="Informacije o proizvodu"
        subscription={recipe?.nutrical_request?.subscription_package}
        nutricalRequestId={recipe?.nutrical_request?.id}
      />
      <div className="p-[15px]">
        <div>
          <div className="flex space-between items-center">
            <div className="text-base font-medium">
              {recipe.nutrical_request.recipe_name}
            </div>
            <div
              className="underline font-medium text-sm text-primary hover:cursor-pointer hover:opacity-80"
              onClick={onRecipeDetailsClick}
            >
              Detaljnije
            </div>
          </div>

          <div className="text-sm">
            {recipe.nutrical_request.recipe_category}
          </div>
        </div>

        <div className="flex items-center space-between mt-[10px]">
          <div className="text-light text-sm">Broj izveštaja:</div>
          <div>{recipe.nutrical_request.id}</div>
        </div>

        {recipe.nutrical_request.ingredients &&
          recipe.nutrical_request.ingredients.length > 0 && (
            <div>
              <div className="mt-[20px] text-light text-sm mb-1">Sastojci</div>
              {recipe.nutrical_request.ingredients
                .sort((e1, e2) => e1.id - e2.id)
                .map((ingredient) => (
                  <div key={ingredient.id} className="flex items-baseline">
                    <div className="grow-0 pr-[10px] text-sm">
                      {ingredient.name}
                    </div>
                    <div className="grow border-b border-dashed border-light"></div>
                    <div className="grow-0 pl-[10px]">
                      <span className="text-sm">{ingredient.amount}</span>
                      <span className="ml-1 text-sm unit">
                        {ingredient.unit.toLowerCase()}
                      </span>
                    </div>
                  </div>
                ))}
            </div>
          )}

        {recipe.nutrical_request.recipe_method_preparation && (
          <div className="mt-[20px] text-sm">
            <div className="text-light mb-1">Način pripreme</div>
            <div className="value">
              {recipe.nutrical_request.recipe_method_preparation}
            </div>
          </div>
        )}

        {recipe.nutrical_request.recipe_prepared_amount && (
          <div className="mt-[20px] text-sm flex space-between">
            <div className="text-light mb-1">Masa proizvoda nakon pripreme</div>
            <div className="flex">
              <div className="value">
                {recipe.nutrical_request?.recipe_prepared_amount}
              </div>
              <div className="ml-1 lowercase">
                {recipe.nutrical_request?.recipe_unit}
              </div>
            </div>
          </div>
        )}

        {recipe.nutrical_request.portion_amount && (
          <div className="mt-[20px] text-sm flex space-between">
            <div className="text-light mb-1">Veličina porcije</div>
            <div className="flex">
              <div className="value">
                {recipe.nutrical_request?.portion_amount}
              </div>
              <div className="ml-1 lowercase">
                {recipe.nutrical_request?.portion_unit}
              </div>
            </div>
          </div>
        )}

        <AttachmentsInCard attachments={attachments} />

        <div className="mt-[20px] text-sm">
          <div className="text-light mb-1">Dodatno</div>
          {recipe.nutrical_request?.nutrient_flag
            ?.referent_entry_percentage && (
            <div className="flex items-center">
              <CheckboxIcon width={10} height={10} stroke="black" />
              <div className="ml-2">Referentni unos</div>
            </div>
          )}

          {recipe.nutrical_request?.nutrient_flag?.portion && (
            <div className="flex items-center">
              <CheckboxIcon width={10} height={10} stroke="black" />
              <div className="ml-2">Prikaz po porciji</div>
            </div>
          )}

          {recipe.nutrical_request?.nutrient_flag?.mono_unsaturated && (
            <div className="flex items-center">
              <CheckboxIcon width={10} height={10} stroke="black" />
              <div className="ml-2">
                Prikazati mononezasićene masne kiseline
              </div>
            </div>
          )}

          {recipe.nutrical_request?.nutrient_flag?.poly_unsaturated && (
            <div className="flex items-center">
              <CheckboxIcon width={10} height={10} stroke="black" />
              <div className="ml-2">
                Prikazati polinezasićene masne kiseline
              </div>
            </div>
          )}

          {recipe.nutrical_request?.nutrient_flag?.polyol && (
            <div className="flex items-center">
              <CheckboxIcon width={10} height={10} stroke="black" />
              <div className="ml-2">Prikazati poliole</div>
            </div>
          )}

          {recipe.nutrical_request?.nutrient_flag?.fibre && (
            <div className="flex items-center">
              <CheckboxIcon width={10} height={10} stroke="black" />
              <div className="ml-2">Prikazati vlakna</div>
            </div>
          )}

          {recipe.nutrical_request?.nutrient_flag?.starch && (
            <div className="flex items-center">
              <CheckboxIcon width={10} height={10} stroke="black" />
              <div className="ml-2">Prikazati skrob</div>
            </div>
          )}
        </div>

        <div className="mt-[20px] text-sm">
          <div className="text-light mb-1">Vitamini</div>
          <div className="text-xs">
            {!allVitaminsChecked && <div>{checkedVitamins}</div>}
            {allVitaminsChecked && <div>Svi vitamini izabrani</div>}
            {!checkedVitamins && <div>Nema izabranih vitamina</div>}
          </div>
        </div>

        <div className="mt-[20px] text-sm">
          <div className="text-light mb-1">Minerali</div>
          <div className="text-xs">
            {!allMineralsChecked && <div>{checkedMinerals}</div>}
            {allMineralsChecked && <div>Svi minerali izabrani</div>}
            {!checkedMinerals && <div>Nema izabranih minerala</div>}
          </div>
        </div>

        <div className="mt-[20px] text-sm">
          <div className="text-light mb-1">Traffic light score</div>
          <div>
            {recipe.nutrical_request?.nutrient_flag?.traffic_light_standard
              ? "Da"
              : "Ne"}
          </div>
        </div>

        <div className="mt-[20px] text-sm">
          <div className="text-light mb-1">Nutritivne izjave</div>
          <div>
            {recipe.nutrical_request?.nutrient_flag?.nutrient_statement
              ? "Da"
              : "Ne"}
          </div>
        </div>

        {recipe.nutrical_request.customer_name && (
          <div className="field mt-[20px] flex space-between items-start">
            <div>
              <div className="text-base font-medium">
                {recipe.nutrical_request.customer_name}
              </div>
              <div className="text-sm">
                {recipe.nutrical_request.customer_phone}
              </div>
            </div>
            <div
              className="underline font-medium text-sm text-primary
                        hover:cursor-pointer hover:text-black"
              onClick={() => setIsExtended(true)}
            >
              Detaljnije
            </div>
          </div>
        )}
      </div>

      <CustomerInfo
        recipe={recipe}
        isExtended={isExtended}
        setIsExtended={setIsExtended}
      />
    </div>
  );
};

export default NutricalRequestCard;
