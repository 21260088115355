import axios from "axios";
import {nutrical_ingredients_url} from "../config/api";
import {useState} from "react";

const emptyValue = {
    name: '',
    ingredients_text: '',
    nutrient_basic: {
        energy_kj: 0,
        energy_kcal: 0,
        carbohydrate: 0,
        sugar: 0,
        fat: 0,
        fat_saturated: 0,
        protein: 0,
        salt: 0,
        water: 0
    },
    nutrient_extended: {
        fibre: 0,
        mono_unsaturated: 0,
        poly_unsaturated: 0,
        polyol: 0,
        starch: 0
    }
}

const useIngredient = () => {

    const [selectedIngredient, setSelectedIngredient] = useState(emptyValue)
    const [isVisibleIngredientForm, setIsVisibleIngredientForm] = useState(false)


    const saveIngredient = async (ingredient) => {
        return axios({
            url: nutrical_ingredients_url,
            method: 'POST',
            data: ingredient
        }).then((response) => {
            return response.data
        }).finally(() => {
            setSelectedIngredient(emptyValue)
            setIsVisibleIngredientForm(false)
        })
    }

    const updateIngredient = (ingredient) => {
        return axios({
            url: `${nutrical_ingredients_url}/${ingredient.id}`,
            method: 'POST',
            data: ingredient
        }).then((response) => {
            return response.data
        }).finally(() => {
            setSelectedIngredient(emptyValue)
            setIsVisibleIngredientForm(false)
        })
    }

    const deleteIngredient = (id) => {
        axios({
            method: 'DELETE',
            url: `${nutrical_ingredients_url}/${id}`
        }).finally(() => {
            setSelectedIngredient(emptyValue)
        })
    }

    const openIngredientForm = (ingredient) => {
        setSelectedIngredient(ingredient || emptyValue)
        setIsVisibleIngredientForm(true)
    }

    const closeIngredientForm = () => {
        setSelectedIngredient(emptyValue)
        setIsVisibleIngredientForm(false)
    }

    return [
        selectedIngredient,
        isVisibleIngredientForm,
        openIngredientForm,
        closeIngredientForm,
        saveIngredient,
        updateIngredient,
        deleteIngredient
    ]
}

export default useIngredient