import React, { useState } from "react";
import "../css/nutricalRequestTable.css";
import Button from "./Button";
import Status from "./Status";
import DeleteModal from "./DeleteModal";
import { format } from "date-fns";
import { TrashIcon } from "../icons";
import { useNavigate } from "react-router-dom";

const ClientsTable = (props) => {
  let { data, onDelete, isLoading } = props;
  const navigate = useNavigate();

  const [selectedForDeletion, setSelectedForDeletion] = useState(null);

  const isDeleteModalOpen = Boolean(selectedForDeletion);

  const handleOpenBtnClick = (restaurant) => {
    navigate(`${restaurant.id}/requests`, {
      state: { restaurant: restaurant },
    });
  };

  const handleConfirmDeleteBtnCLick = () => {
    onDelete(selectedForDeletion.id);
    setSelectedForDeletion(null);
  };

  return (
    <div className="nutrical-request-table mt-[24px]">
      <table className="">
        <thead>
          <tr>
            <th className="w-20-pct-sm">Redni broj</th>
            <th className="w-40-pct-sm">Datum i vreme</th>
            <th className="w-40-pct-sm">Naziv restirana</th>
            <th className="w-40-pct-sm">Br. zahteva</th>
            <th className="w-40-pct-sm pl-35">Status</th>
            <th className="pr-50 w-[160px]">Akcije</th>
          </tr>
        </thead>
        <tbody>
          {data &&
            data.map((e) => (
              <tr key={e.id} className="text-primary">
                <td className="font-medium">{e.id}</td>
                <td className="">
                  <div>
                    {e.created_at
                      ? format(new Date(e.created_at), "dd.MM.yyyy")
                      : ""}
                  </div>
                  <div>
                    {e.created_at
                      ? format(new Date(e.created_at), "HH:mm")
                      : ""}
                  </div>
                </td>
                <td>
                  <div className="font-medium">{e.name}</div>
                  <div>{e.contact_name}</div>
                </td>
                <td className="font-semibold uppercase text-package">
                  {e.total_requests}
                </td>
                <td className="">
                  <Status status={e.status} />
                </td>
                <td className="actions">
                  <div className="flex">
                    <Button
                      className="w-[90px] rounded-[4px] border border-black"
                      color="white"
                      onClick={() => handleOpenBtnClick(e)}
                    >
                      Otvori
                    </Button>
                    <TrashIcon
                      className="ml-4 hover:cursor-pointer"
                      onClick={() => setSelectedForDeletion(e)}
                    />
                  </div>
                </td>
              </tr>
            ))}
        </tbody>
      </table>
      {isLoading && <div className="loader-line"></div>}
      <DeleteModal
        open={isDeleteModalOpen}
        title={"Obriši"}
        itemName={selectedForDeletion ? selectedForDeletion.name : ""}
        message="Da li ste sigurni da želite da obrišete ovaj rekord?"
        onDelete={handleConfirmDeleteBtnCLick}
        onClose={() => setSelectedForDeletion(null)}
      />
    </div>
  );
};

export default ClientsTable;
