import * as React from "react";
const SvgBackIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={16}
    height={16}
    fill="none"
    {...props}
  >
    <path
      stroke="#323232"
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M12.666 8H3.333M8 12.667 3.333 8 8 3.333"
    />
  </svg>
);
export default SvgBackIcon;
