import React from "react";

import "../css/modal.css"
import classNames from "classnames";
import ModalCloseBtn from "../icons/ModalCloseBtn";

const Modal = ({children, show, contentClassName, onClose}) => {

    if (!show) {
        return
    }

    const handleOnCloseBtnClick = () => {
        onClose()
    }

    const contentClasses = classNames(contentClassName, "content")

    return (
        <div className={"Modal flex"}>
            <div className={contentClasses}>
                <div className="modal-close-btn" onClick={handleOnCloseBtnClick}>
                    <ModalCloseBtn/>
                </div>
                {children}
            </div>
        </div>
    )
}

Modal.Header = ({children, className}) => {

    const classes = classNames( "header", className)

    return (
        <div className={classes}>
            {children}
        </div>
    )
}

Modal.Body = ({children, className}) => {

    const classes = classNames("body overflow-auto", className)

    return (
        <div className={classes}>
            {children}
        </div>
    )
}

Modal.Footer = ({children, isValid, className}) => {

    const classes = classNames("footer", className)

    return (
        <div className={classes}>
            {children}
            {!isValid &&  <div className="invalid"></div>}
        </div>
    )
}

export default Modal