import React, {useEffect, useMemo, useRef, useState} from "react";
import Navigation from "../components/Navigation";
import {
    deleteRestaurantRequestFiles,
    getMenuPDF,
    getMenuZip,
    getRestaurant,
    reorderRestaurantRequests,
    updateRestaurant, updateRestaurantRequestCover, updateRestaurantRequestMeal,
    updateRestaurantStatus,
} from "../service/RestaurantService";
import {useNavigate, useParams} from "react-router-dom";
import Expandable from "../components/Expandable";
import TextField from "../components/TextField";
import NextStep from "../components/NextStep";
import Button from "../components/Button";
import {DownloadIcon, EditIcon, EyeIcon, SaveIcon, UploadIcon, XCircleIcon} from "../icons";
import {DocType} from "../hooks/useRecipeReport";
import Loader from "../components/Loader";
import {executeDownload} from "../service/HtmlUtils";
import {DndProvider, useDrag, useDrop} from "react-dnd";
import {HTML5Backend} from "react-dnd-html5-backend";
import classNames from "classnames";
import MealCategorySelect from "../components/MealCategorySelect";
import MealCategoriesModal from "../components/MealCategoriesModal";
import Checkmark from "../components/Checkmark";
import NumberField from "../components/NumberField";
import {useMealCategories} from "../store/MealCategoriesStore";
import {
    useRestaurantMealPriceUpdate,
    useRestaurantMeals, useRestaurantMealSelectionUpdate,
    useRestaurantMealUpdate
} from "../store/RestaurantRequestStore";
import {uploadFile} from "../service/MediaService";
import {useMediaStore} from "../store/MediaStore";
import {useQueryClient} from "react-query";
import CircularLoader from "../components/CircularLoader";
import {publishDigitalMenu} from "../service/DigitalMenu";
import {useQr} from "../store/useDigitalMenu";
import {ToastContainer, toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Modal from "../components/Modal";
import _ from "lodash";

const PublishStatus = ({status, onClose}) => {

    return (
        <Modal show={true} onClose={onClose} contentClassName="bg-white min-w-[500px] w-[800px]">
            <Modal.Header>Publish - Status</Modal.Header>
            <Modal.Body>
                <table className="w-full text-left text-sm">
                    <thead className="font-regular opacity-60 text-sm">
                    <tr>
                        <th className="text-left px-2">Id</th>
                        <th className="px-2">Recept</th>
                        <th className="px-2">Status</th>
                        <th className="px-2">Opis</th>
                    </tr>
                    </thead>
                    <tbody>
                    {
                        status?.items?.map(item => (
                            <tr className="h-[42px]" key={item.restaurant_request_id}>
                                <td className="text-left px-2">{item.restaurant_request_id}</td>
                                <td className="px-2">{item.restaurant_request_name}</td>
                                <td className="px-2">{item.status}</td>
                                <td className="px-2 whitespace-pre-wrap">{item.message}</td>
                            </tr>
                        ))
                    }
                    </tbody>
                </table>
            </Modal.Body>
        </Modal>
    )
}


const QR = ({qr}) => {

    const {data: img} = useMediaStore(qr?.storage?.id)


    const qrImg = useMemo(() => {
        return img ? URL.createObjectURL(img) : null;
    }, [img]);

    if (!qrImg) {
        return null; // or any other placeholder component or loading indicator
    }

    return (
        <img src={qrImg} alt="QR"/>
    )
}

const useSorting = (categories, requests) => {

    const [requestsByCategory, setRequestsByCategory] = useState({})

    useEffect(() => {
        let map = {}

        if (!requests) {
            return
        }

        requests.forEach(request => {
            let categoryId = request?.meal_category?.id ?? '0'

            if (!map[categoryId]) {
                map[categoryId] = [];
            }

            map[categoryId].push(request);
        })

        setRequestsByCategory(map)


    }, [categories, requests]);

    return {
        requestsByCategory,
        setRequestsByCategory
    }
}

const ImgUpload =({uuid, onUpload, onRemove}) => {

    const fileInputRef = useRef(null);

    const {data: imageData, isError} = useMediaStore(uuid);
    const [isUploading, setIsUploading] = useState(false)
    const [isRemoving, setRemoving] = useState(false)
    const imgSrc = useMemo(() => {
        return imageData ? URL.createObjectURL(imageData) : null;
    }, [imageData]);


    const handleButtonClick = () => {
        fileInputRef.current.click(); // Programmatically trigger file input click
    };

    const handleFileChange = async () => {
        setIsUploading(true)
        await onUpload(fileInputRef.current.files[0])
        setIsUploading(false)
    };

    const handleRemove = async () => {
        setRemoving(true)
        await onRemove()
        setRemoving(false)
    }

    if (isUploading || isRemoving) return <CircularLoader/>;
    if (isError) return <div>Error!</div>;

    if (uuid && imageData && imgSrc) {

        return (
            <div className="relative w-full h-full">
                <img src={imgSrc} alt="Image" className="object-cover w-full h-full"/>
                <div
                    className="w-[23px] h-[23px] rounded-full bg-white flex items-center justify-center absolute right-[5px] top-[5px] hover:cursor-pointer hover:bg-gray-200"
                    onClick={handleRemove}>
                    <XCircleIcon/>
                </div>
            </div>
        );
    }

    return (
        <div
            className="w-full h-full border-2 bg-gray-100 border-dashed border-gray-300  flex flex-col items-center justify-center hover:border-gray-600 hover:cursor-pointer"
            onClick={handleButtonClick}>
            <input type="file"
                   ref={fileInputRef}
                   onChange={handleFileChange}
                   style={{display: 'none'}}/>
            <div className="text-sm opacity-80">Izbor slike</div>
            <UploadIcon/>
        </div>
    )
}


const UncategorizedItem = ({request, clientId, categories, handleImgUpload, handleImgRemove}) => {

    const {mutate: updateMeals} = useRestaurantMealUpdate(clientId)

    const handleMealCategoryChange = (category) => {
        updateMeals({
            ...request,
            meal_category: category
        })
    }

    const toggleSelection = () => {
        updateMeals({
            ...request,
            is_selected: !request.is_selected
        })
    }

    const storageId = request?.restaurant_request_files?.[0]?.storage?.id


    return (
        <tr
            key={request.id}
            id={request.id}
            className={classNames("border-b border-b-gray-100 hover:bg-gray-100", {})}
        >
            <td className="text-center py-2">
                <div className="flex items-center justify-center" onClick={toggleSelection}>
                    <Checkmark checked={request.is_selected}/>
                </div>
            </td>
            <td>
                <div className="w-[120px] h-[90px] rounded-[6px] overflow-hidden">
                    <ImgUpload uuid={storageId}
                               onUpload={(file) => handleImgUpload(file, request.id)}
                               onRemove={() => handleImgRemove(request.id)}/>
                </div>
            </td>
            <td className="px-4  py-2 align-middle">
                <div className="flex flex-col items-start">
                    <div className="text-primary font-medium text-sm">
                        {request.recipe_name}
                    </div>
                    <div
                        className="mt-1 text-sm"
                        dangerouslySetInnerHTML={{
                            __html: request.ingredient_text,
                        }}
                    />
                    {request.allergens && request.allergens.length > 0 &&
                        <div
                            className="min-h-[30px] bg-input-disabled text-sm py-2 px-4 text-light rounded-[8px] mt-2 shrink">
                            {request.allergens.map((e) => e.allergen_label).join(", ")}
                        </div>
                    }
                </div>
            </td>
            <td className="px-4 py-2">
                <MealCategorySelect id={request.id}
                                    clientId={clientId}
                                    categories={categories}
                                    onChange={handleMealCategoryChange}
                />
            </td>
            <td className="px-4 py-2">
                <Price defaultPrice={request.price ?? ''} request={request} clientId={clientId}/>
            </td>
        </tr>
    );
};


const MealsTable = React.memo(({
                                   categories,
                                   requestsByCategory,
                                   setRequestsByCategory,
                                   clientId,

                                   moveItem
                               }) => {


    if (!requestsByCategory) {
        return
    }

    return (
        <div>
            {
                categories &&
                categories
                    .filter(category => requestsByCategory[category.id] && requestsByCategory[category.id].length > 0)
                    .sort((a, b) => a.index - b.index)
                    .map((category, categoryIndex) => (
                        <Expandable key={category.id} className="w-full mt-[20px] mb-12"
                                    title={category?.category}>
                            {
                                requestsByCategory[category.id] &&
                                requestsByCategory[category.id].length > 0 && (
                                    <DndProvider backend={HTML5Backend}>
                                        {/*<table className="w-full border-collapse table-fixed">*/}
                                        {/*    <thead*/}
                                        {/*        className="px-4 text-primary opacity-70 text-sm h-[42px] border-b border-b-gray-100">*/}
                                        {/*    <tr>*/}
                                        {/*        <th className="w-[60px] text-center"></th>*/}
                                        {/*        <th className="w-[120px]"></th>*/}
                                        {/*        <th className="px-4 text-left font-light">*/}
                                        {/*            Naziv recepta*/}
                                        {/*        </th>*/}
                                        {/*        <th className="px-4 text-left font-light w-[160px]">Cena</th>*/}
                                        {/*        <th className="w-[50px] text-right"></th>*/}
                                        {/*    </tr>*/}
                                        {/*    </thead>*/}
                                        {/*    <tbody>*/}

                                        <div className="flex flex-col w-full">
                                            {
                                                requestsByCategory[category.id]
                                                    .sort((a, b) => a.position - b.position)
                                                    .map((request, index) => (
                                                        <DraggableItem clientId={clientId}
                                                                       key={request.id}
                                                                       index={index}
                                                                       moveItem={(fromIndex, toIndex) => moveItem(category.id, fromIndex, toIndex)}
                                                                       categoryId={category.id}>
                                                            <MealItem clientId={clientId}
                                                                      key={request.id}
                                                                      request={request}
                                                                      index={index}
                                                                      moveItem={(fromIndex, toIndex) => moveItem(category.id, fromIndex, toIndex)}
                                                                      categoryId={category.id}
                                                                      requestsByCategory={requestsByCategory}
                                                                      setRequestsByCategory={setRequestsByCategory}
                                                            />
                                                        </DraggableItem>


                                                    ))}
                                        </div>
                                    </DndProvider>
                                )
                            }
                        </Expandable>
                    ))
            }
        </div>
    )
})

const Price = ({defaultPrice, request, value, onChange}) => {

    // const [price, setPrice] = useState(defaultPrice)
    //
    // const updatePrice = useMemo(() => _.debounce(updateRestaurantRequestMeal, 1000), []);
    //
    // const handlePriceChange = (e) => {
    //
    //     setPrice(e.target.value)
    //     updatePrice({
    //         ...request,
    //         price: e.target.value
    //     })
    // }

    return (
        <div className="flex items-center w-full">
            <NumberField value={value} onChange={onChange}
                         inputClassName="w-[100px] mr-2"/>
            <div className="text-light text-sm">RSD</div>
        </div>
    )
}


const DraggableItem = ({
                           children,
                           index,
                           moveItem,
                           categoryId
                       }) => {


    const [{isDragging}, drag] = useDrag({
        type: `category-${categoryId}`,
        item: {index},
        collect: (monitor) => ({
            isDragging: !!monitor.isDragging(),
        }),
        snapToGrid: 60,
    });

    const [{isOver}, drop] = useDrop({
        accept: `category-${categoryId}`,
        drop: (draggedItem) => {

            console.log('DROP')
            console.log(draggedItem)
            console.log(draggedItem["index"])

            if (draggedItem.index !== index) {
                moveItem(draggedItem.index, index);
                draggedItem.index = index;
            }
        },
        collect: (monitor) => ({
            isOver: monitor.isOver(),
            canDrop: monitor.canDrop(),
        }),
    });

    return (
        <div ref={(node) => drag(drop(node))}
             className={classNames("w-full grow border-b border-b-gray-100 hover:bg-gray-100", {
                 "!cursor-grabbing": isDragging,
                 "hover:cursor-grab": !isDragging,
                 "bg-gray-900 opacity-10": isOver,
             })}
             draggable={true}>

            {children}
        </div>
    )
}

const MealItem = ({
                      clientId,
                      requestsByCategory,
                      setRequestsByCategory,
                      request,
                      categories
                  }) => {


    const debounceMealUpdate = useMemo(() => _.debounce(updateRestaurantRequestMeal, 1000), []);

    const handleClearMealCategory = () => {
        let result = {
            ...requestsByCategory,
            [request.meal_category.id]: requestsByCategory[request.meal_category.id].filter(item => item.id !== request.id),
        }

        if (!result?.[0]) {
            result[0] = []
        }

        result[0].push({
            ...request,
            meal_category: null
        })


        setRequestsByCategory(result)

        debounceMealUpdate({
            ...request,
            meal_category: null
        })
    }

    const handleMealCategoryChange = (category) => {

        console.log('TEST CATEGORY')
        console.log(category)

        let result = {
            ...requestsByCategory,
            [request.meal_category?.id ?? 0]: requestsByCategory[request.meal_category?.id ?? 0].filter(item => item.id !== request.id),
        }

        if (!result?.[category.id]) {
            result[category.id] = []
        }

        result[category?.id ?? 0].push({
            ...request,
            meal_category: category
        })

        setRequestsByCategory(result)

        debounceMealUpdate({
            ...request,
            meal_category: category
        })

    }

    const handleToggleSelection = () => {
        let result = {
            ...requestsByCategory,
            [request?.meal_category?.id ?? 0]: requestsByCategory[request?.meal_category?.id ?? 0].map(meal => {
                if (meal.id !== request.id) {
                    return meal
                }

                return {
                    ...meal,
                    is_selected: !meal.is_selected
                }

            })
        }

        setRequestsByCategory(result)

        debounceMealUpdate({
            ...request,
            is_selected: !request.is_selected
        })
    }

    const handlePriceChange = (price) => {

        let result = {
            ...requestsByCategory,
            [request?.meal_category?.id ?? 0]: requestsByCategory[request?.meal_category?.id ?? 0].map(meal => {
                if (meal.id !== request.id) {
                    return meal
                }

                return {
                    ...meal,
                    price: price
                }

            })
        }

        setRequestsByCategory(result)

        debounceMealUpdate({
            ...request,
            price: price
        })
    }

    const handleImgRemove = async (requestId) => {

        let result = {
            ...requestsByCategory,
            [request?.meal_category?.id ?? 0]: requestsByCategory[request?.meal_category?.id ?? 0].map(meal => {
                if (meal.id !== request.id) {
                    return meal
                }

                return {
                    ...meal,
                    restaurant_request_files: []
                }

            })
        }

        setRequestsByCategory(result)

        await deleteRestaurantRequestFiles(requestId)
    }

    const handleImgUpload = async (file, requestId) => {



        console.log('TEST img upload')
        console.log(request)


        const uploadResponse = await uploadFile(file)

        await updateRestaurantRequestCover(requestId, uploadResponse.data.id)

        console.log('UPLOAD RESPONSE TEST')
        console.log(uploadResponse)

        let result = {
            ...requestsByCategory,
            [request?.meal_category?.id ?? 0]: requestsByCategory[request?.meal_category?.id ?? 0].map(meal => {
                if (meal.id !== request.id) {
                    return meal
                }

                return {
                    ...meal,
                    restaurant_request_files: [
                        {
                            storage: uploadResponse.data
                        }
                    ]
                }

            })
        }

        setRequestsByCategory(result)

       // await queryClient.invalidateQueries(['restaurant-meals', clientId])
    }

    const storageId = request?.restaurant_request_files?.[0]?.storage?.id
    console.log(request)
    console.log(storageId)

    return (
        <div className="flex items-center"
             key={request.id}
             id={request.id}

        >
            {/*<div>{request.id}</div>*/}
            <div className="px-4 py-2 w-[50px] shrink-0 grow-0">

                <div className="w-full flex items-center justify-center" onClick={handleToggleSelection}>
                    <Checkmark checked={request.is_selected}/>
                </div>
            </div>
            <div className="px-4 py-2 w-[150px] shrink-0 grow-0">
                <div className="w-[120px] h-[90px] rounded-[6px] overflow-hidden">
                    <ImgUpload uuid={storageId}
                               onUpload={(file) => handleImgUpload(file, request.id)}
                               onRemove={() => handleImgRemove(request.id)}/>
                </div>
            </div>
            <div className="px-4 py-2 grow align-middle">
                <div className="flex flex-col items-start justify-center ">
                    <div className="text-primary font-medium text-sm">
                        {request.recipe_name}
                    </div>
                    {
                        request.ingredient_text &&
                        <div
                            className="mt-1 text-sm"
                            dangerouslySetInnerHTML={{
                                __html: request.ingredient_text,
                            }}
                        />
                    }
                    {request.allergens && request.allergens.length > 0 &&
                        <div
                            className="min-h-[30px] bg-gray-200 text-sm py-2 px-4 text-light rounded-[6px] mt-2 shrink">
                            {request.allergens.map((e) => e.allergen_label).join(", ")}
                        </div>
                    }
                </div>
            </div>


            { !request?.meal_category &&
                <div className="px-4 py-2 w-[200px] shrink-0 grow-0">
                    <MealCategorySelect id={request.id}
                                        clientId={clientId}
                                        categories={categories}
                                        onChange={handleMealCategoryChange}
                    />
                </div>
            }

            <div className="px-4 py-2  w-[180px] shrink-0 grow-0">
                <Price key={request.id}
                    // defaultPrice={request.price ?? ''}
                    // request={request}
                       value={request?.price ?? ''}
                       onChange={(e) => handlePriceChange(e.target.value)}
                       clientId={clientId}/>
            </div>

            { request?.meal_category &&
                <div className="px-4 py-2  w-[50px] shrink-0 grow-0">
                    <div
                        className="w-[25px] h-[25px] rounded-full flex items-center justify-center hover:bg-gray-200 hover:cursor-pointer text-gray-400 text-[18px]"
                        onClick={handleClearMealCategory}>x
                    </div>
                </div>
            }
        </div>
    )
}

const RestaurantMenuContainer = () => {

    const queryClient = useQueryClient()
    const navigate = useNavigate();
    const {clientId} = useParams();
    const [restaurant, setRestaurant] = useState(null);

    const {data: meals} = useRestaurantMeals(clientId)
    const {data: categories} = useMealCategories(clientId)
    const {requestsByCategory, setRequestsByCategory} = useSorting(categories, meals)

    const [isLoading, setLoading] = useState(false);
    const [showCategoriesModal, setShowCategoriesModal] = useState(false)
    const {data: qr} = useQr(clientId)
    const [publishStatus, setPublishStatus] = useState(null)

    console.log('RENDER!')

    useEffect(() => {
        getRestaurant(clientId)
            .then((response) => setRestaurant(response.data))
            .catch((err) => alert("Server error"));

    }, [clientId]);

    //const mealsByCategory = sortByCategory(categories, meals);

    const moveItem = async (categoryId, fromIndex, toIndex) => {
        console.log('MOVE ITEM')
        console.log(`${categoryId} - from: ${fromIndex} - to: ${toIndex}`)
        const newItems = [...requestsByCategory[categoryId]]
            .sort((a, b) => a.position - b.position)
            .map((item, index) => (
                {
                    ...item,
                    position: index
                }
            ));


        const [movedItem] = newItems.splice(fromIndex, 1);
        newItems.splice(toIndex, 0, movedItem);

        newItems.forEach((item, index) => item.position = index)

        setRequestsByCategory({
            ...requestsByCategory,
            [categoryId]: newItems
        });

        reorderRestaurantRequests(clientId, newItems.map((item, index) => ({
            restaurant_request_id: item.id,
            position: index
        })))

    };

    const handlePdfPreviewBtnClick = () => {
        navigate("preview");
    };

    const handleRestaurantNameChange = (e) => {
        let restaurantLocal = {
            ...restaurant,
            name: e.target.value,
        };

        setRestaurant(restaurantLocal);
        updateRestaurant(restaurantLocal);
    };

    const toggleRestaurantReportFlag = (name) => {

        let restaurantReportFlagLocal = restaurant.restaurant_report_flag ? restaurant.restaurant_report_flag : {}

        let restaurantLocal = {
            ...restaurant,
            restaurant_report_flag: {
                ...restaurantReportFlagLocal,
                [name]: !restaurantReportFlagLocal?.[name]
            }
        }

        setRestaurant(restaurantLocal);
        updateRestaurant(restaurantLocal);
    };

    const handleCompleteBtnClick = () => {
        updateRestaurantStatus(clientId, "DONE");
        navigate(-2);
    };

    const handlePublish = async () => {

        try {
            const response = await publishDigitalMenu(clientId)
            setPublishStatus(response.data)
            toast.info('Uspešno kreiran meni');
        } catch (e) {
            toast.error('Došlo je do greške!');
        }

        await queryClient.invalidateQueries(['get-qr', clientId])
    }

    const handleDownloadBtnClick = () => {
        setLoading(true);

        getMenuPDF(clientId)
            .then((pdf) => {
                executeDownload(pdf, `${clientId}-jelovnik.pdf`);
            })
            .catch((err) => {
                console.log(err);
                alert("Server error");
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const handleDownloadZipBtnClick = () => {
        setLoading(true);

        getMenuZip(clientId)
            .then((pdf) => {
                executeDownload(pdf, `${clientId}-jelovnik.zip`);
            })
            .catch((err) => {
                console.log(err);
                alert("Server error");
            })
            .finally(() => {
                setLoading(false);
            });
    };

    // const handleImgRemove = async (requestId) => {
    //     await deleteRestaurantRequestFiles(requestId)
    //     await queryClient.invalidateQueries(['restaurant-meals', clientId])
    // }
    //
    // const handleImgUpload = async (file, requestId) => {
    //
    //     const uploadResponse = await uploadFile(file)
    //
    //     await updateRestaurantRequestCover(requestId, uploadResponse.data.id)
    //     await queryClient.invalidateQueries(['restaurant-meals', clientId])
    // }

    return (
        <>
            <div className="flex flex-col items-center justify-center mt-[20px] p-8 w-full">
                <Navigation/>

                <div className="flex items-start w-full">
                    <div className="grow">
                        <Expandable className="w-full" title="Restoran">
                            <div className="flex p-4">
                                <TextField
                                    className="grow"
                                    label="Naziv restorana"
                                    value={restaurant?.name}
                                    onChange={handleRestaurantNameChange}
                                />
                            </div>
                        </Expandable>
                        <div className="mt-4">
                            <Button color="secondary" startIcon={<EditIcon width={16}/>}
                                    onClick={() => setShowCategoriesModal(true)}>
                                <div className="font-light">Kategorije</div>
                            </Button>
                        </div>
                        <MealsTable categories={categories}
                                    requestsByCategory={requestsByCategory}
                                    setRequestsByCategory={setRequestsByCategory}
                                    clientId={clientId}
                                    moveItem={moveItem}/>
                        <Expandable className="w-full mt-[20px] mb-12" title="Nekategorizovani recepti">
                            <div className="">
                                <DndProvider backend={HTML5Backend}>
                                    {/*<table className="w-full border-collapse table-fixed">*/}
                                    {/*    <thead*/}
                                    {/*        className="px-4 text-primary opacity-70 text-sm h-[42px] border-b border-b-gray-100">*/}
                                    {/*    <tr>*/}
                                    {/*        <th className="w-[60px] text-center"></th>*/}
                                    {/*        <th className="w-[120px]"></th>*/}
                                    {/*        <th className="px-4 text-left font-light ">*/}
                                    {/*            Naziv recepta*/}
                                    {/*        </th>*/}
                                    {/*        <th className="px-4 text-left font-light w-[220px]">Kategorija</th>*/}
                                    {/*        <th className="px-4 text-center font-light w-[180px]">Cena</th>*/}
                                    {/*    </tr>*/}
                                    {/*    </thead>*/}
                                    {/*    <tbody>*/}

                                        {
                                            (requestsByCategory?.[0] ?? [])
                                                .sort((a, b) => a.position - b.position)
                                                .map((request, index) => (
                                                    <DraggableItem clientId={clientId}
                                                                   key={request.id}
                                                                   index={index}
                                                                   moveItem={(fromIndex, toIndex) => moveItem(0, fromIndex, toIndex)}
                                                                   categoryId={0}>
                                                        <MealItem clientId={clientId}
                                                                  key={request.id}
                                                                  request={request}
                                                                  index={index}
                                                                  moveItem={(fromIndex, toIndex) => moveItem(0, fromIndex, toIndex)}
                                                                  categoryId={0}
                                                                  requestsByCategory={requestsByCategory}
                                                                  setRequestsByCategory={setRequestsByCategory}
                                                                  categories={categories}
                                                        />
                                                    </DraggableItem>


                                                ))}

                                        {/*{*/}
                                        {/*    (requestsByCategory?.[0] ?? [])*/}
                                        {/*        ?.sort((a, b) => a.position - b.position)*/}
                                        {/*        ?.map((request, index) => (*/}
                                        {/*            <UncategorizedItem*/}
                                        {/*                key={request.id}*/}
                                        {/*                request={request}*/}
                                        {/*                categories={categories}*/}
                                        {/*                clientId={clientId}*/}
                                        {/*                handleImgUpload={handleImgUpload}*/}
                                        {/*                handleImgRemove={handleImgRemove}*/}
                                        {/*            />*/}
                                        {/*        ))}*/}
                                    {/*    </tbody>*/}
                                    {/*</table>*/}
                                </DndProvider>
                            </div>
                        </Expandable>

                    </div>

                    <div
                        className="text-sm p-[24px] text-primary w-[350px] flex-shrink-0 bg-white rounded-[8px] ml-8 flex flex-col">
                        <div className="mt-2">
                            <span>Podnosilac zahteva: </span>
                            <span className="font-semibold ml-2">{restaurant?.contact_name}</span>
                        </div>

                        <div className="mt-2">
                            {
                                [
                                    {
                                        label: 'Izveštaj Zbirni PDF',
                                        value: 'restaurant_report'
                                    },
                                    {
                                        label: 'Izveštaj Pojedinačni PDF',
                                        value: 'regular_report'
                                    },
                                    {
                                        label: 'Izveštaj Proizvodjačka spec',
                                        value: 'print_report'
                                    },
                                    {
                                        label: 'Izveštaj Deklaracija za jelo',
                                        value: 'recipe_declaration_report'
                                    },
                                    {
                                        label: 'Interna deklaracija za subrecept',
                                        value: 'subrecipe_declaration_report'
                                    },
                                    {
                                        label: 'QR kod',
                                        value: 'qr_code'
                                    },
                                    {
                                        label: 'Prikaži cenu',
                                        value: 'price'
                                    },
                                ].map(e => (
                                    <div key={e.value}
                                         className="flex items-center mt-2 hover:cursor-pointer hover:underline"
                                         onClick={() => toggleRestaurantReportFlag(e.value)}>
                                        <Checkmark size="sm" checked={restaurant?.restaurant_report_flag?.[e.value]}/>
                                        <div className="ml-2">{e.label}</div>
                                    </div>
                                ))
                            }

                        </div>

                        <div className="flex flex-col">
                            <button
                                onClick={() => handlePdfPreviewBtnClick(DocType.REGULAR)}
                                className={`h-[32px] rounded-[4px] bg-section-header
                                                mt-[16px] font-semibold
                                                flex items-center justify-center
                                                hover:underline
                                              `}
                            >
                                <EyeIcon fill="black" width={16}/>
                                <div className="ml-1">Preview zbirnog izveštaja</div>
                            </button>
                        </div>

                        <div className="flex flex-col">
                            <button
                                onClick={handleDownloadBtnClick}
                                className={`h-[32px] rounded-[4px] bg-section-header
                                                mt-[16px] font-semibold
                                                flex items-center justify-center
                                                hover:underline`}
                            >
                                <DownloadIcon stroke="black" width={16}/>
                                <div className="ml-1">Preuzimanje zbirnog izveštaja</div>
                            </button>
                        </div>
                        <div className="flex flex-col">
                            <button
                                onClick={handleDownloadZipBtnClick}
                                className={`h-[32px] rounded-[4px] bg-primary-action
                                                mt-[16px] font-semibold text-white
                                                flex items-center justify-center
                                                hover:opacity-80`}
                            >
                                <DownloadIcon stroke="white" width={16}/>
                                <div className="ml-1">Preuzimanje jelovnika .zip</div>
                            </button>
                        </div>

                        <QR qr={qr}/>
                    </div>
                </div>
                <NextStep
                    onNext={handleCompleteBtnClick}
                    rightButton={
                        <div className="flex items-center">
                            <Button color="green" onClick={handleCompleteBtnClick}>
                                <SaveIcon/>
                                <span className="ml-1">Završi</span>
                            </Button>
                            <div className="ml-4">
                                <Button color="primary" onClick={handlePublish}>
                                    <span className="ml-1">Kreiraj digitalni meni</span>
                                </Button>
                            </div>
                        </div>
                    }
                />
            </div>
            {publishStatus && <PublishStatus status={publishStatus} onClose={() => setPublishStatus(null)}/>}
            <Loader isLoading={isLoading}/>
            {showCategoriesModal &&
                <MealCategoriesModal clientId={clientId}
                                     onClose={() => setShowCategoriesModal(false)}/>
            }
            <ToastContainer position="bottom-center"/>
        </>
    );
};


export default RestaurantMenuContainer;
