import React from "react";
import {useState} from "react";
import {getIngredients} from "../service/IngredientsService";

const useIngredients = () => {

    let [ingredients, setIngredients] = useState(null)
    let [isLoading, setIsLoading] = useState(true)
    let [error, setError] = useState(null)
    let [fetchSuccess, setFetchSuccess] = useState(null)
    const [timeoutExec, setTimeoutExec] = useState(null)
    const [controller, setController] = useState(new AbortController())

    const fetch = (params) => {
        setIsLoading(true)
        setError(null)
        setFetchSuccess(null)


        controller.abort()
        const newController = new AbortController()
        setController(newController)

        setIngredients(null)

        if (timeoutExec) {
            clearTimeout(timeoutExec)
        }

        const exec = setTimeout(() => {
            getIngredients(params, newController.signal)
            .then(response => {
                setIngredients(response.data.ingredients);
                setError(null);
                setFetchSuccess(true)
            }).catch(err => {
                setError(err.message);
                setIngredients(null);
            }).finally(() => {
                setIsLoading(false);
            })
        }, 0)

        setTimeoutExec(exec)
    }

    return [
        ingredients,
        setIngredients,
        isLoading,
        error,
        fetch,
        fetchSuccess
    ]

}

export default useIngredients