import React, { useState } from "react";
import Modal from "./Modal";
import TextField from "./TextField";
import Button from "./Button";
import { EmailIcon, UserIcon } from "../icons/customer";
import { AttachmentIcon, GiftIcon, NotebookIcon } from "../icons";
import FileUpload from "./FileUpload";
import Checkmark from "./Checkmark";
import RadioBtnMark from "./RadioBtnMark";
import SubscriptionSelect, { SubscriptionType } from "./SubscriptionSelect";
import { createNutricalRequest } from "../service/NutricalRequestsService";
import TextAreaField from "./TextAreaField";
import NutricalRequestFileUpload from "./NutricalRequestFileUpload";

const nutricalRequestDefault = {
  subscription_package: SubscriptionType.BASIC,
  recipe_name: "",
  customer_name: "",
  customer_email: "",
  recipe_unit: "G",
  nutrients: [],
  nutrient_flag: {
    referent_entry_percentage: false,
    portion: false,
    mono_unsaturated: false,
    poly_unsaturated: false,
    polyol: false,
    fibre: false,
    starch: false,
  },
};

const NutrientExtendedCheckbox = ({ checked, onChange, label }) => {
  return (
    <div
      className="flex items-center mt-3 hover:cursor-pointer hover:opacity-80"
      onClick={onChange}
    >
      <Checkmark checked={checked} />
      <div className="ml-2 text-sm">{label}</div>
    </div>
  );
};

export const BasicField = ({
  icon,
  label,
  value,
  onChange,
  input,
  mandatory = false,
  errorMsg,
}) => {
  return (
    <div className="flex w-full items-center mt-4 text-[14px] text-light">
      <div className="mr-2">{icon}</div>
      <div className="mr-4 basis-[160px]">{label}</div>
      {!input && (
        <TextField
          mandatory={mandatory}
          className="grow basis[200px]"
          value={value}
          onChange={onChange}
          errorMsg={errorMsg}
        />
      )}
      {input}
    </div>
  );
};

export const TextAreaModalField = ({
  icon,
  label,
  value,
  onChange,
  input,
  mandatory = false,
  errorMsg,
  rows,
}) => {
  return (
    <div className="flex w-full items-start mt-4 text-[14px] text-light">
      <div className="mr-2">{icon}</div>
      <div className="mr-4 basis-[160px]">{label}</div>
      {!input && (
        <TextAreaField
          mandatory={mandatory}
          className="grow basis[200px]"
          value={value}
          onChange={onChange}
          errorMsg={errorMsg}
          rows={rows}
        />
      )}
      {input}
    </div>
  );
};

const NutrientExtendedSection = ({ nutricalRequest, setNutricalRequest }) => {
  const [showNutrientExtended, setShowNutrientExtended] = useState(false);

  const onNutrientFlagToggle = (name) => {
    setNutricalRequest({
      ...nutricalRequest,
      nutrient_flag: {
        ...nutricalRequest.nutrient_flag,
        [name]: !nutricalRequest.nutrient_flag[name],
      },
    });
  };

  const handleShowNutrientExtendedChange = (value) => {
    if (!value) {
      setNutricalRequest({
        ...nutricalRequest,
        nutrient_flag: {
          ...nutricalRequest.nutrient_flag,
          mono_unsaturated: false,
          poly_unsaturated: false,
          polyol: false,
          fibre: false,
          starch: false,
        },
      });
    }

    setShowNutrientExtended(value);
  };

  return (
    <div>
      <div className="mt-8">
        <NutrientExtendedCheckbox
          checked={nutricalRequest?.nutrient_flag?.referent_entry_percentage}
          onChange={() => onNutrientFlagToggle("referent_entry_percentage")}
          label="Izrazi procenat referentnog unosa (% RU) za 100g, po porciji/potrošačkoj jedinici"
        />
        <NutrientExtendedCheckbox
          checked={nutricalRequest?.nutrient_flag?.portion}
          onChange={() => onNutrientFlagToggle("portion")}
          label="Izrazi energetsku vrednost i količinu hranjivih materija po porciji/potrošačkoj jedinici hrane"
        />
      </div>

      <div className="mt-6 flex items-center mb-4">
        <div className="font-medium">Proširena nutritivna tabela</div>
        <div
          className="flex items-center ml-6 hover:cursor-pointer"
          onClick={() => handleShowNutrientExtendedChange(true)}
        >
          <RadioBtnMark selected={showNutrientExtended} />
          <div className="ml-2">Da</div>
        </div>
        <div
          className="flex items-center ml-6 hover:cursor-pointer"
          onClick={() => handleShowNutrientExtendedChange(false)}
        >
          <RadioBtnMark selected={!showNutrientExtended} />
          <div className="ml-2">Ne</div>
        </div>
      </div>

      {showNutrientExtended && (
        <div>
          <NutrientExtendedCheckbox
            label="Prikazati mononezasićene masne kiseline"
            checked={nutricalRequest?.nutrient_flag?.mono_unsaturated}
            onChange={() => onNutrientFlagToggle("mono_unsaturated")}
          />

          <NutrientExtendedCheckbox
            label="Prikazati polinezasićene masne kiseline"
            checked={nutricalRequest?.nutrient_flag?.poly_unsaturated}
            onChange={() => onNutrientFlagToggle("poly_unsaturated")}
          />

          <NutrientExtendedCheckbox
            label="Prikazati poliole"
            checked={nutricalRequest?.nutrient_flag?.polyol}
            onChange={() => onNutrientFlagToggle("polyol")}
          />

          <NutrientExtendedCheckbox
            label="Prikazati vlakna"
            checked={nutricalRequest?.nutrient_flag?.fibre}
            onChange={() => onNutrientFlagToggle("fibre")}
          />

          <NutrientExtendedCheckbox
            label="Prikazati skrob"
            checked={nutricalRequest?.nutrient_flag?.starch}
            onChange={() => onNutrientFlagToggle("starch")}
          />
        </div>
      )}
    </div>
  );
};

const NutricalRequestFormModal = ({ onClose }) => {
  const [nutricalRequest, setNutricalRequest] = useState(
    nutricalRequestDefault
  );

  const handleCustomerNameChange = (e) =>
    setNutricalRequest({
      ...nutricalRequest,
      customer_name: e.target.value,
    });

  const handleCustomerEmailChange = (e) =>
    setNutricalRequest({
      ...nutricalRequest,
      customer_email: e.target.value,
    });

  const handleRecipeNameChange = (e) =>
    setNutricalRequest({
      ...nutricalRequest,
      recipe_name: e.target.value,
    });

  const handleSubscriptionTypeChange = (option) =>
    setNutricalRequest({
      ...nutricalRequest,
      subscription_package: option,
    });

  const convert = (data) => {
    return {
      ...data,
      subscription_package: data?.subscription_package?.value,
    };
  };

  const handleSubmit = () => {
    createNutricalRequest(convert(nutricalRequest))
      .then(() => {
        onClose();
      })
      .catch((e) => {
        alert("Došlo je do greške. Molimo vas pokušajte ponovo.");
      });
  };

  const isValidEmail = (email) => {
    if (!email) {
      return true;
    }

    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return emailRegex.test(email);
  };

  const validateForm = () => {
    let validation = {
      isValid: true,
      recipe_name: {
        errorMsg: "",
      },
      customer_name: {
        errorMsg: "",
      },
      customer_email: {
        errorMsg: "",
      },
    };

    if (!nutricalRequest.recipe_name) {
      validation.isValid = false;
    }

    if (!nutricalRequest.customer_name) {
      validation.isValid = false;
    }

    if (!nutricalRequest.customer_email) {
      validation.isValid = false;
    }

    if (
      nutricalRequest.customer_name &&
      nutricalRequest.customer_name.length < 3
    ) {
      validation.customer_name.errorMsg = "Minimalan broj karaktera: 3";
      validation.isValid = false;
    }

    if (!isValidEmail(nutricalRequest.customer_email)) {
      validation.customer_email.errorMsg = "Unesite ispravan format";
      validation.isValid = false;
    }

    return validation;
  };

  const validation = validateForm();

  return (
    <div>
      <Modal
        contentClassName="basis-[600px] bg-white"
        show={true}
        onClose={onClose}
      >
        <Modal.Header className="text-left w-full ">
          Kreiraj zahtev
        </Modal.Header>

        <Modal.Body className="w-full">
          <BasicField
            icon={<UserIcon />}
            label="Ime klijenta"
            value={nutricalRequest.customer_name}
            onChange={handleCustomerNameChange}
            mandatory={true}
            errorMsg={validation.customer_name.errorMsg}
          />
          <BasicField
            icon={<EmailIcon />}
            label="Email"
            value={nutricalRequest.customer_email}
            onChange={handleCustomerEmailChange}
            mandatory={true}
            errorMsg={validation.customer_email.errorMsg}
          />
          <BasicField
            icon={<NotebookIcon />}
            label="Naziv proizvoda"
            value={nutricalRequest.recipe_name}
            onChange={handleRecipeNameChange}
            mandatory={true}
            errorMsg={validation.recipe_name.errorMsg}
          />
          <BasicField
            icon={<GiftIcon />}
            label="Paket"
            input={
              <SubscriptionSelect
                className="grow basis[200px]"
                isClearable={false}
                defaultValue={nutricalRequest.subscription_package}
                onChange={handleSubscriptionTypeChange}
              />
            }
          />

          <NutricalRequestFileUpload
            nutricalRequest={nutricalRequest}
            setNutricalRequest={setNutricalRequest}
          />

          <NutrientExtendedSection
            nutricalRequest={nutricalRequest}
            setNutricalRequest={setNutricalRequest}
          />
        </Modal.Body>

        <Modal.Footer
          className="flex justify-center p-8"
          isValid={validation.isValid}
        >
          <Button className="my-4" size="large" onClick={handleSubmit}>
            Kreiraj zahtev
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default NutricalRequestFormModal;
