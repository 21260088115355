import {useQuery} from "react-query";
import {getFile} from "../service/MediaService";

export const useMediaStore = (uuid) => {
    return useQuery({
        queryKey: ['media-store', uuid],
        queryFn: () => getFile(uuid).then((response) => response.data),
        enabled: !!uuid,
        staleTime: 200000,
    })
}