import * as React from "react";
const SvgWeightScaleIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={16}
    height={16}
    fill="none"
    {...props}
  >
    <g fill="#313131" clipPath="url(#weightScaleIcon_svg__a)">
      <path d="m16 14.125-.697-4.688h-.866V8.5h.938v-.938h-2.637V3.438h2.637V0h-6.21v3.438H11.8v4.124H.625V8.5h.938v.938H.697L0 14.125h.938V16H3.75v-1.875h8.5V16h2.813v-1.875H16ZM10.102.937h4.335V2.5h-4.335V.937ZM2.5 8.5h11v.938h-11V8.5Zm.313 6.563h-.938v-.938h.938v.938Zm-1.79-1.876.468-2.812h13.018l.469 2.813H1.022Zm13.102 1.876h-.938v-.938h.938v.938Z" />
      <path d="M10.688 11.313h.937v.937h-.938v-.938ZM2.5 11.313h3.219v.937H2.5v-.938ZM12.563 11.313h.937v.937h-.938v-.938Z" />
    </g>
    <defs>
      <clipPath id="weightScaleIcon_svg__a">
        <path fill="#fff" d="M0 0h16v16H0z" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgWeightScaleIcon;
