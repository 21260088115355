import * as React from "react";
const SvgForkKnifeIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={16}
    height={16}
    fill="none"
    {...props}
  >
    <path
      fill="#313131"
      d="M4.5 5.5v-3a.5.5 0 0 1 1 0v3a.5.5 0 1 1-1 0Zm9-3V14a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1-.5-.5c.023-1.198.175-2.39.451-3.555.611-2.53 1.77-4.227 3.352-4.904a.5.5 0 0 1 .697.46Zm-1 .869C10.49 4.904 10.096 8.645 10.019 10H12.5V3.37Zm-5.007-.95a.5.5 0 1 0-.986.164L7 5.539a2 2 0 1 1-4 0l.493-2.956a.5.5 0 1 0-.987-.165l-.5 3A.512.512 0 0 0 2 5.5a3.004 3.004 0 0 0 2.5 2.958V14a.5.5 0 0 0 1 0V8.458A3.004 3.004 0 0 0 8 5.5a.51.51 0 0 0-.007-.082l-.5-3Z"
    />
  </svg>
);
export default SvgForkKnifeIcon;
