import * as React from "react";
const SvgThermometerIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={20}
    height={20}
    fill="none"
    {...props}
  >
    <path
      fill="#fff"
      d="M16.563 4.375a2.187 2.187 0 1 0 0 4.375 2.187 2.187 0 0 0 0-4.375Zm0 3.125a.937.937 0 1 1 0-1.874.937.937 0 0 1 0 1.874ZM10 11.953V6.875a.625.625 0 0 0-1.25 0v5.078a2.5 2.5 0 1 0 1.25 0Zm-.625 3.672a1.25 1.25 0 1 1 0-2.5 1.25 1.25 0 0 1 0 2.5Zm3.125-5.156V3.75a3.125 3.125 0 1 0-6.25 0v6.719a5 5 0 1 0 6.25 0Zm-3.125 7.656a3.75 3.75 0 0 1-2.142-6.828.625.625 0 0 0 .267-.516V3.75a1.875 1.875 0 1 1 3.75 0v7.031a.625.625 0 0 0 .267.513 3.75 3.75 0 0 1-2.142 6.831Z"
    />
  </svg>
);
export default SvgThermometerIcon;
