import React from "react";
import { AttachmentIcon } from "../icons";
import FileUpload from "./FileUpload";
import { useCallback } from "react";

const NutricalRequestFileUpload = ({ nutricalRequest, setNutricalRequest }) => {
  const handleUploadStart = useCallback((file) => {
    setNutricalRequest((prevState) => {
      return {
        ...prevState,
        nutrients: [
          ...prevState.nutrients,
          {
            uploading: {
              file,
              is_loading: true,
              has_error: false,
            },
          },
        ],
      };
    });
  }, []);

  const handleUploadEnd = useCallback((file, response, error) => {
    setNutricalRequest((prevState) => {
      let nutrients = prevState.nutrients.map((nutrient) => {
        if (nutrient.uploading.file === file) {
          return {
            storage: response ? { ...response.data } : null,
            uploading: {
              file: file,
              is_loading: false,
              has_error: error,
            },
          };
        }

        return nutrient;
      });

      return {
        ...prevState,
        nutrients: nutrients,
      };
    });
  }, []);

  const handleFileRemoval = (file) => {
    setNutricalRequest((prevState) => {
      let nutrients = prevState.nutrients.filter(
        (nutrient) => nutrient.uploading.file !== file
      );

      return {
        ...prevState,
        nutrients: nutrients,
      };
    });
  };

  let uploadingStatusList =
    nutricalRequest &&
    nutricalRequest.nutrients &&
    nutricalRequest.nutrients.map((nutrient) => nutrient.uploading);

  return (
    <div>
      <div className="flex mb-2 mt-4">
        <AttachmentIcon />
        <div className="ml-2 text-light text-[14px]">Prilog</div>
      </div>
      <FileUpload
        onUploadStart={handleUploadStart}
        onUploadEnd={handleUploadEnd}
        onRemove={handleFileRemoval}
        uploadingStatusList={uploadingStatusList}
      />
    </div>
  );
};

export default NutricalRequestFileUpload;
