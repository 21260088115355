import * as React from "react";
const SvgVitaminsIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={16}
    height={16}
    fill="#989898"
    {...props}
  >
    <path d="M13.312.332 12.98 0l-1.534 1.533-9.913 9.914L0 12.98l.332.332A9.118 9.118 0 0 0 6.822 16a9.118 9.118 0 0 0 6.49-2.688A9.118 9.118 0 0 0 16 6.822a9.118 9.118 0 0 0-2.688-6.49Zm-1.878 2.543a6.078 6.078 0 0 1-.32 8.24 6.078 6.078 0 0 1-8.239.319l8.559-8.559Zm1.213 9.772a8.184 8.184 0 0 1-5.825 2.413 8.178 8.178 0 0 1-5.483-2.09l.87-.87a6.958 6.958 0 0 0 4.613 1.732c1.873 0 3.633-.73 4.957-2.053a6.964 6.964 0 0 0 2.053-4.957A6.957 6.957 0 0 0 12.1 2.21l.87-.87a8.178 8.178 0 0 1 2.09 5.483c0 2.2-.857 4.27-2.413 5.825Z" />
    <path d="m9.271 9.937.665-.664.665.664-.665.665-.665-.665ZM7.344 10.668l.664-.664.665.664-.665.665-.664-.665ZM10.002 8.01l.665-.665.664.665-.664.665-.665-.665ZM10.445 5.794l.665-.664.665.664-.665.665-.665-.665ZM5.127 11.111l.665-.665.664.665-.664.665-.665-.665Z" />
  </svg>
);
export default SvgVitaminsIcon;
