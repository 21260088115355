import React, { useEffect, useRef, useState } from "react";
import classNames from "classnames";
import { ArrowIcon } from "../icons";
import { updateSubscriptionPackage } from "../service/NutricalRequestsService";

const PackageMenu = ({ onChange, onClose }) => {
  const menuRef = useRef(null);

  useEffect(() => {
    const handleClick = (e) => {
      onClose();
    };

    document.addEventListener("click", handleClick);

    return () => document.removeEventListener("click", handleClick);
  }, []);

  return (
    <div
      ref={menuRef}
      className="bg-white border border-gray-100 shadow-md absolute right-0 top-[35px] rounded-[6px]"
    >
      <PackageOption onChange={() => onChange("BASIC")}>
        <div className="text-package-basic">BASIC</div>
      </PackageOption>
      <PackageOption onChange={() => onChange("STANDARD")}>
        <div className="text-package-standard">STANDARD</div>
      </PackageOption>
      <PackageOption onChange={() => onChange("PREMIUM")}>
        <div className="text-package-premium">PREMIUM</div>
      </PackageOption>
    </div>
  );
};

const PackageOption = ({ children, onChange }) => {
  return (
    <div
      className="text-package-basic border-b border-gray-200 px-3 py-2 text-center hover:cursor-pointer
        hover:bg-gray-100"
      onClick={onChange}
    >
      {children}
    </div>
  );
};

const PackageTitle = ({
  title,
  subscription: defaultSubscription,
  nutricalRequestId,
  onChangeStart = () => {},
  onChangeend = () => {},
}) => {
  const [subscription, setSubscription] = useState(defaultSubscription);
  const [showOptions, setShowOptions] = useState(false);

  const titleClasses = classNames({
    "border-package-basic": subscription === "BASIC",
    "border-package-standard": subscription === "STANDARD",
    "border-package-premium": subscription === "PREMIUM",
  });

  const subscriptionLabelClasses = classNames({
    "text-package-basic": subscription === "BASIC",
    "text-package-standard": subscription === "STANDARD",
    "text-package-premium": subscription === "PREMIUM",
  });

  const handleSubscriptionChange = async (subscription) => {
    setSubscription(subscription);
    onChangeStart();
    await updateSubscriptionPackage(nutricalRequestId, subscription);
    onChangeend();
  };

  return (
    <div
      className={`${titleClasses} text-primary-color bg-section-header
                            flex space-between items-center h-[42px] pl-[15px] pr-[5px] rounded-t-[8px] border-b-[2px]`}
    >
      <div className="text-sm font-medium ">{title}</div>
      <div
        className={`${subscriptionLabelClasses} text-xs font-semibold relative `}
      >
        <div
          className=" bg-package-select h-[33px] px-3 rounded-[6px] flex items-center
                hover:cursor-pointer hover:opacity-80"
          onClick={(e) => {
            e.stopPropagation();
            setShowOptions(!showOptions);
          }}
        >
          <div>{subscription}</div>
          <ArrowIcon
            className="ml-2"
            fill="#ffffff"
            transform={showOptions ? "" : "rotate(180)"}
          />
        </div>
        {showOptions && (
          <PackageMenu
            onClose={() => setShowOptions(false)}
            onChange={handleSubscriptionChange}
          />
        )}
      </div>
    </div>
  );
};

export default PackageTitle;
