import React, {useEffect, useRef, useState} from "react";
import Modal from "./Modal";
import {AddressIcon, EmailIcon, PhoneIcon, UserIcon} from "../icons/customer";
import { BasicField } from "./NutricalRequestFormModal";
import {ForkKnifeIcon, CodeBlockIcon, AttachmentIcon, PickerIcon} from "../icons";
import Button from "./Button";
import {saveRestaurant, updateRestaurant} from "../service/RestaurantService";
import RestaurantFileUpload from "./RestaurantFileUpload";
import classNames from "classnames";
import { CameraIcon } from "../icons";
import {ChromePicker, SketchPicker} from "react-color";

const Tab = {
   BASIC_INFO: {
     id: "BASIC_INFO",
     label: "Informacije o klijentu"
   },
  VISUAL_IDENTITY: {
     id: "VISUAL_IDENTITY",
    label: "Vizuelni identitet"
  }
}

const restaurantDefault = {
  contact_email: "",
  contact_name: "",
  contact_phone: "",
  external_id: "",
  id: null,
  name: "",
  restaurant_files: [
    // {
    //   "id": 0,
    //   "storage": {
    //     "id": "3fa85f64-5717-4562-b3fc-2c963f66afa6"
    //   }
    // }
  ],
};

const RestaurantForm = ({ onClose,
                          onSubmit,
                          defaultValue = restaurantDefault }) => {
  const [restaurant, setRestaurant] = useState(defaultValue);
  const [tab, setTab] = useState(Tab.BASIC_INFO.id)
  const [showPicker, setShowPicker] = useState(false);
  const pickerRef = useRef();

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (pickerRef.current && !pickerRef.current.contains(event.target)) {
        setShowPicker(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const isValidEmail = (email) => {
    if (!email) {
      return true;
    }

    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return emailRegex.test(email);
  };

  const validateForm = () => {
    let validation = {
      isValid: true,
      name: {
        errorMsg: "",
      },
      contact_email: {
        errorMsg: "",
      },
    };

    if (!restaurant.name) {
      validation.isValid = false;
    }

    if (restaurant.name && restaurant.name.length < 3) {
      validation.name.errorMsg = "Minimalan broj karaktera: 3";
      validation.isValid = false;
    }

    if (!isValidEmail(restaurant.contact_email)) {
      validation.contact_email.errorMsg = "Unesite ispravan format";
      validation.isValid = false;
    }

    return validation;
  };

  const validation = validateForm();

  const handleNameChange = (e) => {
    setRestaurant({ ...restaurant, name: e.target.value });
  };

  const handleAddressChange = (e) => {
    setRestaurant({ ...restaurant, address: e.target.value });
  };

  const handleContactNameChange = (e) => {
    setRestaurant({ ...restaurant, contact_name: e.target.value });
  };

  const handleContactPhoneChange = (e) => {
    setRestaurant({ ...restaurant, contact_phone: e.target.value });
  };

  const handleContactEmailChange = (e) => {
    setRestaurant({ ...restaurant, contact_email: e.target.value });
  };

  const handleBackgroundColorChange = (color) => {
    const { r, g, b, a } = color.rgb;
    const rgbaColor = `rgba(${r}, ${g}, ${b}, ${a})`;

    setRestaurant({ ...restaurant, background_color: rgbaColor  });
  };

  const handleSubmit = async () => {

    try {
      if (restaurant?.id) {
        await updateRestaurant(restaurant)
      } else {
        await saveRestaurant(restaurant)
      }

      onSubmit();
    } catch (error) {
       console.log(error)
       alert("Došlo je do greške");
    }
/*
    saveRestaurant(restaurant)
      .then(() => {
        onSubmit();
      })
      .catch((error) => {
        alert("Došlo je do greške");
      });*/
  };

  return (
    <div>
      <Modal
        contentClassName="basis-[550px] bg-white"
        show={true}
        onClose={onClose}
      >
        <Modal.Header className=" border-b border-gray-200">
          <div className="text-left w-full ">Kreiraj klijenta</div>

          <div className="mt-6 h-[36px] w-full rounded-[8px] bg-input shrink-0 flex text-xs p-[2px] ">
            {
              [Tab.BASIC_INFO, Tab.VISUAL_IDENTITY].map(tabItem => (
                  <div className={classNames({
                    "grow basis-1 flex items-center justify-center rounded-[8px]": true,
                    "bg-primary-action text-white": tab === tabItem.id,
                    "hover:cursor-pointer hover:underline": tab !== tabItem.id
                  })}
                       onClick={() => {
                         setTab(tabItem.id)
                       }}>
                    {tabItem.label}
                  </div>
              ))
            }
          </div>
        </Modal.Header>

        <Modal.Body className="w-full">


          {
            <div className={classNames({
              "hidden": tab !== Tab.BASIC_INFO.id
            })}>
              <BasicField
                  icon={<ForkKnifeIcon/>}
                  label="Ime klijenta"
                  value={restaurant.name}
                  onChange={handleNameChange}
                  mandatory={true}
                  errorMsg={validation.name.errorMsg}
              />
              <BasicField
                  icon={<AddressIcon/>}
                  label="Adresa"
                  value={restaurant.address}
                  onChange={handleAddressChange}
                  mandatory={false}
                  errorMsg={validation?.address?.errorMsg}
              />

              <BasicField
                  icon={<UserIcon/>}
                  label="Ime kontakt osobe"
                  value={restaurant.contact_name}
                  onChange={handleContactNameChange}
                  mandatory={false}
                  errorMsg={validation?.contact_name?.errorMsg}
              />

              <BasicField
                  icon={<PhoneIcon/>}
                  label="Broj telefona"
                  value={restaurant.contact_phone}
                  onChange={handleContactPhoneChange}
                  mandatory={false}
                  errorMsg={validation?.contact_phone?.errorMsg}
              />

              <BasicField
                  icon={<EmailIcon/>}
                  label="Email"
                  value={restaurant.contact_email}
                  onChange={handleContactEmailChange}
                  mandatory={false}
                  errorMsg={validation?.contact_email?.errorMsg}
              />

              <div>
                <div className="flex mb-2 mt-4">
                  <AttachmentIcon/>
                  <div className="ml-2 text-light text-[14px]">Prilog</div>
                </div>
                <RestaurantFileUpload
                    clientRequest={restaurant}
                    setClientRequest={setRestaurant}
                    type={null}
                />
              </div>
            </div>
          }
          {
            <div className={classNames({
              "hidden": tab !== Tab.VISUAL_IDENTITY.id
            })}>

              <div className="mt-8">
                  <div className="flex items-center mb-2">
                    <CameraIcon/>
                    <div className="text-sm opacity-80 ml-2">Upload logotipa (Tamna verzija teme)</div>
                  </div>
                  <RestaurantFileUpload
                      clientRequest={restaurant}
                      setClientRequest={setRestaurant}
                      type="LOGO"
                  />
              </div>

              <div className="mt-8">
                <div className="flex items-center mb-2">
                  <CameraIcon/>
                  <div className="text-sm opacity-80 ml-2">Upload nosece fotografije</div>
                </div>

                <RestaurantFileUpload
                    clientRequest={restaurant}
                    setClientRequest={setRestaurant}
                    type="COVER"
                  />
              </div>

              <div className="w-full flex justify-center mt-4 opacity-80">Ili</div>

              <div className="mt-4 border border-gray-200 rounded-[8px] flex justify-between items-center px-4 h-[70px]">
                <div className="text-sm opacity-80">Izaberite pozadinsku boju</div>

                <div className="relative flex items-center">
                {/*  <div
                      className="bg-gray-100 py-2 px-4 flex items-center hover:cursor-pointer hover:underline  rounded-[8px]"
                      onClick={() => setShowPicker(!showPicker)}>
                    <span className="w-[22px] h-[22px] rounded-[4px]"
                          style={{backgroundColor: restaurant?.background_color}}></span>
                    <span className="ml-2 text-sm whitespace-nowrap">{restaurant?.background_color}</span>
                  </div>*/}
                  <div
                      className="ml-4 bg-gray-200 py-2 pl-2 pr-4 flex items-center hover:cursor-pointer hover:underline  rounded-[8px]"
                      onClick={() => setShowPicker(!showPicker)}>
                   <span className="w-[22px] h-[22px] rounded-[4px]"
                         style={{backgroundColor: restaurant?.background_color ?? 'black'}}></span>
                    <span className="ml-2 text-sm whitespace-nowrap">{restaurant?.background_color ?? 'Ručni izbor'}</span>
                  </div>
                  {showPicker &&
                      <div ref={pickerRef} className="absolute bottom-[50px] right-0 z-30">
                        <ChromePicker color={restaurant?.background_color ?? ''}
                                      onChange={handleBackgroundColorChange}/>
                      </div>
                  }
                </div>
              </div>


            </div>


          }
        </Modal.Body>
        <Modal.Footer
            className="flex justify-center p-8"
            isValid={validation.isValid}
        >
          <Button className="my-4" size="large" onClick={handleSubmit}>
          {!restaurant?.id && <span>Kreiraj klijenta</span>}
            {restaurant?.id && <span>Sačuvaj izmene</span>}
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default RestaurantForm;
