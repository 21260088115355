import React from "react";
import ReportContainer from "./ReportContainer";
import { useParams } from "react-router-dom";
import useRecipeReport from "../hooks/useRecipeReport";
import { RecipeType } from "../service/RecipeService";
import { updateNutricalRequestStatus } from "../service/NutricalRequestsService";

const NutricalReportContainer = () => {
  const { requestId } = useParams();
  const { report, handleReportChange, hasUnsavedChanges } = useRecipeReport(
    requestId,
    RecipeType.NUTRICAL
  );

  return (
    <ReportContainer
      requestId={requestId}
      report={report}
      handleReportChange={handleReportChange}
      hasUnsavedChanges={hasUnsavedChanges}
      onComplete={updateNutricalRequestStatus}
    />
  );
};

export default NutricalReportContainer;
