import * as React from "react";
const SvgInProgressIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={16}
    height={16}
    fill="none"
    {...props}
  >
    <path
      fill="#313131"
      d="M13.2 12.285 8.724 8 13.2 3.715A1 1 0 0 0 12.5 2h-9a1 1 0 0 0-.706 1.707l.007.008L7.278 8l-4.476 4.285-.007.008A1 1 0 0 0 3.5 14h9a1 1 0 0 0 .699-1.715ZM5.59 5h4.822L8 7.308 5.59 5Zm6.91-2-1.043 1H4.546L3.5 3h9Zm-9 10L8 8.693 12.5 13h-9Z"
    />
  </svg>
);
export default SvgInProgressIcon;
