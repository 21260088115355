import axios from "axios";
import {host} from "../config/api";

export const getIngredients = ({searchText}) => {
    return  axios({
        url: `${host}/v1/ingredients`,
        method: 'GET',
        params: {
            "search-text": searchText,
        },
    })
}

export const IngredientType = {
    NUTRIKATOR: "NUTRIKATOR",
    EUROFIR: "EUROFIR"
}