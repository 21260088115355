import queryString from "query-string";

const objectToQueryString = (paramsObject) => {
  const result = queryString.stringify(paramsObject);
  return result ? `?${result}` : "";
};

export const updateUrlQueryParams = (paramsObject) => {
  const queryString = objectToQueryString(paramsObject);
  const newUrl = `${window.location.pathname}${queryString}`;
  window.history.replaceState(null, null, newUrl);
};
