import * as React from "react";
const SvgClearInputIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={16}
    height={16}
    fill="none"
    stroke="#7E7E7E"
    {...props}
  >
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M14 2.667H5.332L.666 8l4.667 5.333h8.666A1.334 1.334 0 0 0 15.333 12V4a1.334 1.334 0 0 0-1.334-1.333ZM12 6l-4 4M8 6l4 4"
    />
  </svg>
);
export default SvgClearInputIcon;
