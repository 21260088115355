import React from "react";

import "../css/navigation.css";
import { useKeycloak } from "@react-keycloak/web";
import Menu from "./Menu";
import MenuItem from "./MenuItem";
import { useLocation } from "react-router-dom";
import { ArrowIcon } from "../icons";

const Navigation = (props) => {
  const { keycloak } = useKeycloak();
  const location = useLocation();

  const firstName = keycloak?.tokenParsed?.given_name;
  const userPicture = keycloak?.tokenParsed?.picture;

  const profileEle = (
    <div className="flex items-center hover:cursor-pointer hover:opacity-90">
      <div className="profile-pic">
        {userPicture && <img src={userPicture} alt=" " />}
        {!userPicture && <span>{firstName.charAt(0)}</span>}
      </div>
      <ArrowIcon className="ml-2" width={12} transform={"rotate(180)"} />
    </div>
  );
  return (
    <div className="navigation-wrapper">
      <div className="navigation">
        <div>
          <div className="logo">
            <img src="/logo.svg" width={136} />
          </div>
          <div className="items">
            <div className="item">
              <a
                className={
                  location.pathname === "/" ||
                  location.pathname.startsWith("/requests")
                    ? "selected"
                    : ""
                }
                href="/requests"
              >
                <span>Proizvođači hrane</span>
              </a>
            </div>
            <div className="item">
              <a
                className={
                  location.pathname.startsWith("/clients") ? "selected" : ""
                }
                href="/clients"
              >
                <span>Kreiranje jelovnika</span>
              </a>
            </div>
          </div>
          <div className="user">
            <Menu element={profileEle} position="right">
              <MenuItem
                className="font-semibold"
                startIcon={<img width={12} src="/logout.svg" />}
                onClick={() => keycloak.logout()}
              >
                Logout
              </MenuItem>
            </Menu>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Navigation;
