import React, {useState} from "react";
import {AttachmentIcon} from "../icons";
import FileUpload from "./FileUpload";
import {useCallback} from "react";

const RestaurantFileUpload = ({clientRequest, setClientRequest, type}) => {

    const handleUploadStart = useCallback((file) => {

    }, []);

    const handleUploadEnd = useCallback((file, response, error) => {

        if (error) {
            return
        }

        setClientRequest((prevState) => {
            return {
                ...prevState,
                restaurant_files: [
                    ...prevState.restaurant_files,
                    {
                        storage: response ? {...response.data} : null,
                        restaurant_image_type: type,
                    }
                ]
            }
        })

    }, []);

    const handleFileRemoval = (storageId) => {
        const newRequest = {
            ...clientRequest,
            restaurant_files: clientRequest?.restaurant_files
                ?.filter((file) => file?.storage?.id !== storageId)
        }
        setClientRequest(newRequest)
    };

    const uploadedFiles = clientRequest?.restaurant_files?.filter(file => file.restaurant_image_type === type)

    return (
        <div>

            <FileUpload
                onUploadStart={handleUploadStart}
                onUploadEnd={handleUploadEnd}
                onRemove={handleFileRemoval}
                defaultUploadedFiles={uploadedFiles}
            />
        </div>
    );
};

export default RestaurantFileUpload;
