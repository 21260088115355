import * as React from "react";
const SvgPlateForkAndKnifeIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={98}
    height={65}
    fill="none"
    {...props}
  >
    <path
      fill="#414141"
      d="M97.236 1a1 1 0 0 0-1-1C92.542 0 90.12 6.72 89.9 9.588l-1.812 23.56a4.478 4.478 0 0 0 1.176 3.377c.275.297.596.535.935.744L89.114 59.81a3.504 3.504 0 0 0 .978 2.598 3.503 3.503 0 0 0 2.552 1.09h1.02a3.538 3.538 0 0 0 3.534-3.534V37.146c.013-.063.038-.122.038-.189V1Zm-5.343 8.741c.176-2.29 1.753-6.071 3.343-7.324v33.042H91.24c-.05 0-.095.021-.144.028a2.438 2.438 0 0 1-1.013-2.186l1.811-23.56Zm1.772 51.756h-1.02a1.52 1.52 0 0 1-1.107-.473 1.52 1.52 0 0 1-.425-1.124l1.057-21.983c.124.011.245.039.37.039h2.658v22.007c0 .846-.688 1.534-1.533 1.534ZM50.156.193c-17.526 0-31.785 14.259-31.785 31.785s14.26 31.785 31.785 31.785c17.526 0 31.785-14.26 31.785-31.785C81.941 14.452 67.682.193 50.156.193Zm0 61.57c-16.424 0-29.785-13.361-29.785-29.785S33.732 2.193 50.156 2.193s29.785 13.36 29.785 29.785c0 16.424-13.36 29.785-29.785 29.785Z"
    />
    <path
      fill="#414141"
      d="M68.039 33.234a.995.995 0 0 0-1.117.868c-1.055 8.423-8.263 14.775-16.766 14.775-9.318 0-16.898-7.58-16.898-16.898s7.58-16.898 16.898-16.898c3.47 0 6.805 1.044 9.643 3.02a1 1 0 1 0 1.142-1.642 18.787 18.787 0 0 0-10.785-3.378c-10.42 0-18.898 8.478-18.898 18.898s8.478 18.898 18.898 18.898c9.509 0 17.57-7.104 18.75-16.526a.999.999 0 0 0-.867-1.117ZM65.901 24.676a18.2 18.2 0 0 1 .777 2.23 1.001 1.001 0 0 0 1.23.696 1 1 0 0 0 .697-1.231 20.073 20.073 0 0 0-2.327-5.292 1 1 0 1 0-1.697 1.058c.507.811.95 1.665 1.32 2.539ZM13.314.693H1a1 1 0 0 0-1 1V17.7a7.157 7.157 0 0 0 3.694 6.264V60.8a3.466 3.466 0 0 0 3.463 3.463A3.466 3.466 0 0 0 10.62 60.8V23.966a7.16 7.16 0 0 0 3.694-6.265V1.693a1 1 0 0 0-1-1Zm-1 17.008a5.16 5.16 0 0 1-3.095 4.728 1 1 0 0 0-.6.916V60.8c0 .807-.656 1.463-1.463 1.463A1.464 1.464 0 0 1 5.693 60.8V23.344a1 1 0 0 0-.6-.917A5.157 5.157 0 0 1 1.999 17.7V2.693h2.158V14.85a1 1 0 0 0 2 0V2.693h2V14.85a1 1 0 0 0 2 0V2.693h2.157V17.7Z"
    />
  </svg>
);
export default SvgPlateForkAndKnifeIcon;
